const createuserModel = {
  Username: null,
  Name: null,
  Surname: null,
  EmailAddress: null,
  IsActive: false,
  RoleNames: [],
  Password: null,
  PhoneNumber: null,
  Country: null,
  City: null,
  State: null,
};

export default createuserModel;
