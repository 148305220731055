import * as React from "react";
import {useState,useEffect} from "react";
import * as Module from './Module.react';

const { forwardRef, useRef, useImperativeHandle } = React;

const CreateCurrency = forwardRef((props,ref) =>
{
    const [state, setstate] = useState({
        id:0,
        loading: true,
        CurrencyName: null,
        CurrencyCode: null,
        Priority: null,
        isdataavailable: false,        
        URL: null,        
        isbuttondisable: false,
      });

    Module.ar.getCurrencyDetailsById(props.match.params.id).then(
    result=>{
         setstate(
             { ...state,
                isdataavailable: true,
                CurrencyName: result[0].currencyName,
                CurrencyCode: result[0].currencyCode,
                id:result[0].id,
             })
    },error=>{

    console.log(error);
    })

      return (
        <Module.SiteWrapper>
           <Module.Container>
         <Module.Formik

        enableReinitialize
        initialValues={{
            CurrencyName: (state.isdataavailable) ? state.CurrencyName : "",
            CurrencyCode: (state.isdataavailable) ? state.CurrencyCode : ""         
        }}
         onSubmit={(
           { CurrencyName, CurrencyCode, Priority },
           { setStatus, setSubmitting }
         ) => {
            var Updatecurrency = {};
            Updatecurrency.Id = props.match.params.id;
            Updatecurrency.currencyName = CurrencyName;
            Updatecurrency.currencyCode = CurrencyCode;
            //Updatecurrency.priority = 4;

           Module.ar.updateCurrencyDetails(Updatecurrency).then(
             result => {
               Module.SnackbarUtils.success("Currency Update successfully !!");
               props.history.push("/CurrencyManagement");
             },
             error => {
             Module.errorresult(error);
             }
           );
         }}
         validate={values => {
           let errors = {};
          
           if (!values.CurrencyName) {
             errors.CurrencyName = "Currency Name Required";
           }
           if (!values.CurrencyCode) {
             errors.CurrencyCode = "Currency Code Required";
           }
           
           return errors;
         }}
         validateOnChange={false}
         validateOnBlur={false}
         render={({
           values,
           errors,
           touched,
           handleChange,
           handleBlur,
           handleSubmit,
           isSubmitting,
         }) => (
             <Module.Form  onSubmit={handleSubmit}>
               <Module.Card.Body className="card p-6">
                 <Module.Card.Title RootComponent="div">Edit Currency</Module.Card.Title>
                 <p>Enter your details below to create currency</p>
                 <Module.Form.Group>
                   <Module.Form.Label>Currency Name <span className="required">*</span></Module.Form.Label>
                   <Module.Form.Input
                     name="CurrencyName"
                     placeholder="CurrencyName"
                     value={values.CurrencyName}
                     error={errors.CurrencyName}
                     onChange={handleChange}
                     onBlur={handleBlur}
                   />
                 </Module.Form.Group>
                 <Module.Form.Group>
                   <Module.Form.Label>Currency Code <span className="required">*</span></Module.Form.Label>
                   <Module.Form.Input
                     name="CurrencyCode"
                     placeholder="Currency Code"
                     value={values.CurrencyCode}
                     error={errors.CurrencyCode}
                     onChange={handleChange}
                     onBlur={handleBlur}
                   />
                 </Module.Form.Group>
                 

             </Module.Card.Body>
              <Module.Card.Footer className="text-right mt-25">
 
                 <Module.Button type="button" color="btn btn-primary mr-4 btn-secondary" onClick={e => props.history.push("/CurrencyManagement") }>Back</Module.Button>
                 <Module.Button type="submit" color="primary">
                   Update
                 </Module.Button>
               
               </Module.Card.Footer>
           </Module.Form>
         )}
       />
        </Module.Container>
       </Module.SiteWrapper>
     )

});

export default CreateCurrency;
