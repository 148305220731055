import * as React from "react";
import {useState,useEffect} from "react";
import { Redirect } from "react-router-dom";
import * as Module from './Module.react';

const { forwardRef, useRef, useImperativeHandle } = React;

function replaceAll(string, search, replace) {
  return string.split(search).join(replace);
}

const States = forwardRef((props,ref) =>
{
    const [stateobj, setstateobj] = useState({
        States: null,
        Country: null,
        IsActive: true,
      });
    const [Loading, setLoading] = React.useState(true);
    const [ButtonStatus, setButtonStatus] = React.useState(true);
    const [Countries, setCountries] = React.useState([]);
      

    useEffect(()=>
    {
        Module.ar.getCountryDataWithoutLogin().then(countrydata => {
            setCountries(countrydata);
        });
        console.log(props.match.params.id);
        if(props.match.params.id != null) {
            Module.ar.getStates(props.match.params.id).then(result=>{
                setstateobj(
                {
                    States: result.states,
                    Country: result.country,
                    IsActive: result.isActive,
                });
                console.log(result);
                console.log(stateobj);
                setLoading(false);
            },error=>{
            console.log(error);
            });
        } else {
            setLoading(false);
        }
    },[])
      return (
        <>
        <Module.SiteWrapper>
        {Loading ? (
            <Module.Loading />
            ) : (
           <Module.Container>
                <Module.Formik
                    enableReinitialize
                    initialValues={{
                    States: (stateobj.States) ? stateobj.States : "",
                    Country: (stateobj.Country) ? stateobj.Country : "",
                    }}
                    onSubmit={(
                    { States, Country },
                    { setStatus, setSubmitting }
                    ) => {
                        setButtonStatus(false);
                        var Updatestate = {};
                        if(props.match.params.id) {
                            Updatestate.Id = props.match.params.id;
                            Updatestate.States = States;
                            Updatestate.Country = Country;

                            Module.ar.updateStates(Updatestate).then(
                                result => {
                                    Module.SnackbarUtils.success("State List Updated successfully !!"); 
                                    props.history.push("/StateManagement");
                                    setButtonStatus(true);
                                },
                                error => {
                                    Module.errorresult(error);
                                    setButtonStatus(true);
                                }
                            );
                        } else {
                            Updatestate.States = States;
                            Updatestate.Country = Country;
                            Module.ar.addStates(Updatestate).then(
                                result => {
                                    Module.SnackbarUtils.success("State List Added successfully !!"); 
                                    props.history.push("/StateManagement");
                                    setButtonStatus(true);
                                },
                                error => {
                                    Module.errorresult(error);
                                    setButtonStatus(true);
                                }
                            );
                        }
                    }}
                    validate={values => {
                    let errors = {};
                    
                    if (!values.States) {
                        errors.States = "States Required";
                    }
                    if (!values.Country) {
                        errors.Country = "Select Any Country";
                    }
                    return errors;
                    }}
                    validateOnChange={false}
                    validateOnBlur={false}
                    render={({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    }) => (
                        <Module.Form  onSubmit={handleSubmit}>
                        <Module.Card.Body className="card p-6">
                            <Module.Card.Title RootComponent="div">{(props.match.params.id) ? 'Update' : 'Add'} States</Module.Card.Title>
                            <p>Enter your details below to create State List</p>
                            <Module.Form.Group>
                            <Module.Form.Label>State List <span className="required">*</span></Module.Form.Label>
                            <Module.Form.Textarea
                                name="States"
                                placeholder="State List"
                                className="comment-box"
                                value={values.States}
                                error={errors.States}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            </Module.Form.Group>
                            <Module.Form.Group>
                            <Module.Form.Label>Country<span className="required">*</span></Module.Form.Label>
                            <Module.Form.Select
                                name="Country"
                                value={values.Country}
                                error={errors.Country}
                                onChange={handleChange}
                            >
                                <option value="">Select Country</option>
                                {Countries.map((option) => (
                                <option value={option.id}>{option.countryName}</option>
                                ))}
                            </Module.Form.Select>
                            </Module.Form.Group>

                        </Module.Card.Body>
                        <Module.Card.Footer className="text-right mt-25">
            
                            <Module.Button type="button" color="btn btn-primary mr-4 btn-secondary" onClick={e => props.history.push("/StateManagement") }>Back</Module.Button>
                            <Module.Button type="submit" color="primary" disabled={!ButtonStatus}>
                            {(props.match.params.id) ? 'Update' : 'Add'}
                            </Module.Button>
                        
                        </Module.Card.Footer>
                    </Module.Form>
                    )}
                />
            </Module.Container>
            )}
        </Module.SiteWrapper>
        )
    </>
     )

});

export default States;