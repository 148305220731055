// @flow

import React from "react";
import "./_services/FetchInterceptor";
import ReactDOM from "react-dom";
import store  from "./store/store";
import { Provider } from "react-redux";
import "./index.css";
import "./c3jscustom.css";

import App from "./App.react";

import { SnackbarProvider } from 'notistack';

const rootElement = document.getElementById("root");

if (rootElement) {
  ReactDOM.render(
    <Provider store={store}>
      <SnackbarProvider maxSnack={3} anchorOrigin={{horizontal: 'right', vertical: 'top' }}>
        <App />
      </SnackbarProvider>
    </Provider>,
    rootElement
  );
} else {
  throw new Error("Could not find root element to mount to!");
}
