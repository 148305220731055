import UserReducer from './userdata'
import { createStore,combineReducers,applyMiddleware } from "redux";
import thunk from 'redux-thunk'   // we thunk for hold reaction and wait to compelete

const margeReducer = combineReducers({
    user:UserReducer
})

const store = createStore(margeReducer,applyMiddleware(thunk));

export default store;