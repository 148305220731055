import * as React from "react";
import {useState,useEffect} from "react";
import { Redirect } from "react-router-dom";
import * as Module from './Module.react';
import { Client, CountryInput } from "../../_services/apireq";

const { forwardRef, useRef, useImperativeHandle } = React;

var countryinput = CountryInput;

const CreateCountry = forwardRef((props,ref) =>
{
    const [state, setstate] = useState({
        loading: true,
        CountryName: null,
        CountryCode: null,
        Code: null,
        CurrencyId: null,
        Priority: null,
        isdataavailable: false,       
        isbuttondisable: false,
      });
    const [curencyDataApi, setCurencyDataApi] = useState([])
      useEffect(() => {          
          Module.ar.getCurrencyData().then(
            result=>{
              setCurencyDataApi(result)
            },error=>{
               console.log(error);
            })
      }, [])
      return (
        <Module.SiteWrapper>
           <Module.Container>
         <Module.Formik

         initialValues={{
           CountryName: (state.isdataavailable) ? state.CountryName : "",
           CountryCode: (state.isdataavailable) ? state.CountryCode : "",
           Code: (state.isdataavailable) ? state.Code : "",
           CurrencyId: (state.isdataavailable) ? state.CurrencyId : "",
           Priority: (state.isdataavailable) ? state.Priority : "",
         }}

         onSubmit={(
           { CountryName, CountryCode, Code, CurrencyId, Priority },
           { setStatus, setSubmitting }
         ) => {
          countryinput = {
            id : 0,
            CountryName : CountryName,
            CountryCode : CountryCode,
            Code : "+" + Code,
            CurrencyId : CurrencyId,
            Priority : 4,
           }

           Module.ar.createCountry(countryinput).then(
             result => {
               Module.SnackbarUtils.success("Thanks! Country Has Been Created Successfully");
               props.history.push("/CountryManagement");
             },
             error => {
             Module.errorresult(error);
             }
           );
         }}
         validate={values => {
           let errors = {};
          
           if (!values.CountryName) {
             errors.CountryName = "Country Name Required";
           }
           if (!values.CountryCode) {
             errors.CountryCode = "Country Code Required";
           }
           if (!values.Code) {
             errors.Code = "Phone Code Required";
           }
           else if (!/^([0-9])*$/i.test(values.Code)) {
            errors.Code = "Invalid Phone Code";
           }  
           if (!values.CurrencyId) {
             errors.currencyId = "Select Currency Name";
           }
           return errors;
         }}
         validateOnChange={false}
         validateOnBlur={false}
         render={({
           values,
           errors,
           touched,
           handleChange,
           handleBlur,
           handleSubmit,
           isSubmitting,
         }) => (
             <Module.Form  onSubmit={handleSubmit}>
               <Module.Card.Body className="card p-6">
                 <Module.Card.Title RootComponent="div">Create New Country</Module.Card.Title>
                 <p>Enter your details below to create country</p>
                 <Module.Form.Group>
                   <Module.Form.Label>Country Name <span className="required">*</span></Module.Form.Label>
                   <Module.Form.Input
                     name="CountryName"
                     placeholder="CountryName"
                     disabled = {state.isdataavailable}
                     value={values.CountryName}
                     error={errors.CountryName}
                     onChange={handleChange}
                     onBlur={handleBlur}
                   />
                 </Module.Form.Group>
                 <Module.Form.Group>
                   <Module.Form.Label>Country Code <span className="required">*</span></Module.Form.Label>
                   <Module.Form.Input
                     name="CountryCode"
                     disabled = {state.isdataavailable}
                     placeholder="Country Code"                    
                     value={values.CountryCode}
                     error={errors.CountryCode}
                     onChange={handleChange}
                     onBlur={handleBlur}
                   />
                 </Module.Form.Group>
                 <Module.Form.Group>
                   <Module.Form.Label>Phone Code <span className="required">*</span><span >  (Enter number only)</span></Module.Form.Label>
                   <Module.Form.Input
                     name="Code"
                     placeholder="Code"
                     value={values.Code}
                     error={errors.Code}
                     onChange={handleChange}
                     onBlur={handleBlur}
                   />
                 </Module.Form.Group>
                 <Module.Form.Group>
                   <Module.Form.Label>Currency Name <span className="required">*</span></Module.Form.Label>
                   <Module.Form.Select
                     name="CurrencyId"
                     value={values.currencyId}
                     error={errors.currencyId}
                     onChange={handleChange}
                   >
                     <option value="">Select Currency Name</option>
                     {curencyDataApi.length && curencyDataApi.map((option) => (
                       <option value={option.id}>{option.currencyName}</option>
                     ))}
                   </Module.Form.Select>
                 </Module.Form.Group>

             </Module.Card.Body>
              <Module.Card.Footer className="text-right mt-25">
 
                 <Module.Button type="button" color="btn btn-primary mr-4 btn-secondary" onClick={e => props.history.push("/CountryManagement") }>Back</Module.Button>
                 <Module.Button type="submit" color="primary">
                   Create Country
                 </Module.Button>
               
               </Module.Card.Footer>
           </Module.Form>
         )}
       />
        </Module.Container>
       </Module.SiteWrapper>
     )

});

export default CreateCountry;
