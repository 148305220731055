import React, { Component, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { PrivateRoute } from "./_services";
import  Role  from "./_services/Roles";
import { SnackbarProvider, useSnackbar } from "notistack";
import SnackbarUtils from './_services/SnackbarUtils';
import {
  ForgotPasswordPage,
  ForgotPasswordConfirmation,
  ResetPassword,
  LoginPage,
  Logout,
  RegisterPage,
  Family,
  MyFamily,
  Investment,
  MyInvestment,
  Error400,
  Error401,
  Error403,
  Error404,
  Error500,
  Error503,
  Empty,
  Email,
  ProfilePage,
  Subscription,
  MySubscription,
  ChangePassword,
  SharedInvestment,
  UsersManagement,
  EmailManagement,
  EmailTemplate,
  RegisterUser,
  EditUser,
  UserInvestment,
  UserFamily,
  CountryManagement,
  StateManagement,
  States,
  CreateCountry,
  EditCountry,
  DeleteCountry,
  CurrencyManagement,
  CreateCurrency,
  EditCurrency,
  DeleteCurrency,
  CouponManagement,
  Coupon,
  PricingManagement,
  Pricing,
  UsersSubscriptions,
  UsersSubscriptionDetails,
  InvestmentTitleManagement,
  InvestmentTitle,
} from "./pages";

import HomePage from "./HomePage.react";
import FormElementsPage from "./FormElementsPage.react";
import PricingCardsPage from "./interface/PricingCardsPage.react";
import CardsDesignPage from "./interface/CardsDesignPage.react";
import StoreCardsPage from "./components/StoreCardsPage.react.js";
import IconPage from "./components/IconPage.react.js";
import ChartsPage from "./interface/ChartsPage.react";
import GalleryPage from "./GalleryPage.react";
import BlogPage from "./components/BlogPage.react";
import ReactGA from "react-ga";

import "./tabler-react/Tabler.css";

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE);

const App = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   
    useEffect(()=>{
        ReactGA.pageview(window.location.pathname + window.location.search);
        SnackbarUtils.setSnackBar(enqueueSnackbar,closeSnackbar);
    },[]);
  return (
    <React.StrictMode>
      <Router basename={process.env.REACT_APP_PRE_BIND_DIRECTION_ROUTE}>
        <Switch>
          <PrivateRoute exact path="/" component={HomePage}  role={[Role.User]} />
          <PrivateRoute exact path="/400" component={Error400} />
          <PrivateRoute exact path="/401" component={Error401} />
          <PrivateRoute exact path="/403" component={Error403} />
          <PrivateRoute exact path="/404" component={Error404} />
          <PrivateRoute exact path="/500" component={Error500} />
          <PrivateRoute exact path="/503" component={Error503} />
          <PrivateRoute exact path="/blog" component={BlogPage} />
          <PrivateRoute exact path="/cards" component={CardsDesignPage}  role={[Role.User]} />
          <PrivateRoute exact path="/charts" component={ChartsPage}  role={[Role.User]} />
          <PrivateRoute exact path="/email" component={Email}  role={[Role.User]} />
          <PrivateRoute exact path="/empty-page" component={Empty}  role={[Role.User]} />
          <PrivateRoute
            exact
            path="/form-elements"
            component={FormElementsPage}  role={[Role.User]}
          />

          <PrivateRoute exact path="/gallery" component={GalleryPage}  role={[Role.User]} />
          <PrivateRoute exact path="/icons" component={IconPage}  role={[Role.User]} />
          <Route exact path="/login" component={LoginPage}  role={[Role.User]} />
          <PrivateRoute
            exact
            path="/pricing-cards"
            component={PricingCardsPage}  role={[Role.User]}
          />
          <PrivateRoute exact path="/profile" component={ProfilePage}  role={[Role.User]} />

          <PrivateRoute exact path="/store" component={StoreCardsPage}  role={[Role.User]} />

          <PrivateRoute
            exact
            path="/MySubscription"
            component={MySubscription}  role={[Role.User]}
          />
          <PrivateRoute exact path="/Family" component={Family}  role={[Role.User]} />
          <PrivateRoute exact path="/Family/:id" component={Family}  role={[Role.User]} />
          <PrivateRoute exact path="/MyFamily" component={MyFamily}   role={[Role.User]} />
          <PrivateRoute exact path="/Investment" component={Investment}  role={[Role.User]} />
          <PrivateRoute exact path="/Investment/:id" component={Investment}  role={[Role.User]} />
          <PrivateRoute exact path="/MyInvestment" component={MyInvestment}  role={[Role.User]} />
          <PrivateRoute exact path="/ChangePassword" component={ChangePassword}  />

          <PrivateRoute exact path="/SharedInvestment" component={SharedInvestment}  role={[Role.User]} />

          <Route exact path="/register" component={RegisterPage} />
          <Route path="/forgot-password" component={ForgotPasswordPage} />
          <Route
            path="/ForgotPasswordConfirmation"
            component={ForgotPasswordConfirmation}
          />
          <Route path="/resetpassword" component={ResetPassword} />

          <Route exact path="/logout" component={Logout} />
          <PrivateRoute exact path="/Subscription" component={Subscription} />

          <PrivateRoute exact path="/UsersManagement" component={UsersManagement} role={[Role.Admin]} />
          <PrivateRoute exact path="/EmailManagement" component={EmailManagement} role={[Role.Admin]} />
          <PrivateRoute exact path="/EmailTemplate" component={EmailTemplate} role={[Role.Admin]} />
          <PrivateRoute exact path="/EmailTemplate/:id" component={EmailTemplate}  role={[Role.Admin]} />
          <PrivateRoute exact path="/RegisterUser" component={RegisterUser} role={[Role.Admin]} />
          <PrivateRoute exact path="/EditUser/:id" component={EditUser} role={[Role.Admin]} />    
          <PrivateRoute exact path="/UserInvestment/:id" component={UserInvestment} role={[Role.Admin]} />   
          <PrivateRoute exact path="/UserFamily/:id" component={UserFamily} role={[Role.Admin]} />  

          <PrivateRoute exact path="/CountryManagement" component={CountryManagement} role={[Role.Admin]} /> 
          <PrivateRoute exact path="/StateManagement" component={StateManagement} role={[Role.Admin]} /> 
          <PrivateRoute exact path="/States" component={States} role={[Role.Admin]} /> 
          <PrivateRoute exact path="/States/:id" component={States} role={[Role.Admin]} /> 
          <PrivateRoute exact path="/CreateCountry" component={CreateCountry} role={[Role.Admin]} />
          <PrivateRoute exact path="/EditCountry/:id" component={EditCountry} role={[Role.Admin]} />   
          <PrivateRoute exact path="/DeleteCountry/:id" component={DeleteCountry} role={[Role.Admin]} />  

           <PrivateRoute exact path="/CurrencyManagement" component={CurrencyManagement} role={[Role.Admin]} /> 
          <PrivateRoute exact path="/CreateCurrency" component={CreateCurrency} role={[Role.Admin]} />
          <PrivateRoute exact path="/EditCurrency/:id" component={EditCurrency} role={[Role.Admin]} />   
          <PrivateRoute exact path="/DeleteCurrency/:id" component={DeleteCurrency} role={[Role.Admin]} />           
          <PrivateRoute exact path="/UserInvestment/:id" component={UserInvestment} role={[Role.Admin]} />
          <PrivateRoute exact path="/UserFamily/:id" component={UserFamily} role={[Role.Admin]} />

          <PrivateRoute exact path="/CouponsManagement" component={CouponManagement} role={[Role.Admin]} />
          <PrivateRoute exact path="/Coupon" component={Coupon} role={[Role.Admin]} />
          <PrivateRoute exact path="/Coupon/:id" component={Coupon} role={[Role.Admin]} />

          <PrivateRoute exact path="/PricingManagement" component={PricingManagement} role={[Role.Admin]} />
          <PrivateRoute exact path="/Pricing" component={Pricing} role={[Role.Admin]} />
          <PrivateRoute exact path="/Pricing/:id" component={Pricing} role={[Role.Admin]} />

          <PrivateRoute exact path="/UsersSubscriptions" component={UsersSubscriptions} role={[Role.Admin]} />
          <PrivateRoute exact path="/UsersSubscriptionDetails/:id/:userid" component={UsersSubscriptionDetails} role={[Role.Admin]} />
          <PrivateRoute exact path="/UsersSubscriptionDetails/:id/:userid/:action" component={UsersSubscriptionDetails} role={[Role.Admin]} />
          <PrivateRoute exact path="/InvestmentTitleManagement" component={InvestmentTitleManagement} role={[Role.Admin]} />
          <PrivateRoute exact path="/InvestmentTitle" component={InvestmentTitle} role={[Role.Admin]} />
          <PrivateRoute exact path="/InvestmentTitle/:id" component={InvestmentTitle} role={[Role.Admin]} />
          <Route component={Error404} />
        </Switch>
      </Router>
    </React.StrictMode>
  );
}

export default App;
