import * as React from "react";
import {useState,useEffect} from "react";
import { Redirect } from "react-router-dom";
import * as Module from './Module.react'
import createuserModel from "../../_services/dto/createuserModel";

const { forwardRef, useRef, useImperativeHandle } = React;

const RegisterUser = forwardRef((props,ref) =>
{
 

   const [count, setCount] = useState(0);
const [state, setstate] = useState({
    id:0,
    loading: true,
    name: null,
    email: null,
    PhoneNumber: null,
    PhoneNumberFormate: null,
    isdataavailable: false,
    GUID: null,
    URL: null,
    showtermsandcondition:false,
    isbuttondisable: false,
    state:null,
    country:null,
    city:null
  });
  const [countries, setcountries] = useState();


useEffect(()=>
  {
    Module.ar.getCountryDataWithoutLogin().then(res=>{
      setcountries(res);
    });
    console.log(props.match.params.id);

    Module.ar.getUserProfile(props.match.params.id).then(
    result=>{
      console.log(result.phonenumberformate);
         setstate(
             { ...state,
                isdataavailable: true,
                name:result.firstname+" "+result.lastname,
                city: result.city,
                country: result.country,
                email: result.emailaddress,
                id:result.id,
                PhoneNumber: result.phonenumber,
                PhoneNumberFormate: "+" + result.phonenumberformate,
                state: result.state
             })
    },error=>{

    console.log(error);
    })

  },[])

    return (

       <Module.SiteWrapper>
        <Module.Container>
 
        <Module.Formik
        enableReinitialize={true}
        initialValues={state}
        onSubmit={(
          { name, email,  PhoneNumber, PhoneNumberFormate, country, city, state },
          { setStatus, setSubmitting }
        ) => {
         // setState({ isbuttondisable: true });
          var Updateuser = {};
          Updateuser.Id = props.match.params.id;
          Updateuser.firstname = name;
          Updateuser.lastname = name;
          Updateuser.phonenumber = PhoneNumberFormate + "-" + PhoneNumber;
          Updateuser.country = country;
          Updateuser.city = city;
          Updateuser.state = state;
          Module.ar.updateUserProfile(Updateuser).then(
            result => {
              Module.SnackbarUtils.success("User Update successfully !!");
              
              props.history.push("/UsersManagement");
            },
            error => {
            Module.errorresult(error);
            }
          );
        }}
        validate={values => {
          // same as above, but feel free to move this into a class method now.
          let errors = {};
          if (!values.email) {
            errors.email = "Email address Required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }
          if (!values.name) {
            errors.name = "Name Required";
          }
          if (!values.PhoneNumberFormate) {
            errors.PhoneNumberFormate = "Select Country Code";
          }
          if (!values.PhoneNumber) {
            errors.PhoneNumber = "Phone Number Required";
          } 
          //else if (!/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i.test(values.PhoneNumber)) {
            else if (!/^([0-9])*$/i.test(values.PhoneNumber)) {
            errors.PhoneNumber = "Invalid Phone Number";
          }
          if (!values.city) {
            errors.city = "City Required";
          }
          if (!values.state) {
            errors.state = "State Required";
          }
          if (!values.country) {
            errors.country = "Select Country";
          }
          return errors;
        }}
        validateOnChange={false}
        validateOnBlur={false}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
            <Module.Form onSubmit={handleSubmit}>
              <Module.Card.Body className="card p-6">
                <Module.Card.Title RootComponent="div">Edit Account</Module.Card.Title>
                <p>Enter your details below to create an account with us</p>
                <Module.Form.Group>
                  <Module.Form.Label>Name <span className="required">*</span></Module.Form.Label>
                  <Module.Form.Input
                    name="name"
                    placeholder="Name"
                    value={values.name}
                    error={errors.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Module.Form.Group>
                <Module.Form.Group>
                  <Module.Form.Label>Email Address <span className="required">*</span></Module.Form.Label>
                  <Module.Form.Input
                    name="email"
                    type="email"
                    disabled
                    placeholder="Email Address"
                    value={values.email}
                    error={errors.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Module.Form.Group>
                
                <Module.Form.Group>
                  <Module.Form.Label>Where do you live <span className="required">*</span></Module.Form.Label>
                  <Module.Form.Select
                    name="country"
                    value={values.country}
                    error={errors.country}
                    onChange={handleChange}
                  >
                    <option value="">Select Country</option>
                    {countries && countries.map((option) => (
                      <option value={option.id}>{option.countryName}</option>
                    ))}
                  </Module.Form.Select>
                </Module.Form.Group>
                <Module.Form.Group>
                  <Module.Form.Label>City & State / Province <span className="required">*</span></Module.Form.Label>
                  <Module.Grid.Row>
                    <Module.Grid.Col sm={6} md={6}>
                      <Module.Form.Group className="mb-0">
                      <Module.Form.Input
                        name="city"
                        placeholder="City"
                        value={values.city}
                        error={errors.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      </Module.Form.Group>
                    </Module.Grid.Col>
                    <Module.Grid.Col sm={6} md={6}>
                      <Module.Form.Group className="mb-0">
                        <Module.Form.Input
                          name="state"
                          placeholder="State"
                          value={values.state}
                          error={errors.state}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Module.Form.Group>
                    </Module.Grid.Col>
                  </Module.Grid.Row>
                </Module.Form.Group>
                
                <Module.Form.Group>
                  <Module.Form.Label>Phone Number <span className="required">*</span></Module.Form.Label>
                  <Module.Grid.Row>
                    <Module.Grid.Col sm={4} md={4}>
                      <Module.Form.Group className="mb-0">
                      <Module.Form.Select
                        name="PhoneNumberFormate"
                        
                        value={values.PhoneNumberFormate}
                        error={errors.PhoneNumberFormate}
                        onChange={handleChange}
                      >
                        <option value="">Select Country Code</option>
                        {countries && countries.map((option) => (
                            <option value={option.code}>{option.countryName + " (" + option.code.replace("~","") + ")"}</option>
                        ))}
                      </Module.Form.Select>
                      </Module.Form.Group>
                    </Module.Grid.Col>
                    <Module.Grid.Col sm={8} md={8}>
                      <Module.Form.Group className="mb-0">
                      <Module.Form.Input
                        name="PhoneNumber"
                        
                        placeholder="Enter Phone Number"
                        value={values.PhoneNumber}
                        error={errors.PhoneNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      </Module.Form.Group>
                    </Module.Grid.Col>
                  </Module.Grid.Row>

                </Module.Form.Group>

            </Module.Card.Body>
            <Module.Card.Footer className="text-right mt-25">

                <Module.Button type="button" color="btn btn-primary mr-4 btn-secondary" onClick={e => props.history.push("/UsersManagement") }>Back</Module.Button>
                <Module.Button type="submit" color="primary">
                  Update
                </Module.Button>
              
              </Module.Card.Footer>
          </Module.Form>
        )}
      />
      </Module.Container>
      </Module.SiteWrapper>
    )

});

export default RegisterUser;