// @flow

import * as React from "react";

import { FormCardLogin, FormTextInput, StandaloneFormPage } from "../../../";
import withTouchedErrors from "../../../helpers/withTouchedErrors.react";

import defaultStrings from "./ResetPassword.strings";
import type { stringTypes } from "./ResetPassword.strings";
import type { FormEvents, FocusEvents } from "../../../";

type fieldTypes = {|
  Newpassword?: string,
  Confirmpassword?: string,
|};

type touchedTypes = {|
  Newpassword?: boolean,
  Confirmpassword?: boolean,
|};

type Props = {|
  ...FormEvents,
  ...FocusEvents,
  +strings?: stringTypes,
  +action?: string,
  +method?: string,
  +values?: fieldTypes,
  +errors?: fieldTypes,
  +touched?: touchedTypes,
|};

/**
 * A login page
 * Can be easily wrapped with form libraries like formik and redux-form
 */
function ResetPassword(props: Props): React.Node {
  const {
    action,
    method,
    onSubmit,
    onChange,
    onBlur,
    values,
    strings = {},
    errors,
  } = props;

  return (
    <StandaloneFormPage imageURL={"./demo/brand/mwl-logo-h-sml-w2.png"}>
      <FormCardLogin
        buttonText={strings.buttonText || defaultStrings.buttonText}
        title={strings.title || defaultStrings.title}
        onSubmit={onSubmit}
        action={action}
        method={method}
        forgotbuttonText={
          strings.forgotbuttonText || defaultStrings.forgotbuttonText
        }
      >
        <FormTextInput
          name="Newpassword"
          type="password"
          label={strings.NewPasswordLabel || defaultStrings.NewPasswordLabel}
          placeholder={
            strings.NewPasswordPlaceholder ||
            defaultStrings.NewPasswordPlaceholder
          }
          onChange={onChange}
          onBlur={onBlur}
          value={values && values.Newpassword}
          error={errors && errors.Newpassword}
        />
        <FormTextInput
          name="Confirmpassword"
          type="password"
          label={
            strings.ConfirmPasswordLabel || defaultStrings.ConfirmPasswordLabel
          }
          placeholder={
            strings.ConfirmpasswordPlaceholder ||
            defaultStrings.ConfirmpasswordPlaceholder
          }
          onChange={onChange}
          onBlur={onBlur}
          value={values && values.Confirmpassword}
          error={errors && errors.Confirmpassword}
        />
      </FormCardLogin>
    </StandaloneFormPage>
  );
}

const ResetPasswordWithTouchedErrors: React.ComponentType<Props> = withTouchedErrors(
  ["Newpassword", "Confirmpassword"]
)(ResetPassword);

export default ResetPasswordWithTouchedErrors;
