//@flow
const strings = {
  title: "Create New Account",
  buttonText: "Create Account",
  nameLabel: "Name",
  namePlaceholder: "Enter name",
  emailLabel: "Email Address",
  emailPlaceholder: "Enter email",
  passwordLabel: "Password",
  passwordPlaceholder: "Password",
  PhoneNumberLabel: "Phone Number",
  PhoneNumberPlaceholder: "Enter Phone Number",
  termsLabel: "Agree to the terms and policy",
  countryLabel: "Where do you live",
  countryPlaceholder: "Select Country",
};

export default strings;

export type stringTypes = { [$Keys<typeof strings>]: string };
