import Cookies from "js-cookie";
export default async function GetProfileImage(name) {
  try{
    if(name) {
      var url = process.env.REACT_APP_REMOTE_SERVICE_BASE_URL + '/api/services/app/MyProfile/DownloadProfileImageUsingname?name=' + name;
      let data = await fetch(url, {
        method: 'POST',
        headers: {
          "Authorization": "Bearer " + Cookies.get("Abp.AuthToken"),
          "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
          "Access-Control-Allow-Origin": "https://app.mywealthlocker.com",
        },
      })
      .then((response) => {
        return response.blob();
      })
      .then((data) => {
        var objectURL = URL.createObjectURL(data); 
        //this.setState({pic: objectURL});
        return objectURL;
      });
      return data;
    } else {
      var url = process.env.REACT_APP_REMOTE_SERVICE_BASE_URL + '/api/services/app/MyProfile/DownloadProfileImage';
      let data = await fetch(url, {
        method: 'POST',
        headers: {
          "Authorization": "Bearer " + Cookies.get("Abp.AuthToken"),
          "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
          "Access-Control-Allow-Origin": "https://app.mywealthlocker.com",
        },
      })
      .then((response) => {
        return response.blob();
      })
      .then((data) => {
        var objectURL = URL.createObjectURL(data); 
        //this.setState({pic: objectURL});
        return objectURL;
      });
      return data;
    }
  }
  catch(error){
    return null;
  }
}