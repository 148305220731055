export function getfirstlatters(fname,lname) {
    return fname.charAt(0).toUpperCase() +  lname.charAt(0).toUpperCase();
}
export function getfirstlattersconditional(fname,lname) {
    let creator = '';
    if(fname != null && fname != "")
      creator += fname.charAt(0).toUpperCase();
    if(lname != null && lname != "")
      creator += lname.charAt(0).toUpperCase();
    else
      if(fname != null && fname != "")
        creator += fname.charAt(1).toUpperCase();
    return creator;
  }
export function formatNumber(n) {
    // format number 1000000 to 1,234,567
    if (n.indexOf('.') > -1) {
        n = n.split('.')[0]
    }
    return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}
export function rendercardbrand(brand) {
  switch(brand.toLowerCase()) {
    case 'amex':
      return process.env.REACT_APP_PRE_BIND_DIRECTION + '/demo/cards/card_amex.png';
    case 'cartes_bancaires':
      return process.env.REACT_APP_PRE_BIND_DIRECTION + '/demo/cards/card_cartes_bancaires.png';
    case 'diners_club':
      return process.env.REACT_APP_PRE_BIND_DIRECTION + '/demo/cards/card_diners_club.png';
    case 'discover':
      return process.env.REACT_APP_PRE_BIND_DIRECTION + '/demo/cards/card_discover.png';
    case 'jcb':
      return process.env.REACT_APP_PRE_BIND_DIRECTION + '/demo/cards/card_jcb.png';
    case 'mastercard':
      return process.env.REACT_APP_PRE_BIND_DIRECTION + '/demo/cards/card_mastercard.png';
    case 'visa':
      return process.env.REACT_APP_PRE_BIND_DIRECTION + '/demo/cards/card_visa.png';
    case 'unionpay':
      return process.env.REACT_APP_PRE_BIND_DIRECTION + '/demo/cards/card_unionpay.png';
    default:
      return '';
  }  
}