import React from "react";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router";

class BreadCum extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="breadcum">
                <a href="#!" onClick={(e) => { this.props.history.push(this.props.routes.root.path) }}>{this.props.routes.root.title}</a>
                {this.props.routes.child.map((item, i) => (
                    <div className="d-inline" key={i}>
                        <span key={i}> &gt; </span>
                        {(item.path != '') ? <a href="#!" onClick={(e) => { this.props.history.push(item.path) }}>{item.title}</a> : <span>{item.title}</span>}
                    </div>
                ))}
            </div>
        );
    }
}
export default withRouter(BreadCum);