// @flow

import * as React from "react";

import {
  FormCardRegister,
  FormTextInput,
  FormCheckboxInput,
  StandaloneFormPageRegister,
  FormTextInputPhoneNumber,
  DropDownBox,
} from "../../../";
import withTouchedErrors from "../../../helpers/withTouchedErrors.react";

import defaultStrings from "./RegisterPage.strings";
import type { stringTypes } from "./RegisterPage.strings";

import type { FormEvents, FocusEvents } from "../../../";

type fieldTypes = {|
  name?: string,
  email?: string,
  password?: string,
  PhoneNumber?: string,
  PhoneNumberFormate?: string,
  //terms?: string,
|};

type touchedTypes = {|
  name?: boolean,
  email?: boolean,
  password?: boolean,
  PhoneNumber?: boolean,
  PhoneNumberFormate?: boolean,
  //terms?: boolean,
|};

type Props = {|
  ...FormEvents,
  ...FocusEvents,
  +strings?: stringTypes,
  +action?: string,
  +method?: string,
  +values?: fieldTypes,
  +errors?: fieldTypes,
  +touched?: touchedTypes,
|};

/**
 * A register page
 * Can be easily wrapped with form libraries like formik and redux-form
 */
function RegisterPage(props: Props): React.Node {
  const {
    action,
    method,
    onSubmit,
    onChange,
    onBlur,
    values,
    strings = {},
    errors,
    forgotbuttonText,
    dropdownonbuttonname,
    dropdownonbuttononchange,
    dropdownonbuttonononBlur,
    dropdownonbuttononvalue,
    dropdownonbuttononerror,
  } = props;

  return (
    <StandaloneFormPageRegister imageURL={"./demo/brand/mwl-logo-h-sml-w2.png"}>
      <FormCardRegister
        buttonText={strings.buttonText || defaultStrings.buttonText}
        title={strings.title || defaultStrings.title}
        onSubmit={onSubmit}
        action={action}
        method={method}
        loginlinkurl={values.loginlinkurl || "/app/login"}
      >
        <FormTextInput
          name="name"
          label={strings.nameLabel || defaultStrings.nameLabel}
          placeholder={
            strings.namePlaceholder || defaultStrings.namePlaceholder
          }
          disabled = {(values.disabled)}
          onChange={onChange}
          onBlur={onBlur}
          value={values && values.name}
          error={errors && errors.name}
        />
        <FormTextInput
          name="email"
          label={strings.emailLabel || defaultStrings.emailLabel}
          placeholder={
            strings.emailPlaceholder || defaultStrings.emailPlaceholder
          }
          disabled = {(values.disabled)}
          onChange={onChange}
          onBlur={onBlur}
          value={values && values.email}
          error={errors && errors.email}
        />
        <FormTextInput
          name="password"
          type="password"
          label={strings.passwordLabel || defaultStrings.passwordLabel}
          placeholder={
            strings.passwordPlaceholder || defaultStrings.passwordPlaceholder
          }
          onChange={onChange}
          onBlur={onBlur}
          value={values && values.password}
          error={errors && errors.password}
        />
        <FormTextInputPhoneNumber
          name="PhoneNumber"
          label={strings.PhoneNumberLabel || defaultStrings.PhoneNumberLabel}
          placeholder={
            strings.PhoneNumberPlaceholder ||
            defaultStrings.PhoneNumberPlaceholder
          }
          disabled = {(values.disabled)}
          dropdownonbuttonname="PhoneNumberFormate"
          dropdownonbuttononchange={onChange}
          dropdownonbuttonononBlur={onBlur}
          dropdownonbuttononvalue={values && values.PhoneNumberFormate}
          dropdownonbuttononerror={errors && errors.PhoneNumberFormate}
          dropdownonbuttonondisabled={(values.disabled)}
          onChange={onChange}
          onBlur={onBlur}
          value={values && values.PhoneNumber}
          error={errors && errors.PhoneNumber}
        />
        <DropDownBox
          name="country"
          type="country"
          label={strings.countryLabel || defaultStrings.countryLabel}
          placeholder={
            strings.countryPlaceholder || defaultStrings.countryPlaceholder
          }
          onChange={onChange}
          onBlur={onBlur}
          value={values && values.country}
          error={errors && errors.country}
        />
        {/* <FormCheckboxInput
          onChange={onChange}
          onBlur={onBlur}
          value={values && values.terms}
          name="terms"
          label={strings.termsLabel || defaultStrings.termsLabel}
        /> */}
      </FormCardRegister>
    </StandaloneFormPageRegister>
  );
}

const RegisterPageWithTouchedErrors: React.ComponentType<Props> = withTouchedErrors(
  ["name", "email", "password", "PhoneNumber", "PhoneNumberFormate", "country"]
)(RegisterPage);

export default RegisterPageWithTouchedErrors;
