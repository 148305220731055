// @flow

import React from "react";

import { Container, Grid, Form, Card,Button } from "../tabler-react/index";

import SiteWrapper from "../SiteWrapper.react";
import { Client, MyFamilyInput } from "../_services/apireq";
import BreadCum from "../_services/breadcum";
import Loading from "../_services/loadingpage";

const ar = new Client(process.env.REACT_APP_REMOTE_SERVICE_BASE_URL, window);

var familyinput = MyFamilyInput;

const breadcumroute = {
  root: {title: 'Home', path: '/'},
  child: [
    {title: 'Change Password', path: ''},
  ]
}

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    loading: true,
    result: null,
    buttontext: 'Update Password',
    buttonstatus: true,
    oldpassword: null,
    newpassword: null,
    retypepassword: null,
  };
  async componentDidMount() {
    
  }
  onchangevalidatebox(field,value) {
    if(field == 'emailaddress') {
      if (value == '') {
        this.setState({ [field+'error']: "Required" });
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        this.setState({ [field+'error']: "Enter Valid Email Address" });
      } else {
        this.setState({ [field+'error']: null });
      }
    } else {
      if(value == '' || value == null) {
        this.setState({ [field+'error']: "Required" });
      } else {
        this.setState({ [field+'error']: null });
      }
    }
  }
  validatebox(field,value) {
    let isvalid = true;
    var feildsname = ['firstname','lastname','emailaddress','countrycode','phonenumber','reletionship'];
    if(!this.state.trusteedisabled)
      feildsname.push('trustee');
    else
      this.setState({ trusteeerror: null });
    if(this.state.otherreletionshiptoggle == ' mt-3')
      feildsname.push('otherreletionship');
    else
      this.setState({ otherreletionshiperror: null });

    // if(this.state.firstname == '' || this.state.firstname == null) {
    //   isvalid = false;
    //   this.setState({ firstnameerror: " Required" });
    // } else if(this.state.lastname == '' || this.state.lastname == null) {
    //   isvalid = false;
    //   this.setState({ lastnameerror: " Required" });
    // }
    // else if(this.state.emailaddress == '' || this.state.emailaddress == null) {
    //   isvalid = false;
    //   this.setState({ emailaddresserror: "Email Address Required" });
    // }
    // else if (this.state.emailaddress == '' || this.state.emailaddress == null && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.emailaddress)) {
    //   isvalid = false;
    //   this.setState({ emailaddresserror: "Enter Valid Email Address" });
    // }
    // else if(this.state.countrycode == '' || this.state.countrycode == null) {
    //   isvalid = false;
    //   this.setState({ error: "Country Code Required" });
    // }
    // else if(this.state.phonenumber == '' || this.state.phonenumber == null) {
    //   isvalid = false;
    //   this.setState({ error: "PhoneNumber Required" });
    // }
    // else if(this.state.reletionship == '' || this.state.reletionship == null) {
    //   isvalid = false;
    //   this.setState({ error: "Select Reletionship" });
    // }
    // else if(!this.state.trusteedisabled) {
    //   if(this.state.trustee == '' || this.state.trustee == null) {
    //     isvalid = false;
    //     this.setState({ error: "Trustee Required" });
    //   }
    // }
    // else if(this.state.otherreletionshiptoggle == ' mt-3') {
    //   if(this.state.otherreletionship == '' || this.state.otherreletionship == null) {
    //     isvalid = false;
    //     this.setState({ otherreletionshiperror: "Reletionship Required" });
    //   }
    // }

    feildsname.forEach((result) => {
      if(result == 'emailaddress') {
        if (this.state[result] == '') {
          isvalid = false;
          this.setState({ [result+'error']: "Required" });
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state[result])) {
          isvalid = false;
          this.setState({ [result+'error']: "Enter Valid Email Address" });
        } else {
          this.setState({ [result+'error']: null });
        }
      } else {
        if(this.state[result] == '' || this.state[result] == null) {
          isvalid = false;
          this.setState({ [result+'error']: "Required" });
        } else {
          this.setState({ [result+'error']: null });
        }
      }
    });
    return isvalid;
  }
  handleChange(evt, field) {
    if (field);
    this.setState({ [field]: evt.target.value });

    if(field == 'reletionship') {
      this.onchangevalidatebox(field,evt.target.value);
      if(evt.target.value == 'Other') {
        this.onchangevalidatebox('otherreletionship',this.state.otherreletionship);
        this.setState({ otherreletionshiptoggle: ' mt-3' });
      } else {
        this.setState({ otherreletionshiperror: null, valid: true });
        this.setState({ otherreletionshiptoggle: ' d-none' });
      }
    }
    else if(field == 'minordisabled') {
      this.setState({ minordisabled: evt.target.checked });
      if(evt.target.checked == true) {
        this.onchangevalidatebox('trustee',this.state.trustee);
        this.setState({ trusteedisabled: false });
      } else {
        this.setState({ trusteeerror: null, valid: true });
        this.setState({ trusteedisabled: true });
      }
    } else {
      this.onchangevalidatebox(field,evt.target.value);
    }
  }
  updateprofile = e => {
    e.preventDefault();
    const result = this.validatebox();
      if(result) {
        this.setState({
          buttonstatus: false
        });
        var id = 0;
        if(this.state.id) {
          id = this.state.id;
        }
        familyinput = {
          id:id,
          userid:0,
          Firstname:this.state.firstname,
          Lastname:this.state.lastname,
          Email:this.state.emailaddress,
          PhoneNumber:'+' + this.state.countrycode + '-' + this.state.phonenumber,
          Reletionship: (this.state.reletionship == "Other") ? this.state.otherreletionship : this.state.reletionship,
          MinorDisabled:this.state.minordisabled,
          Trustee: (this.state.trusteedisabled == false) ? this.state.trustee : null,
          DefaultSharing:this.state.defaultsharing,
        };
        const result = ar.addFamilyData(familyinput).then(result => {
            if(this.state.buttonaction == 'update') {
              // notifier({
              //   type: "success",
              //   message: <span key="1">Updated Successfully in Family.</span>,
              //   autoDismissTimeout: 5000,
              // });
              this.setState({
                buttontext: 'Update',
                buttonstatus: true
              });
              setTimeout(
                function() {
                  return this.props.history.push("/MyFamily");
                }.bind(this),
                2000
              );
            } else {
              // notifier({
              //   type: "success",
              //   message: <span key="1">Added Successfully in Family.</span>,
              //   autoDismissTimeout: 5000,
              // });
              this.setState({
                buttontext: 'Save',
                buttonstatus: true
              });
              setTimeout(
                function() {
                  return this.props.history.push("/MyFamily");
                }.bind(this),
                2000
              );
            }
            return result;
          },
          error => {
            return error;
          }
        );
      } else {
        //console.log(this.state.valid);
        //console.log('invalid form');
      }
  };
  render() {
    //let status = this.state.subscriptiondata.account_status == 'ACTIVE' ? 'bg-success' : 'bg-danger';
    return (
      <SiteWrapper>
          {this.state.loading ? (
            <Loading />
          ) : (
            <Container>
              <Form className="card" onSubmit={this.updateprofile} novalidate>
                    <Card.Body>
                    <Grid.Row>
                        <Card.Title>{this.state.statustitle} Family</Card.Title>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Col width={12}>
                          <BreadCum routes={breadcumroute} />
                        </Grid.Col>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Col sm={4} md={4}>
                          <Form.Group>
                            <Form.Label>First Name</Form.Label>
                            <Form.Input
                              type="text"
                              placeholder="First Name"
                              name="firstname"
                              error={this.state.firstnameerror || ""}
                              value={this.state.firstname || ""}
                              onChange={event =>
                                this.handleChange(event, "firstname")
                              }
                            />
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col sm={4} md={4}>
                          <Form.Group>
                            <Form.Label>Last Name</Form.Label>
                            <Form.Input
                              type="text"
                              placeholder="Last Name"
                              error={this.state.lastnameerror || ""}
                              name="lastname"
                              value={this.state.lastname || ""}
                              onChange={event =>
                                this.handleChange(event, "lastname")
                              }
                            />
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col sm={4} md={4}>
                          <Form.Group>
                            <Form.Label>Email address</Form.Label>
                            <Form.Input
                              type="email"
                              placeholder="Email"
                              name="emailaddress"
                              error={this.state.emailaddresserror || ""}
                              value={this.state.emailaddress || ""}
                              onChange={event =>
                                this.handleChange(event, "emailaddress")
                              }
                            />
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col sm={2} md={2}>
                          <Form.Group>
                            <Form.Label>Country Code</Form.Label>
                            <Form.Select
                              name="countrycode"
                              error={this.state.countrycodeerror || ""}
                              value={this.state.countrycode || ""}
                              onChange={event =>
                                this.handleChange(event, "countrycode")
                              }
                            >
                              <option value="">Select Code</option>
                              <option value="1">United States (+1)</option>
                              <option value="91">India (+91)</option>
                            </Form.Select>
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col sm={3} md={3}>
                          <Form.Group>
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Input
                              type="text"
                              placeholder="Phone Number"
                              error={this.state.phonenumbererror || ""}
                              name="phonenumber"
                              value={this.state.phonenumber || ""}
                              onChange={event =>
                                this.handleChange(event, "phonenumber")
                              }
                            />
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col sm={3} md={3}>
                          <Form.Group>
                            <Form.Label>Relationship</Form.Label>
                            <Form.Select
                              name="reletionship"
                              error={this.state.reletionshiperror || ""}
                              value={this.state.reletionship || ""}
                              onChange={event =>
                                this.handleChange(event, "reletionship")
                              }
                            >
                              <option value="">Select Relationship</option>
                              <option value="Son">Son</option>
                              <option value="Father">Father</option>
                              <option value="Daughter">Daughter</option>
                              <option value="Mother">Mother</option>
                              <option value="Spouse">Spouse</option>
                              <option value="Sibling">Sibling</option>
                              <option value="Other">Other</option>
                            </Form.Select>
                            <Form.Input
                              type="text"
                              placeholder="ReletionShip"
                              error={this.state.otherreletionshiperror || ""}
                              name="otherreletionship"
                              className={this.state.otherreletionshiptoggle}
                              value={this.state.otherreletionship || ""}
                              onChange={event =>
                                this.handleChange(event, "otherreletionship")
                              }
                            />
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col sm={3} md={3}>
                          <Form.Group>
                            <Form.Label>Minor/disabled</Form.Label>
                            <Form.Checkbox
                              name="minordisabled"
                              label="Minor/Disabled"
                              value={this.state.minordisabled || false}
                              checked={this.state.minordisabled}
                              onChange={event =>
                                this.handleChange(event, "minordisabled")
                              }
                              onClick={event =>
                                this.handleChange(event, "minordisabled")
                              }
                            />
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col sm={3} md={3}>
                          <Form.Group>
                            <Form.Label>Trustee</Form.Label>
                            <Form.Input
                              type="text"
                              placeholder="Trustee"
                              error={this.state.trusteeerror || ""}
                              name="trustee"
                              disabled={this.state.trusteedisabled}
                              value={this.state.trustee || ""}
                              onChange={event =>
                                this.handleChange(event, "trustee")
                              }
                            />
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col sm={3} md={3}>
                          <Form.Group>
                            <Form.Label>Sharing options</Form.Label>
                            <Form.Radio
                              label="Share"
                              name="defaultsharing"
                              value='1'
                              defaultChecked
                              checked={this.state.defaultsharing === '1'}
                              onChange={event =>
                                this.handleChange(event, "defaultsharing")
                              }
                            />
                            <Form.Radio
                              label="Not Share"
                              name="defaultsharing"
                              value='2'
                              checked={this.state.defaultsharing === '2'}
                              onChange={event =>
                                this.handleChange(event, "defaultsharing")
                              }
                            />
                            <Form.Radio
                              label="Share When Inactive"
                              name="defaultsharing"
                              value='3'
                              checked={this.state.defaultsharing === '3'}
                              onChange={event =>
                                this.handleChange(event, "defaultsharing")
                              }
                            />
                          </Form.Group>
                        </Grid.Col>
                      </Grid.Row>
                    </Card.Body>
                    <Card.Footer className="text-right mt-25">
                      <Button
                        type="button"
                        color="primary"
                        className="mr-4"
                        onClick={e => this.props.history.push("/MyFamily") }>
                          Cancel
                      </Button>
                      <Button
                        type="submit"
                        color="primary"
                        disabled={!this.state.buttonstatus}
                      >
                        {this.state.buttontext}
                      </Button>
                    </Card.Footer>
                  </Form>
            </Container>
          )}
          ;
      </SiteWrapper>
    );
  }
}

export default ChangePassword;
