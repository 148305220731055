// @flow

import * as React from "react";
import { Formik } from "formik";
import OuterWrapper from "../OuterWrapper.react";
import { Container, Grid, Form, Card,Button } from "../tabler-react/index";

import { Redirect, useLocation, useHistory } from "react-router-dom";
import { Client } from "../_services/apireq";
import SnackbarUtils from '../_services/SnackbarUtils';
import { errorresult } from "../_services/abp";

const ar = new Client(process.env.REACT_APP_REMOTE_SERVICE_BASE_URL, window);

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    // const stringified = queryString.stringify(parsed);
    // console.log(stringified);
  }

  getParameter(paramName) {
    var searchString = this.props.location.search.replace("?", ""),
      i,
      val,
      params = searchString.split("&");

    for (i = 0; i < params.length; i++) {
      val = params[i].split(/=(.+)/);
      if (val[0] == paramName) {
        return val[1];
      }
    }
    return null;
  }

  handleSubmit = (values, actions) => {
    var code = this.getParameter("code");
    var username = this.getParameter("username");
    ar.userResetPassword(code, username, values.Newpassword).then(res => {

      if (res.error != null) {
        SnackbarUtils.error(res.error.message);
      } else {
        
        return this.props.history.push("/login");
      }
    },
    error => {
      errorresult(error);
    });
  };

  render() {
    return (
      <Formik
        initialValues={{
          Newpassword: "",
          Confirmpassword: "",
        }}
        validate={values => {
          let errors = {};
          if (!values.Newpassword) {
            errors.Newpassword = "Password Required";
          }
          if (!values.Confirmpassword) {
            errors.Confirmpassword = "Confirm Password Required";
          }

          if (values.Newpassword != values.Confirmpassword) {
            errors.Confirmpassword = "Password does not match!";
          }

          return errors;
        }}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={this.handleSubmit}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <OuterWrapper>
            <Form className="card" onSubmit={handleSubmit}>
              <Card.Body className="p-6">
                <Card.Title RootComponent="div">RESET PASSWORD</Card.Title>
                <p>New Password</p>
                <Form.Group>
                  <Form.Label>New Password</Form.Label>
                  <Form.Input
                    name="Newpassword"
                    type="password"
                    placeholder="Enter Password"
                    value={values.Newpassword}
                    error={errors.Newpassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Input
                    name="Confirmpassword"
                    type="password"
                    placeholder="Enter Password"
                    value={values.Confirmpassword}
                    error={errors.Confirmpassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Form.Group>
                <Form.Footer>
                <Button type="submit" color="primary" block={true}>
                  Reset Password
                </Button>
                <div className="link-box">
                  <span> Don't have an account?</span>
                  <a href="/app/register" color="primary" className="register-link">
                    Sign Up
                  </a>
                </div>
              </Form.Footer>
            </Card.Body>
          </Form>
          </OuterWrapper>
        )}
      />
    );
  }
}
export default ResetPassword;
