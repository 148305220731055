import * as React from "react";
import {useState,useEffect} from "react";
import {Link} from "react-router-dom";
import * as Module from './Modules.react';
import queryString from 'query-string';
import { connect } from "react-redux";
import {updateuser} from "../store/index";

const { forwardRef, useRef, useImperativeHandle } = React;

const LoginPage = (props) => {
  const [GUID, setGUID] = React.useState("");
  const [Loading, setLoading] = React.useState(true);
  
  const OnLoadCheck = () => {
    let queries = queryString.parse(props.location.search);
    Module.authenticationService.getCurrentLoginInformationsfn().then(loggeduser => {
      if(loggeduser && loggeduser.user && loggeduser.user.istrial == false && loggeduser.user.subscriptionsuccess == false) {
        return props.history.push("/Subscription");        
      }
      if(loggeduser && loggeduser.user)
        return props.history.push("/");
      
      if(queries.code && queries.username) {
        Module.ar.getFamilyUsersData(queries.code).then(result => {
          if(Object.entries(result).length != 0) {
            setLoading(false);
            setGUID(queries.code);
          } else {
            setLoading(false);
          }
        },
        error => {
          setLoading(false);
          Module.errorresult(error);
        });
      } else
      {
        setLoading(false);
      }
    });
  }
  const handleSubmit = (values, actions) => {
    localStorage.removeItem("SUBStatus");
    props.updateuser({
      _Name:'',
      _ProfileImage:'',
      _ProfileName:''
    });
    Module.authenticationService.login(values.email, values.password, GUID, true).then(
      result => {
        actions.setSubmitting(true);
        Module.ar.getCurrentSubscribeStatus().then(
          result2 => {
            localStorage.setItem('SUBStatus', (result2) ? '00b46157' : 'c286de5aa9ff');
            if (result2 == false) {
              return props.history.push("/Subscription");
            } else {
              return props.history.push("/");
            }
          },
          error => {
            SnackbarUtils.error("Something Went Wrong! Try later");
          }
        );
      },
      error => {
        Module.errorresult(error);
      }
    );
  }
  useEffect(()=>
  {
    OnLoadCheck();
  },[])
  return (
    <Module.OuterWrapper>
      {(!Loading) ? (
      <Module.Formik
        initialValues={{
          email: "",
          password: "",
          staysignin: false
        }}
        validate={values => {
          // same as above, but feel free to move this into a class method now.
          let errors = {};
          if (!values.email) {
            errors.email = "Email address Required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }
          if (!values.password) {
            errors.password = "Password Required";
          }
          return errors;
        }}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={handleSubmit}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
            <Module.Form className="card" onSubmit={handleSubmit}>
              <Module.Card.Body className="p-6">
                <Module.Card.Title RootComponent="div">Login to your Account</Module.Card.Title>
                <p>Enter your credentials to login to the application</p>
                <Module.Form.Group>
                  <Module.Form.Label>Email Address</Module.Form.Label>
                  <Module.Form.Input
                    name="email"
                    placeholder="Enter Email Address"
                    value={values.email}
                    error={errors.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Module.Form.Group>
                <Module.Form.Group>
                  <Module.Form.Label>Password</Module.Form.Label>
                  <Module.Form.Input
                    name="password"
                    type="password"
                    placeholder="Enter Your Password"
                    value={values.password}
                    error={errors.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Module.Form.Group>
                <Module.Form.Group className='w-25 staysignin'>
                  <Module.Form.Checkbox
                    name="staysignin"
                    label="Remember Me"
                    value={values.staysignin}
                    checked={values.staysignin}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Module.Form.Group>
                <Module.Form.Footer>
                <a href="#!" color="primary" className="mb25" onClick={e => props.history.push("/forgot-password") }>
                  Forgot your password?
                </a>
                <Module.Button type="submit" color="primary" block={true}>
                  Login
                </Module.Button>
                <div className="link-box">
                  <span> Don't have an account?</span>
                  <a href="#!" color="primary" onClick={e => props.history.push("/register") }>
                    Sign Up
                  </a>
                </div>
              </Module.Form.Footer>
            </Module.Card.Body>
          </Module.Form>
        )}
      /> ) : <Module.Loading/>}
      </Module.OuterWrapper>
  )
}
const mapStatetoProps=(state)=>{
  return {
      userdata:state.user.userdata
  }
}


const mapDispatchtoProps=(dispatch)=>({
  
  updateuser : (_val) => { dispatch(updateuser({type:"UPDATE_USER",val:_val}));},
  
})
export default connect(mapStatetoProps, mapDispatchtoProps,null,{ forwardRef: true })(LoginPage);
