export default function ColorPicker(latters) {
    var latter = latters.charAt(0).toUpperCase();
    var color = '#bf00ff';
    switch (latter) {
        case 'A':
            color = '#0000ff';
            break;
        case 'B':
            color = '#00bfff';
            break;
        case 'C':
            color = '#00ff00';
            break;
        case 'D':
            color = '#0080ff';
            break;
        case 'E':
            color = '#00ff80';
            break;
        case 'F':
            color = '#00ffff';
            break;
        case 'G':
            color = '#4000ff';
            break;
        case 'H':
            color = '#8000ff';
            break;
        case 'I':
            color = '#bf00ff';
            break;
        case 'J':
            color = '#bfff00';
            break;
        case 'K':
            color = '#ff0000';
            break;
        case 'L':
            color = '#ff00bf';
            break;
        case 'M':
            color = '#ff4000';
            break;
        case 'N':
            color = '#ff8000';
            break;
        case 'O':
            color = '#ffbf00';
            break;
        case 'P':
            color = '#800000';
            break;
        case 'Q':
            color = '#000080';
            break;
        case 'R':
            color = '#F08080';
            break;
        case 'S':
            color = '#800080';
            break;
        case 'T':
            color = '#E9967A';
            break;
        case 'U':
            color = '#DC143C';
            break;
        case 'V':
            color = '#FFD700';
            break;
        case 'W':
            color = '#FFFACD';
            break;
        case 'X':
            color = '#BDB76B';
            break;
        case 'Y':
            color = '#00FA9A';
            break;
        case 'Z':
            color = '#1E90FF';
            break;
        default: 
            color = '#bf00ff';
            break;
    }
    return color;
}