import * as React from "react";
import {useState,useEffect} from "react";
import {Link} from "react-router-dom";
import * as Module from './Module.react'

const { forwardRef, useRef, useImperativeHandle } = React;

const UsersSubscriptions = (props) => {

 const _columns = [
 
   {
    text: 'USER ID',
    dataField: 'userId',
    headerStyle: { width:'10%' },
     filter: Module.textFilter(),
    attrs: {
        'data-label': 'USER ID'
      },
      sort:true,
  },
  {
    text: 'EMAIL',
    dataField: 'emailAddres',
    headerStyle: { width:'15%' },
      filter: Module.textFilter(),
 attrs: {
    'data-label': 'EMAIL'
  },
  sort:true,
  },
  {
   text: 'NAME',
    dataField: 'name',
    headerStyle: { width:'10%' },
      filter: Module.textFilter(),
 attrs: {
    'data-label': 'Name'
  },
  sort:true,
  },
   {
    text: 'Start Date',
    dataField: 'current_period_start',
    headerStyle: { width:'10%' },
      filter: Module.textFilter(),
 attrs: {
    'data-label': 'Start Date'
  },
  sort:true,
  },
  {
    text: 'End Date',
    dataField: 'current_period_end',
    headerStyle: { width:'10%' },
      filter: Module.textFilter(),
 attrs: {
    'data-label': 'End Date'
  },
  sort:true,
  },
  {
    text: 'Coupon Applied',
    dataField: 'coupon',
    headerStyle: { width:'10%' },
      filter: Module.textFilter(),
 attrs: {
    'data-label': 'Coupon Applied'
  },
  sort:true,
  },
  {
    text: 'Is Recurring',
    dataField: 'collection_method',
    headerStyle: { width:'10%' },
    filter: Module.textFilter(),
    attrs: {
      'data-label': 'Is Recurring'
    },
    sort:true,
    formatter : (cell, row) => (row.collection_method == "charge_automatically") ? "Auto Renew" : "-",
    sortValue: (cell, row)=> (row.collection_method == "charge_automatically") ? "Auto Renew" : "-",
    filterValue: (cell, row)=> (row.collection_method == "charge_automatically") ? "Auto Renew" : "-",
  },
  {
  
  text: 'Action',
  attrs: {
    'data-label': 'Action'
  },
  headerStyle: { width:'10%' },
  formatter : (cell, row) => <a href="#!" onClick={()=> props.history.push("/UsersSubscriptionDetails/"+row.customerid+"/"+row.userId+"/package")} >View</a>,
  }
];
var data = [];
const [SubscriptionsList, setSubscriptionsList] = React.useState([]);

const LoadSubscriptions =()=>{
  Module.ar.listUsersSubscriptions().then(res=>{    
    console.log(res);
    setSubscriptionsList(res); 
  })   
};


useEffect(()=>
{
  LoadSubscriptions();
},[])
  return (
    <Module.SiteWrapper>
      <Module.Page.Content>
          <Module.Grid.Row className="mobile-full mb-4">
            <Module.Grid.Col width={12}>
              <Module.Card.Title>User Subscriptions</Module.Card.Title>
            </Module.Grid.Col>
          </Module.Grid.Row>
          <Module.Grid.Row cards={true}>                
          <Module.Grid.Col width={12}>
            <Module.Card classtext="Userlist">
            <Module.BootstrapTable
              bootstrap4
              keyField='id' 
              bordered={ false } 
              classes="card-table table-vcenter investment-table my-family-table" 
              data={ SubscriptionsList }
              columns={ _columns }
              noDataIndication={ <div className="text-center">Please Wait</div> }
              filter={ Module.filterFactory() }
              filterPosition="top"
              pagination={ Module.paginationFactory() } />

            </Module.Card>
            
          </Module.Grid.Col>
        </Module.Grid.Row>
      </Module.Page.Content>
    </Module.SiteWrapper>
  );
}
  
  export default UsersSubscriptions;
  
