// @flow

import * as React from "react";

const OuterWrapper = (props) => {
    return (
    <div className="page box-min">
        <div className="page-single">
            <div className="col col-login col-sm-6 bg-color">
                <div className="inner-row">
                    <div className="text-center col-sm-12">
                        <img src={"./demo/brand/mwl-logo-h-sml-w2.png"} className="h-6" alt="logo" />
                    </div>
                    <div className="text-center mb-6 col-sm-12">
                        <div className="dot-img" style={{ backgroundImage: `url("`+process.env.REACT_APP_PRE_BIND_DIRECTION+`/demo/brand/dot-img.png")`}}></div>
                        <h1>Get Peace of Mind for You & Your Loved Ones with <br></br>My Wealth Locker</h1>
                    </div>
                    <div className="pettan-img" style={{ backgroundImage: `url("`+process.env.REACT_APP_PRE_BIND_DIRECTION+`/demo/brand/Vector-Smart-Object-W.png")`}}></div>
                </div>
            </div>
            <div className={(props.outerclass) ? "col col-login col-sm-6 right-box " + props.outerclass : "col col-login col-sm-6 right-box"}>
                <div className="inner-row">
                    <div className="text-center mb-6">
                        {props.children}
                    </div> 
                </div> 
            </div>
        </div>
    </div>
    );
}

export default OuterWrapper;
