// @flow

import * as React from "react";

import Form from "../components/Form";

import type { Props as FormInputProps } from "../components/Form/FormInput.react";

type Props = {|
  ...FormInputProps,
  +label?: string,
|};

function FormTextInputPhoneNumber(props: Props): React.Node {
  const { label,dropdownonbuttonname,dropdownonbuttononchange,dropdownonbuttonononBlur,dropdownonbuttononvalue,dropdownonbuttononerror,dropdownonbuttonondisabled, ...propsForInput } = props;

  const formInputComponent = React.createElement(Form.Input, propsForInput);

  const formDropDownComponent = React.createElement(Form.Select,
  {
    error: dropdownonbuttononerror,
    name: dropdownonbuttonname,
    onBlur: dropdownonbuttonononBlur,
    onChange: dropdownonbuttononchange,
    value: dropdownonbuttononvalue || '',
    disabled: (dropdownonbuttonondisabled)
  },
  React.createElement("option", {value: ""}, "Select Country Code"),
  React.createElement("option", {value: "93"}, "Afghanistan (+93)"),
  React.createElement("option", {value: "355"}, "Albania (+355)"),
  React.createElement("option", {value: "213"}, "Algeria (+213)"),
  React.createElement("option", {value: "1-684"}, "American Samoa (+1-684)"),
  React.createElement("option", {value: "376"}, "Andorra (+376)"),
  React.createElement("option", {value: "244"}, "Angola (+244)"),
  React.createElement("option", {value: "1-264"}, "Anguilla (+1-264)"),
  React.createElement("option", {value: "672"}, "Antarctica (+672)"),
  React.createElement("option", {value: "1-268"}, "Antigua and Barbuda (+1-268)"),
  React.createElement("option", {value: "54"}, "Argentina (+54)"),
  React.createElement("option", {value: "374"}, "Armenia (+374)"),
  React.createElement("option", {value: "297"}, "Aruba (+297)"),
  React.createElement("option", {value: "61"}, "Australia (+61)"),
  React.createElement("option", {value: "43"}, "Austria (+43)"),
  React.createElement("option", {value: "994"}, "Azerbaijan (+994)"),
  React.createElement("option", {value: "1-242"}, "Bahamas (+1-242)"),
  React.createElement("option", {value: "973"}, "Bahrain (+973)"),
  React.createElement("option", {value: "880"}, "Bangladesh (+880)"),
  React.createElement("option", {value: "1-246"}, "Barbados (+1-246)"),
  React.createElement("option", {value: "375"}, "Belarus (+375)"),
  React.createElement("option", {value: "32"}, "Belgium (+32)"),
  React.createElement("option", {value: "501"}, "Belize (+501)"),
  React.createElement("option", {value: "229"}, "Benin (+229)"),
  React.createElement("option", {value: "1-441"}, "Bermuda (+1-441)"),
  React.createElement("option", {value: "975"}, "Bhutan (+975)"),
  React.createElement("option", {value: "591"}, "Bolivia (+591)"),
  React.createElement("option", {value: "387"}, "Bosnia and Herzegovina (+387)"),
  React.createElement("option", {value: "267"}, "Botswana (+267)"),
  React.createElement("option", {value: "55"}, "Brazil (+55)"),
  React.createElement("option", {value: "246"}, "British Indian Ocean Territory (+246)"),
  React.createElement("option", {value: "1-284"}, "British Virgin Islands (+1-284)"),
  React.createElement("option", {value: "673"}, "Brunei (+673)"),
  React.createElement("option", {value: "359"}, "Bulgaria (+359)"),
  React.createElement("option", {value: "226"}, "Burkina Faso (+226)"),
  React.createElement("option", {value: "257"}, "Burundi (+257)"),
  React.createElement("option", {value: "855"}, "Cambodia (+855)"),
  React.createElement("option", {value: "237"}, "Cameroon (+237)"),
  // React.createElement("option", {value: "1"}, "Canada (+1)"),
  React.createElement("option", {value: "238"}, "Cape Verde (+238)"),
  React.createElement("option", {value: "1-345"}, "Cayman Islands (+1-345)"),
  React.createElement("option", {value: "236"}, "Central African Republic (+236)"),
  React.createElement("option", {value: "235"}, "Chad (+235)"),
  React.createElement("option", {value: "56"}, "Chile (+56)"),
  React.createElement("option", {value: "86"}, "China (+86)"),
  React.createElement("option", {value: "61"}, "Christmas Island (+61)"),
  React.createElement("option", {value: "61"}, "Cocos Islands (+61)"),
  React.createElement("option", {value: "57"}, "Colombia (+57)"),
  React.createElement("option", {value: "269"}, "Comoros (+269)"),
  React.createElement("option", {value: "682"}, "Cook Islands (+682)"),
  React.createElement("option", {value: "506"}, "Costa Rica (+506)"),
  React.createElement("option", {value: "385"}, "Croatia (+385)"),
  React.createElement("option", {value: "53"}, "Cuba (+53)"),
  React.createElement("option", {value: "599"}, "Curacao (+599)"),
  React.createElement("option", {value: "357"}, "Cyprus (+357)"),
  React.createElement("option", {value: "420"}, "Czech Republic (+420)"),
  React.createElement("option", {value: "243"}, "Democratic Republic of the Congo (+243)"),
  React.createElement("option", {value: "45"}, "Denmark (+45)"),
  React.createElement("option", {value: "253"}, "Djibouti (+253)"),
  React.createElement("option", {value: "1-767"}, "Dominica (+1-767)"),
  React.createElement("option", {value: "1-809, 1-829, 1-849"}, "Dominican Republic (+1-809, 1-829, 1-849)"),
  React.createElement("option", {value: "670"}, "East Timor (+670)"),
  React.createElement("option", {value: "593"}, "Ecuador (+593)"),
  React.createElement("option", {value: "20"}, "Egypt (+20)"),
  React.createElement("option", {value: "503"}, "El Salvador (+503)"),
  React.createElement("option", {value: "240"}, "Equatorial Guinea (+240)"),
  React.createElement("option", {value: "291"}, "Eritrea (+291)"),
  React.createElement("option", {value: "372"}, "Estonia (+372)"),
  React.createElement("option", {value: "251"}, "Ethiopia (+251)"),
  React.createElement("option", {value: "500"}, "Falkland Islands (+500)"),
  React.createElement("option", {value: "298"}, "Faroe Islands (+298)"),
  React.createElement("option", {value: "679"}, "Fiji (+679)"),
  React.createElement("option", {value: "358"}, "Finland (+358)"),
  React.createElement("option", {value: "33"}, "France (+33)"),
  React.createElement("option", {value: "689"}, "French Polynesia (+689)"),
  React.createElement("option", {value: "241"}, "Gabon (+241)"),
  React.createElement("option", {value: "220"}, "Gambia (+220)"),
  React.createElement("option", {value: "995"}, "Georgia (+995)"),
  React.createElement("option", {value: "49"}, "Germany (+49)"),
  React.createElement("option", {value: "233"}, "Ghana (+233)"),
  React.createElement("option", {value: "350"}, "Gibraltar (+350)"),
  React.createElement("option", {value: "30"}, "Greece (+30)"),
  React.createElement("option", {value: "299"}, "Greenland (+299)"),
  React.createElement("option", {value: "1-473"}, "Grenada (+1-473)"),
  React.createElement("option", {value: "1-671"}, "Guam (+1-671)"),
  React.createElement("option", {value: "502"}, "Guatemala (+502)"),
  React.createElement("option", {value: "44-1481"}, "Guernsey (+44-1481)"),
  React.createElement("option", {value: "224"}, "Guinea (+224)"),
  React.createElement("option", {value: "245"}, "Guinea-Bissau (+245)"),
  React.createElement("option", {value: "592"}, "Guyana (+592)"),
  React.createElement("option", {value: "509"}, "Haiti (+509)"),
  React.createElement("option", {value: "504"}, "Honduras (+504)"),
  React.createElement("option", {value: "852"}, "Hong Kong (+852)"),
  React.createElement("option", {value: "36"}, "Hungary (+36)"),
  React.createElement("option", {value: "354"}, "Iceland (+354)"),
  React.createElement("option", {value: "91"}, "India (+91)"),
  React.createElement("option", {value: "62"}, "Indonesia (+62)"),
  React.createElement("option", {value: "98"}, "Iran (+98)"),
  React.createElement("option", {value: "964"}, "Iraq (+964)"),
  React.createElement("option", {value: "353"}, "Ireland (+353)"),
  React.createElement("option", {value: "44-1624"}, "Isle of Man (+44-1624)"),
  React.createElement("option", {value: "972"}, "Israel (+972)"),
  React.createElement("option", {value: "39"}, "Italy (+39)"),
  React.createElement("option", {value: "225"}, "Ivory Coast (+225)"),
  React.createElement("option", {value: "1-876"}, "Jamaica (+1-876)"),
  React.createElement("option", {value: "81"}, "Japan (+81)"),
  React.createElement("option", {value: "44-1534"}, "Jersey (+44-1534)"),
  React.createElement("option", {value: "962"}, "Jordan (+962)"),
  React.createElement("option", {value: "7"}, "Kazakhstan (+7)"),
  React.createElement("option", {value: "254"}, "Kenya (+254)"),
  React.createElement("option", {value: "686"}, "Kiribati (+686)"),
  React.createElement("option", {value: "383"}, "Kosovo (+383)"),
  React.createElement("option", {value: "965"}, "Kuwait (+965)"),
  React.createElement("option", {value: "996"}, "Kyrgyzstan (+996)"),
  React.createElement("option", {value: "856"}, "Laos (+856)"),
  React.createElement("option", {value: "371"}, "Latvia (+371)"),
  React.createElement("option", {value: "961"}, "Lebanon (+961)"),
  React.createElement("option", {value: "266"}, "Lesotho (+266)"),
  React.createElement("option", {value: "231"}, "Liberia (+231)"),
  React.createElement("option", {value: "218"}, "Libya (+218)"),
  React.createElement("option", {value: "423"}, "Liechtenstein (+423)"),
  React.createElement("option", {value: "370"}, "Lithuania (+370)"),
  React.createElement("option", {value: "352"}, "Luxembourg (+352)"),
  React.createElement("option", {value: "853"}, "Macau (+853)"),
  React.createElement("option", {value: "389"}, "Macedonia (+389)"),
  React.createElement("option", {value: "261"}, "Madagascar (+261)"),
  React.createElement("option", {value: "265"}, "Malawi (+265)"),
  React.createElement("option", {value: "60"}, "Malaysia (+60)"),
  React.createElement("option", {value: "960"}, "Maldives (+960)"),
  React.createElement("option", {value: "223"}, "Mali (+223)"),
  React.createElement("option", {value: "356"}, "Malta (+356)"),
  React.createElement("option", {value: "692"}, "Marshall Islands (+692)"),
  React.createElement("option", {value: "222"}, "Mauritania (+222)"),
  React.createElement("option", {value: "230"}, "Mauritius (+230)"),
  React.createElement("option", {value: "262"}, "Mayotte (+262)"),
  React.createElement("option", {value: "52"}, "Mexico (+52)"),
  React.createElement("option", {value: "691"}, "Micronesia (+691)"),
  React.createElement("option", {value: "373"}, "Moldova (+373)"),
  React.createElement("option", {value: "377"}, "Monaco (+377)"),
  React.createElement("option", {value: "976"}, "Mongolia (+976)"),
  React.createElement("option", {value: "382"}, "Montenegro (+382)"),
  React.createElement("option", {value: "1-664"}, "Montserrat (+1-664)"),
  React.createElement("option", {value: "212"}, "Morocco (+212)"),
  React.createElement("option", {value: "258"}, "Mozambique (+258)"),
  React.createElement("option", {value: "95"}, "Myanmar (+95)"),
  React.createElement("option", {value: "264"}, "Namibia (+264)"),
  React.createElement("option", {value: "674"}, "Nauru (+674)"),
  React.createElement("option", {value: "977"}, "Nepal (+977)"),
  React.createElement("option", {value: "31"}, "Netherlands (+31)"),
  React.createElement("option", {value: "599"}, "Netherlands Antilles (+599)"),
  React.createElement("option", {value: "687"}, "New Caledonia (+687)"),
  React.createElement("option", {value: "64"}, "New Zealand (+64)"),
  React.createElement("option", {value: "505"}, "Nicaragua (+505)"),
  React.createElement("option", {value: "227"}, "Niger (+227)"),
  React.createElement("option", {value: "234"}, "Nigeria (+234)"),
  React.createElement("option", {value: "683"}, "Niue (+683)"),
  React.createElement("option", {value: "850"}, "North Korea (+850)"),
  React.createElement("option", {value: "1-670"}, "Northern Mariana Islands (+1-670)"),
  React.createElement("option", {value: "47"}, "Norway (+47)"),
  React.createElement("option", {value: "968"}, "Oman (+968)"),
  React.createElement("option", {value: "92"}, "Pakistan (+92)"),
  React.createElement("option", {value: "680"}, "Palau (+680)"),
  React.createElement("option", {value: "970"}, "Palestine (+970)"),
  React.createElement("option", {value: "507"}, "Panama (+507)"),
  React.createElement("option", {value: "675"}, "Papua New Guinea (+675)"),
  React.createElement("option", {value: "595"}, "Paraguay (+595)"),
  React.createElement("option", {value: "51"}, "Peru (+51)"),
  React.createElement("option", {value: "63"}, "Philippines (+63)"),
  React.createElement("option", {value: "64"}, "Pitcairn (+64)"),
  React.createElement("option", {value: "48"}, "Poland (+48)"),
  React.createElement("option", {value: "351"}, "Portugal (+351)"),
  React.createElement("option", {value: "1-787, 1-939"}, "Puerto Rico (+1-787, 1-939)"),
  React.createElement("option", {value: "974"}, "Qatar (+974)"),
  React.createElement("option", {value: "242"}, "Republic of the Congo (+242)"),
  React.createElement("option", {value: "262"}, "Reunion (+262)"),
  React.createElement("option", {value: "40"}, "Romania (+40)"),
  React.createElement("option", {value: "7"}, "Russia (+7)"),
  React.createElement("option", {value: "250"}, "Rwanda (+250)"),
  React.createElement("option", {value: "590"}, "Saint Barthelemy (+590)"),
  React.createElement("option", {value: "290"}, "Saint Helena (+290)"),
  React.createElement("option", {value: "1-869"}, "Saint Kitts and Nevis (+1-869)"),
  React.createElement("option", {value: "1-758"}, "Saint Lucia (+1-758)"),
  React.createElement("option", {value: "590"}, "Saint Martin (+590)"),
  React.createElement("option", {value: "508"}, "Saint Pierre and Miquelon (+508)"),
  React.createElement("option", {value: "1-784"}, "Saint Vincent and the Grenadines (+1-784)"),
  React.createElement("option", {value: "685"}, "Samoa (+685)"),
  React.createElement("option", {value: "378"}, "San Marino (+378)"),
  React.createElement("option", {value: "239"}, "Sao Tome and Principe (+239)"),
  React.createElement("option", {value: "966"}, "Saudi Arabia (+966)"),
  React.createElement("option", {value: "221"}, "Senegal (+221)"),
  React.createElement("option", {value: "381"}, "Serbia (+381)"),
  React.createElement("option", {value: "248"}, "Seychelles (+248)"),
  React.createElement("option", {value: "232"}, "Sierra Leone (+232)"),
  React.createElement("option", {value: "65"}, "Singapore (+65)"),
  React.createElement("option", {value: "1-721"}, "Sint Maarten (+1-721)"),
  React.createElement("option", {value: "421"}, "Slovakia (+421)"),
  React.createElement("option", {value: "386"}, "Slovenia (+386)"),
  React.createElement("option", {value: "677"}, "Solomon Islands (+677)"),
  React.createElement("option", {value: "252"}, "Somalia (+252)"),
  React.createElement("option", {value: "27"}, "South Africa (+27)"),
  React.createElement("option", {value: "82"}, "South Korea (+82)"),
  React.createElement("option", {value: "211"}, "South Sudan (+211)"),
  React.createElement("option", {value: "34"}, "Spain (+34)"),
  React.createElement("option", {value: "94"}, "Sri Lanka (+94)"),
  React.createElement("option", {value: "249"}, "Sudan (+249)"),
  React.createElement("option", {value: "597"}, "Suriname (+597)"),
  React.createElement("option", {value: "47"}, "Svalbard and Jan Mayen (+47)"),
  React.createElement("option", {value: "268"}, "Swaziland (+268)"),
  React.createElement("option", {value: "46"}, "Sweden (+46)"),
  React.createElement("option", {value: "41"}, "Switzerland (+41)"),
  React.createElement("option", {value: "963"}, "Syria (+963)"),
  React.createElement("option", {value: "886"}, "Taiwan (+886)"),
  React.createElement("option", {value: "992"}, "Tajikistan (+992)"),
  React.createElement("option", {value: "255"}, "Tanzania (+255)"),
  React.createElement("option", {value: "66"}, "Thailand (+66)"),
  React.createElement("option", {value: "228"}, "Togo (+228)"),
  React.createElement("option", {value: "690"}, "Tokelau (+690)"),
  React.createElement("option", {value: "676"}, "Tonga (+676)"),
  React.createElement("option", {value: "1-868"}, "Trinidad and Tobago (+1-868)"),
  React.createElement("option", {value: "216"}, "Tunisia (+216)"),
  React.createElement("option", {value: "90"}, "Turkey (+90)"),
  React.createElement("option", {value: "993"}, "Turkmenistan (+993)"),
  React.createElement("option", {value: "1-649"}, "Turks and Caicos Islands (+1-649)"),
  React.createElement("option", {value: "688"}, "Tuvalu (+688)"),
  React.createElement("option", {value: "1-340"}, "U.S. Virgin Islands (+1-340)"),
  React.createElement("option", {value: "256"}, "Uganda (+256)"),
  React.createElement("option", {value: "380"}, "Ukraine (+380)"),
  React.createElement("option", {value: "971"}, "United Arab Emirates (+971)"),
  React.createElement("option", {value: "44"}, "United Kingdom (+44)"),
  React.createElement("option", {value: "1" }, "United States (+1)"),
  React.createElement("option", {value: "598"}, "Uruguay (+598)"),
  React.createElement("option", {value: "998"}, "Uzbekistan (+998)"),
  React.createElement("option", {value: "678"}, "Vanuatu (+678)"),
  React.createElement("option", {value: "379"}, "Vatican (+379)"),
  React.createElement("option", {value: "58"}, "Venezuela (+58)"),
  React.createElement("option", {value: "84"}, "Vietnam (+84)"),
  React.createElement("option", {value: "681"}, "Wallis and Futuna (+681)"),
  React.createElement("option", {value: "212"}, "Western Sahara (+212)"),
  React.createElement("option", {value: "967"}, "Yemen (+967)"),
  React.createElement("option", {value: "260"}, "Zambia (+260)"),
  React.createElement("option", {value: "263"}, "Zimbabwe (+263)")
  );
  return <Form.GroupWithDropDown label={label}>{formDropDownComponent}{formInputComponent}</Form.GroupWithDropDown>;
}

export default FormTextInputPhoneNumber;
