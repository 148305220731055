import * as React from "react";
import {useState,useEffect} from "react";
import {Link} from "react-router-dom";
import * as Module from './Modules.react';

const { forwardRef, useRef, useImperativeHandle } = React;

const Family = (props) => {
  const [FamilyData, setFamilyData] = React.useState({
    firstname:'',
    lastname:'',
    emailaddress:'',
    countrycode:'',
    phonenumber:'',
    reletionship:'',
    otherreletionship:'',
    otherreletionshiptoggle:'',
    minordisabled:false,
    trustee:'',
    defaultsharing:'1',
  });
  const [Loading, setLoading] = React.useState(true);
  const [SubscriptionCheck, setSubscriptionCheck] = React.useState({
    issubscribed: false,
    ismodalopenblock: false,
    visibleblockmodal: false,
    showsweetalert:false,
    blurbg: false,
  });
  const [Countries, setCountries] = React.useState();
  const [Buttonaction, setButtonaction] = React.useState('add');
  const [ButtonStatus, setButtonStatus] = React.useState(false);

  var familyinput = Module.MyFamilyInput;

  const breadcumroute = {
    root: {title: 'Home', path: '/'},
    child: [
      {title: 'My Family', path: '/MyFamily'},
      {title: 'Add/Edit Family', path: ''},
    ]
  }

  const LoadFamilyData = () =>{
    Module.ar.getCountryData().then(res=>{
      setCountries(res);
    });
    Module.authenticationService.getCurrentLoginInformationsfn().then(res=>{
      if(res.user.subscriptionsuccess) {
        setSubscriptionCheck({
          ...SubscriptionCheck,
          issubscribed: true,
          showsweetalert: false,
          blurbg: false,
          visibleblockmodal: false,
        });
      } else {
        setSubscriptionCheck({
          ...SubscriptionCheck,
          showsweetalert: true,
          blurbg: true,
        });
      }
    }).then(res2 => {
      if(props.match.params.id) {
        Module.ar.getFamilyMemberDetail(props.match.params.id).then(result => {
            var numberformat = "";
            if (result.phoneNumber.split("-").length){
              for(let i=0;i<result.phoneNumber.split("-").length-1;i++ ){
                numberformat += result.phoneNumber.split("-")[i]
              }
            }
            var number = result.phoneNumber.split(/-(.+)/)[result.phoneNumber.split("-").length - 1];
            var reletionstatus = (result.reletionship === "Son" ||
            result.reletionship === "Father" ||
            result.reletionship === "Daughter" ||
            result.reletionship === "Mother" ||
            result.reletionship === "Spouse" ||
            result.reletionship === "Sibling");
  
            setFamilyData({
              ...FamilyData,
              firstname:result.firstname,
              lastname:result.lastname,
              emailaddress:result.email,
              countrycode:numberformat,
              phonenumber:number,
              reletionship: (reletionstatus) ? result.reletionship : 'Other',
              otherreletionship: (reletionstatus) ? null : result.reletionship,
              otherreletionshiptoggle: (reletionstatus) ? ' d-none' : ' mt-3',
              minordisabled:result.minorDisabled,
              trustee:result.trustee,
              defaultsharing : result.defaultSharing.toString()
            });
            setLoading(false);
            setButtonaction('update');
            setButtonStatus(true);
            return result
          },
          error => {
            return error;
          });
      } else {
        setLoading(false);
        setButtonaction('add');
        setButtonStatus(true);
      }
    });
  };
  const afterpaymentsuccess = () => {
    LoadFamilyData();
  }
  const updateprofile = (values, actions) => {
    // //this.checkemailaddresscheck('emailaddress',this.state.emailaddress);
    if(SubscriptionCheck.issubscribed) {      
        setButtonStatus(false);
        var id = 0;
        if(props.match.params.id) {
          id = props.match.params.id;
        }
        familyinput = {
          id:id,
          userid:0,
          Firstname:values.firstname,
          Lastname:values.lastname,
          Email:values.emailaddress,
          PhoneNumber: values.countrycode + '-' + values.phonenumber,
          Reletionship: (values.reletionship == "Other") ? values.otherreletionship : values.reletionship,
          MinorDisabled:values.minordisabled,
          Trustee: (values.minordisabled == true) ? values.trustee : null,
          DefaultSharing:values.defaultsharing,
        };
        Module.ar.addFamilyData(familyinput).then(result => {
            if(result == "success") {
              setButtonStatus(true);
              Module.SnackbarUtils.success((Buttonaction == 'update') ? "Updated Successfully in Family." : "Added Successfully in Family.");
              return props.history.push("/MyFamily");
            } else {
              Module.SnackbarUtils.error(result);
              setButtonStatus(true);
            }
          },
          error => {
            Module.SnackbarUtils.error("Failed to add in Family.");
          }
        );
    }
    else {
      Module.SnackbarUtils.error("You Don't have access this Functionality");
    }
  };
  const onConfirmpopup = () => {
    setSubscriptionCheck({
      ...SubscriptionCheck,
      showsweetalert: false,
      ismodalopenblock: true,
      visibleblockmodal: true,
    });
  }
  const onCancelpopup = () => {
    props.history.push("/MyFamily")
  }
  const RedirectSuccessPayment = () => {
    afterpaymentsuccess();
  }
  const RedirectPaymentFailed = () => {
    props.history.push("/MyFamily");
  }
  const closeModal = () => {
    props.history.push("/MyFamily");
  }
  
  useEffect(()=>
  {
    LoadFamilyData();
  },[])
  return (
    <>
      {(!SubscriptionCheck.showsweetalert) ? (
        null
      ) : <Module.SweetAlert 
      warning 
      title="Confirm My Wealth Locker subscription"
      showCancel={true}
      onConfirm={(event) => onConfirmpopup(event)}
      onCancel={(event) => onCancelpopup(event)}
      confirmBtnText="Yes"
      cancelBtnText="Not Now"
      closeOnClickOutside={false}
    >
      Are you sure want to subscribe to My wealth Locker?
    </Module.SweetAlert>}
    <div className={(SubscriptionCheck.blurbg) ? "blurmypage" : null}>
    <Module.SiteWrapper>
        {(Loading) ? (
          <Module.Loading />
        ) : (
          <Module.Container className="add-family">
            <Module.Formik
              initialValues={{
                firstname: FamilyData.firstname,
                lastname: FamilyData.lastname,
                emailaddress: FamilyData.emailaddress,
                countrycode: FamilyData.countrycode,
                phonenumber: FamilyData.phonenumber,
                reletionship: FamilyData.reletionship,
                otherreletionship:FamilyData.otherreletionship,
                minordisabled: FamilyData.minordisabled,
                trustee: FamilyData.trustee,
                defaultsharing: FamilyData.defaultsharing,
              }}
              validate={values => {
                let errors = {};
                if (!values.emailaddress) {
                  errors.emailaddress = "Email address Required";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.emailaddress)
                ) {
                  errors.emailaddress = "Invalid email address";
                }
                if (!values.firstname) {
                  errors.firstname = "First name Required";
                }
                else if (values.firstname.length > 26) {
                  errors.firstname = 'Must be 26 characters or less';
                }
                if (!values.lastname) {
                  errors.lastname = "Last name Required";
                }
                else if (values.lastname.length > 26) {
                  errors.lastname = 'Must be 26 characters or less';
                }      
                if (!values.countrycode) {
                  errors.countrycode = "Country Code Required";
                }
                if (!values.phonenumber) {
                  errors.phonenumber = "PhoneNumber Required";
                }
                else if (!/^([0-9])*$/i.test(values.phonenumber)) {
                  errors.phonenumber = "Invalid Phone Number";
                }
                else if (!/^.{6,10}$/i.test(values.phonenumber)) {
                  errors.phonenumber = "Invalid Phone Number";
                }   
                if (!values.reletionship) {
                  errors.reletionship = "Reletionship Required";
                }
                if(values.reletionship == "Other") {
                    if (!values.otherreletionship) {
                      errors.otherreletionship = "Reletionship Required";
                    }
                } else {
                }
                if(values.minordisabled == true) {
                  if (!values.trustee) {
                    errors.trustee = "Truste Required";
                  }
                }
                return errors;
              }}
              validateOnChange={false}
              handleChange={values => {
                console.log(values);
              }}
              onSubmit={updateprofile}
              render={({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <Module.Form onSubmit={handleSubmit}>
                  <Module.Card.Body className="card">
                  <Module.Grid.Row>
                  <Module.Grid.Col sm={12} md={12}>
                      <Module.Card.Title>{(Buttonaction == 'add') ? 'Add' : 'Update'} Family</Module.Card.Title>
                  </Module.Grid.Col>
                    </Module.Grid.Row>
                    <Module.Grid.Row>
                      <Module.Grid.Col width={12}>
                        <Module.BreadCum routes={breadcumroute} />
                      </Module.Grid.Col>
                    </Module.Grid.Row>
                    <Module.Grid.Row>
                      <Module.Grid.Col sm={4} md={4}>
                        <Module.Form.Group>
                          <Module.Form.Label>First Name <span class="required">*</span></Module.Form.Label>
                          <Module.Form.Input
                            type="text"
                            placeholder="First Name"
                            name="firstname"
                            value={values.firstname}
                            error={errors.firstname}
                            onChange={handleChange}
                          />
                        </Module.Form.Group>
                      </Module.Grid.Col>
                      <Module.Grid.Col sm={4} md={4}>
                        <Module.Form.Group>
                          <Module.Form.Label>Last Name <span class="required">*</span></Module.Form.Label>
                          <Module.Form.Input
                            type="text"
                            placeholder="Last Name"
                            name="lastname"
                            value={values.lastname}
                            error={errors.lastname}
                            onChange={handleChange}
                          />
                        </Module.Form.Group>
                      </Module.Grid.Col>
                      <Module.Grid.Col sm={4} md={4}>
                        <Module.Form.Group>
                          <Module.Form.Checkbox
                            name="minordisabled"
                            label="Minor / Disabled"
                            checked={values.minordisabled}
                            value={values.minordisabled || false}
                            className="font-20 mt-6 ml-5"
                            onChange={handleChange}
                          />
                        </Module.Form.Group>
                      </Module.Grid.Col>
                      <Module.Grid.Col sm={4} md={4}>
                        <Module.Form.Group>
                          <Module.Form.Label>{(values.minordisabled === false) ? 'Email address' : 'Trustee Email Address'} <span class="required">*</span></Module.Form.Label>
                          <Module.Form.Input
                            type="email"
                            placeholder="Email"
                            name="emailaddress"
                            value={values.emailaddress}
                            error={errors.emailaddress}
                            onChange={handleChange}
                          />
                        </Module.Form.Group>
                      </Module.Grid.Col>
                      <Module.Grid.Col sm={7} md={7} className="add-full-wd-80">
                        <Module.Form.Group>
                          <Module.Form.Label>{(values.minordisabled === false) ? 'Phone Number' : 'Trustee Phone Number'} <span class="required">*</span></Module.Form.Label>
                          <Module.Grid.Row className="add-mb-wd-50">
                            <Module.Grid.Col sm={4} md={4}>
                              <Module.Form.Group className="mb-0">
                              <Module.Form.Select
                                name="countrycode"
                                value={values.countrycode}
                                error={errors.countrycode}
                                onChange={handleChange}
                              >
                                <option value="">Select Country Code</option>
                                {Countries && Countries.map((option) => (
                                        <option value={option.code}>{option.countryName + " (" + option.code.replace("~","") + ")"}</option>
                                ))}
                              </Module.Form.Select>
                              </Module.Form.Group>
                            </Module.Grid.Col>
                            <Module.Grid.Col sm={8} md={8}>
                              <Module.Form.Group className="mb-0">
                              <Module.Form.Input
                                  type="text"
                                  placeholder="Phone Number"
                                  name="phonenumber"
                                  value={values.phonenumber}
                                  error={errors.phonenumber}
                                  onChange={handleChange}
                                />
                              </Module.Form.Group>
                            </Module.Grid.Col>
                          </Module.Grid.Row>
                        </Module.Form.Group>
                      </Module.Grid.Col>
                      <Module.Grid.Col sm={4} md={4} className="add-full-wd-40">
                        <Module.Form.Group>
                          <Module.Form.Label>Relationship <span class="required">*</span></Module.Form.Label>
                          <Module.Form.Select
                            name="reletionship"
                            value={values.reletionship}
                            error={errors.reletionship}
                            onChange={handleChange}
                          >
                            <option value="">Select Relationship</option>
                            <option value="Father">Father</option>
                            <option value="Mother">Mother</option>
                            <option value="Spouse">Spouse</option>
                            <option value="Son">Son</option>
                            <option value="Daughter">Daughter</option>
                            <option value="Sibling">Sibling</option>
                            <option value="Other">Other</option>
                          </Module.Form.Select>
                          <Module.Form.Input
                            type="text"
                            placeholder="Relationship"
                            name="otherreletionship"
                            className={(values.reletionship == "Other") ? 'mt-3' : 'd-none' + ' w-50'}
                            value={values.otherreletionship}
                            error={errors.otherreletionship}
                            onChange={handleChange}
                          />
                        </Module.Form.Group>
                      </Module.Grid.Col>
                      
                      <Module.Grid.Col sm={4} md={4} className={(!values.minordisabled === false) ? '' : 'd-none'}>
                        <Module.Form.Group>
                          <Module.Form.Label>Trustee Name</Module.Form.Label>
                          <Module.Form.Input
                            type="text"
                            placeholder="Trustee Name"
                            name="trustee"
                            disabled={!values.minordisabled}
                            value={values.trustee}
                            error={errors.trustee}
                            onChange={handleChange}
                          />
                        </Module.Form.Group>
                      </Module.Grid.Col>
                    </Module.Grid.Row>
                  </Module.Card.Body>
                  <Module.Card.Footer className="text-right mt-25">
                    <Module.Button
                      type="button"
                      color="primary"
                      className="mr-4 btn-secondary"
                      onClick={e => props.history.push("/MyFamily") }>
                        Back
                    </Module.Button>
                    <Module.Button
                      type="submit"
                      color="primary"
                      disabled={!ButtonStatus}
                    >
                      {(Buttonaction == 'add') ? 'Add' : 'Update'}
                    </Module.Button>
                  </Module.Card.Footer>
                </Module.Form>
              )}
            />
            
          </Module.Container>
        )}
        ;
    </Module.SiteWrapper>
    </div>
    <section className="modal-class">
        <Module.Modal show={SubscriptionCheck.visibleblockmodal} aria-labelledby={"ModalHeader"} className={"ModalCustomStyle"} onHide={closeModal}>
          <Module.Modal.Header closeButton>
              <Module.Modal.Title id='ModalHeader'>Subscription</Module.Modal.Title>
          </Module.Modal.Header>
          <Module.Modal.Body>
              {(SubscriptionCheck.ismodalopenblock == true ? 
                <Module.StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
                  <Module.Elements>
                  <Module.InjectedCheckoutForm PaymentSuccess={(event) => RedirectSuccessPayment(event)} Paymentfailed={(event) => RedirectPaymentFailed(event)} />
                  </Module.Elements>
                </Module.StripeProvider>  
              : null)}
          </Module.Modal.Body>
        </Module.Modal>
    </section>
    </>
  )
}
  
export default Family;
  
