import React from "react";
import {useState,useEffect} from "react";
import { Formik } from "formik";
import { Grid,Button,Form,Table } from "../../tabler-react/index";
import {
  Client,
  SubscriptionOutput,
  SubscriptionInput,
  Couponasyncinput,
} from "../apireq";

import { injectStripe } from "react-stripe-elements";

import SnackbarUtils from '../SnackbarUtils';
import { withRouter } from "react-router";
import { Elements,CardNumberElement,CardExpiryElement,CardCVCElement } from "react-stripe-elements";
import * as Module from '../../pages/Modules.react';

const CheckoutForm = (props) => {
  var subinput = SubscriptionInput;
  const [Loading, setLoading] = React.useState(true);
  const [data, setdata] = React.useState(SubscriptionOutput);
  const [dataall, setdataall] = React.useState(SubscriptionOutput);
  const [substatusresult, setsubstatusresult] = React.useState(null);
  const [buttonstatus, setbuttonstatus] = React.useState(true);
  const [couponloaading, setcouponloaading] = React.useState("");
  const [carddetailsvalidate, setcarddetailsvalidate] = React.useState({
    carderror: null,
    experror: null,
    cvcerror: null,
  });
  const LoadData = () => {
    
  }
  const paynextstep = (values, actions) => {
    setbuttonstatus(false);
    const cardElement = props.elements.getElement("cardNumber");
    props.stripe
      .createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: { name: values.nameoncard },
      })
      .then(({ paymentMethod }) => {
        if(!paymentMethod) {
          setbuttonstatus(true);
        }
        subinput = {
          paymentMethodId: paymentMethod.id,
        };
        Module.ar.updateCustomerCard(subinput).then(
          result => {
            SnackbarUtils.success("Card Updated Successfull.");
            this.props.CardUpdateSuccess();
          },
          error => {
            SnackbarUtils.error("Failed to Update Card");
            this.props.CardUpdatefailed();
          }
        );
      });
  }
  const canclesubscription = () => {
    this.props.CardUpdatefailed();
  }
  const elementFontSize = window.innerWidth < 450 ? '14px' : '18px';
  const createOptions = (fontSize, padding) => {
    return {
      style: {
        base: {
          fontSize,
          color: '#424770',
          letterSpacing: '0.025em',
          fontFamily: 'Source Code Pro, monospace',
          '::placeholder': {
            color: '#aab7c4',
          },
          display:'block',
          padding,
        },
        invalid: {
          color: '#9e2146',
        },
      },
    };
  };
  const handleChangecustom = (e) => {
    if(e.elementType == "cardNumber") {
      if(e.error != null && e.error.message != null && e.error.message != "") {
        setcarddetailsvalidate({
          ...carddetailsvalidate,
          carderror: e.error.message
        });
      } else {
        setcarddetailsvalidate({
          ...carddetailsvalidate,
          carderror: null
        });
      }
    }
    else if(e.elementType == "cardExpiry") {
      if(e.error != null && e.error.message != null && e.error.message != "") {
        setcarddetailsvalidate({
          ...carddetailsvalidate,
          experror: e.error.message
        });
      } else {
        setcarddetailsvalidate({
          ...carddetailsvalidate,
          experror: null
        });
      }
    }
    else if(e.elementType == "cardCvc") {
      if(e.error != null && e.error.message != null && e.error.message != "") {
        setcarddetailsvalidate({
          ...carddetailsvalidate,
          cvcerror: e.error.message
        });
      } else {
        setcarddetailsvalidate({
          ...carddetailsvalidate,
          cvcerror: null
        });
      }
    }
    else {
      setcarddetailsvalidate({
        ...carddetailsvalidate,
        carderror: null,
        experror: null,
        cvcerror: null
      });
    }
  }
  useEffect(()=>
  {
    setLoading(false);
  },[])
  return (
    <Formik
      initialValues={{
        nameoncard: "",
      }}
      validate={values => {
        let errors = {};
        if (!values.nameoncard) {
          errors.nameoncard = "Name on Card Required";
        }
        return errors;
      }}
      onSubmit={paynextstep}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        handleFocus,
        handleReady,
        isSubmitting,
      }) => (
        <Form className="card" onSubmit={handleSubmit}>
            {Loading ? (
              <div className="progress">
                <div className="progress-bar progress-bar-indeterminate bg-green" />
              </div>
            ) : (
              <div>
                <Form.Group>
                  <Form.Label>Name On Card</Form.Label>
                  <Form.Input
                    name="nameoncard"
                    placeholder="Name On Card"
                    value={values.nameoncard}
                    error={errors.nameoncard}
                    onChange={handleChange}
                    onBlur={handleChange}
                  />
                </Form.Group>
                <Form.Group className="Checkout text-left innerscheckout">
                    <Form.Label>Card number</Form.Label>
                    <CardNumberElement
                      className="form-control"
                      //onChange={handleChange}
                      onChange={ e => handleChangecustom(e) }
                      onFocus={handleFocus}
                      onReady={handleReady}
                      {...createOptions(elementFontSize)}
                    />
                    <div className={((carddetailsvalidate.carderror != null && carddetailsvalidate.carderror != '') ? 'invalid-feedback d-block' : 'd-none')}>{carddetailsvalidate.carderror}</div>
                  </Form.Group>
                  <Grid.Row>
                    <Grid.Col sm={6} md={6}>
                      <Form.Group className="Checkout text-left">
                        <Form.Label>Expiration date</Form.Label>
                        <CardExpiryElement
                          className="form-control"
                          onBlur={handleBlur}
                          onChange={ e => handleChangecustom(e) }
                          onFocus={handleFocus}
                          onReady={handleReady}
                          {...createOptions(elementFontSize)}
                        />
                        <div className={((carddetailsvalidate.experror != null && carddetailsvalidate.experror != '') ? 'invalid-feedback d-block' : 'd-none')}>{carddetailsvalidate.experror}</div>
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col sm={6} md={6}>
                      <Form.Group className="Checkout text-left">
                          <Form.Label>CVC</Form.Label>
                          <CardCVCElement
                            className="form-control"
                            onBlur={handleBlur}
                            onChange={ e => handleChangecustom(e) }
                            onFocus={handleFocus}
                            onReady={handleReady}
                            {...createOptions(elementFontSize)}
                          />
                          <div className={((carddetailsvalidate.cvcerror != null && carddetailsvalidate.cvcerror != '') ? 'invalid-feedback d-block' : 'd-none')}>{carddetailsvalidate.cvcerror}</div>
                      </Form.Group>
                    </Grid.Col>
                  </Grid.Row>
                <Form.Footer>
                  <Button type="submit" className="btn-block d-inline" color="primary" disabled={!buttonstatus}>
                    {props.CreditCardButtonText}
                  </Button>
                  <div className="link-box d-inline ml-5">
                    <a className="cancel-link" disabled={!buttonstatus} onClick={canclesubscription}>Cancel</a>
                  </div>
                </Form.Footer>
              </div>
            )}
        </Form>
      )}
    />
  );
}

export default withRouter(injectStripe(CheckoutForm));
