import fetchIntercept from "fetch-intercept";
import Cookies from "js-cookie";
import { authenticationService } from '../_services';
import SnackbarUtils from '../_services/SnackbarUtils';

const unregister = fetchIntercept.register({
  request: function(url, config) {
    // Modify the url or config here
    if (Cookies.get("Abp.AuthToken") != null) {
      config.headers.Authorization = "Bearer " + Cookies.get("Abp.AuthToken");
    }
    if (Cookies.get("XSRF-TOKEN") != null) {
      config.headers["X-XSRF-TOKEN"] = Cookies.get("XSRF-TOKEN");
    }
    config.headers["Access-Control-Allow-Origin"] = "http://localhost:3000";
    //config.headers.Cookie = "";
    return [url, config];
  },

  requestError: function(error) {
    // Called when an error occured during another 'request' interceptor call
    return Promise.reject(error);
  },

  response: function(response) {
    if(response != null) {
      if(response.status == 401) {
        SnackbarUtils.error("Session Expired.");
        authenticationService.logout();
      }
    }
    return response;
  },

  responseError: function(error) {
    // Handle an fetch error
    if(error.toString().toLowerCase().indexOf('failed to fetch') !== -1) {
      // notifier({
      //   type: "error",
      //   message: (
      //     <span key="1">
      //       <span key="2">No Connection Try later</span>
      //     </span>
      //   ),
      //   autoDismissTimeout: 5000,
      // });
    }
    return Promise.reject(error);
  },
});
