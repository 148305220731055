import * as React from "react";

import Form from "../components/Form";

interface Props {
  label?: string;
}

function Dropdown(props: Props) {
  const { label, ...propsForInput } = props;

  const formInputComponent = React.createElement(
    Form.Select,
    propsForInput,
    React.createElement("option", { value: "" }, "Select Country"),
    React.createElement("option", { value: "us" }, "USA"),
    React.createElement("option", { value: "ca" }, "Canada"),
    React.createElement("option", { value: "in" }, "India")
  );

  return <Form.Group label={label}>{formInputComponent}</Form.Group>;
}

export default Dropdown;
