import * as React from 'react'
import { authenticationService } from './authentication.service';
import { Redirect, Route } from 'react-router-dom'
import {CheckAuth} from './abp';
import  Role  from "../_services/Roles";
export const PrivateRoute = ({ component: Component,role, ...rest }) => {

  // Add your own authentication on the below line.
  //const isLoggedIn = AuthService.isLoggedIn()
  if (role && role.indexOf(authenticationService.checkRole) > -1)
  {
    
  }
  else
  {
  if(role && role.indexOf(Role.Admin))
  {
    return  <Redirect to={{ pathname: '/UsersManagement'}} />
  }
  else if(role && role.indexOf(Role.User))
  {
    return  <Redirect to={{ pathname: '/'}} />
  }
  }
  const currentUser = CheckAuth();
  const IsSubscribed = localStorage.getItem('SUBStatus') == 'c286de5aa9ff' ? false : true;
  console.log(IsSubscribed);
  console.log(currentUser);

  return (
    <Route
      {...rest}
      render={props =>
        currentUser ? (
          (IsSubscribed ? (
            <Component {...props} />
	        ) : (
            (props.location.pathname == "/Subscription" ? (
              <Component {...props} />
            ) : (
              <Redirect to={{ pathname: '/Subscription', state: { from: props.location } }} />
            ))
          ))
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
      }
    />
  )
}
