import * as React from "react";
import {useState,useEffect} from "react";
import {Link} from "react-router-dom";
import * as Module from './Modules.react';

const { forwardRef, useRef, useImperativeHandle } = React;

const MySubscription = (props) => {
  const [Loading, setLoading] = React.useState(true);
  const [DataLoading, setDataLoading] = React.useState(true);
  const [Buttonaction, setButtonaction] = React.useState('activate');
  const [ButtonStatus, setButtonStatus] = React.useState(true);
  const [Modaldata, setModaldata] = React.useState({modal: '', visiblestatus: false});
  const [sweetalert, setsweetalert] = React.useState();

  const [subscriptiondata, setsubscriptiondata] = React.useState([]);
  const [stripedata, setstripedata] = React.useState([]);

  const [updatecreditcard,setupdatecreditcard] = React.useState(false);

  const LoadSubscription = () => {
    setDataLoading(true);
    setLoading(true);
    Module.ar.getCurrentUserSubscription().then(data=> {
      if (data.account_status == 'ACTIVE') {
        setButtonaction('cancle');
      }
      else {
        setButtonaction('activate');
      }
      setsubscriptiondata(data);
    });
    Module.ar.retriveCustomerSubscriptionData().then(result => {
      setstripedata(result);  
      setDataLoading(false);
      setLoading(false);
    });
  }
  const subscribefirst = () => {
    if(stripedata.cust_paymentmethod) {
      setModaldata({modal: 'isupdatecreditcardmodalopen', visiblestatus: true});
    } else {
      setModaldata({modal: 'isupdatemodalopen', visiblestatus: true});
    }
  }
  const MySubscriptionAction = (e) => {
    e.preventDefault();
    if (Buttonaction == "cancle") {
      setsweetalert(
        <Module.SweetAlert 
          warning 
          title="Are you sure you want to cancel this subscription?"
          showCancel={true}
          onConfirm={(event) => {
            setButtonStatus(true);
            setsweetalert();
            Module.ar.cancelCustomerSubscription().then(res => {
              setButtonaction('activate');
              Module.ar.getCurrentUserSubscription().then(result=> {
                if (result.account_status == 'ACTIVE')
                  setButtonaction('cancel');
                else
                  setButtonaction('activate');
                setsubscriptiondata(result);
                LoadSubscription();
              });
            });
          }}
          onCancel={(event) => setsweetalert()}
          confirmBtnText="Yes cancel it"
          cancelBtnText="No"
          closeOnClickOutside={false}
        >
          You'll no longer be able to access important features after the subscription is cancelled.
        </Module.SweetAlert>
      );
    }
    else {
      setsweetalert(
        <Module.SweetAlert 
          warning 
          title="Are you sure want to renew your subscription"
          showCancel={true}
          onConfirm={(event) => {
            setButtonStatus(true);
            setsweetalert();
            const subinput = {
              subscription_Plan: '',
              coupon: '',
              paymentMethodId: '',
            };
            Module.ar.reCreateCustomerSubscription(subinput).then(result => {
              setButtonaction('activate');
              Module.ar.getCurrentUserSubscription().then(getresult=> {
                if (result.account_status == 'ACTIVE')
                  setButtonaction('cancel');
                else
                  setButtonaction('activate');
                setsubscriptiondata(getresult);
                LoadSubscription();
              });
            });
          }}
          onCancel={(event) => setsweetalert()}
          confirmBtnText="Yes I Want"
          cancelBtnText="No"
          closeOnClickOutside={false}
        >
        </Module.SweetAlert>
      );
    }
  }
  const afterpaymentsuccess = () => {
    //Module.SnackbarUtils.success("Subscription Successfull.");
    LoadSubscription();
    setModaldata({modal: '', visiblestatus: false});
  }
  const RedirectSuccessPayment = () => {
    afterpaymentsuccess();
  }
  const RedirectPaymentFailed = () => {
    LoadSubscription();
    setModaldata({modal: '', visiblestatus: false});
  }
  const RedirectSuccessCardUpdate = () => {
    //Module.SnackbarUtils.success("Card Updated Successfull.");
    LoadSubscription();
    setupdatecreditcard(false);
  }
  const RedirectCardUpdateFailed = () => {
    setupdatecreditcard(false);
  }
  const creditcardupdatetoggle = () => {
    var cardstatus = updatecreditcard;
    if(cardstatus)
      cardstatus = false;
    else
      cardstatus = true;
    setupdatecreditcard(cardstatus);
  }
  const downloadinvoice = (id,name) => {
    let url = process.env.REACT_APP_REMOTE_SERVICE_BASE_URL + '/api/services/app/Subscription/DownloadInvoice?InvoiceNumber='+ id;
    fetch(url,
        { 
          method: "GET",
          headers: { "Content-Type": "application/json",
        }
    }).then(response => response.blob()).then(response => 
      {
        var url = window.URL.createObjectURL(response);
        var a = document.createElement('a');
        a.href = url;
        a.download = name + "_" + id + ".pdf";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();    
        a.remove();
      });
  }
  let closeModal = () => {
    setModaldata({modal: '', visiblestatus: false});
  }
  useEffect(()=>
  {
    LoadSubscription();
  },[])
  return (
    <Module.SiteWrapper>
        <div className="my-3 my-md-5">
            <Module.Container className="mysubscription-pages">
              <Module.Form className="card" onSubmit={MySubscriptionAction}>
                <Module.Grid.Row>
                  <Module.Grid.Col lg={12} className="mysubscription">
                    <div className="white-box subscription-paln">
                      <Module.Card.Title className="border-bottom">My Subscription Plan</Module.Card.Title>
                      {(Loading) ? (
                        <Module.Loading />
                      ) : (
                      <div>
                      <div className="subscription-top-text mt-5 pt-5">
                          <div className="float-left">
                              <h3 class="h3 mt-0 mb-2">{(stripedata.products && subscriptiondata && subscriptiondata.account_status == 'ACTIVE') ? stripedata.products.find(item => item.id === subscriptiondata.product_id).name : stripedata.products[0].name}</h3>
                              <span className="h5">{(stripedata.products && subscriptiondata && subscriptiondata.account_status == 'ACTIVE') ? stripedata.products.find(item => item.id === subscriptiondata.product_id).description : stripedata.products[0].description}</span>
                          </div>
                          <div className="float-right">
                            {((subscriptiondata.itemcurrency != null) ? <div className="text-right h3"><b>{subscriptiondata.itemcurrency + ' ' + ((subscriptiondata.itemunit_amount != 0) ? subscriptiondata.itemunit_amount + '/' + subscriptiondata.itemrecurring : null)}</b></div>: null)}
                            {(subscriptiondata.couponid) ? <div className="text-right h5">Discount: <b>{subscriptiondata.couponid + ' - ' + ((subscriptiondata.couponamount_off != null) ? ((subscriptiondata.itemcurrency) ? subscriptiondata.itemcurrency : '-') + ' ' +  subscriptiondata.couponamount_off + ' off' : subscriptiondata.couponpercentage_off + '% off')}</b></div> : null}
                            {((subscriptiondata.itemcurrency != null && subscriptiondata.itemunit_discountamount != null) ? <div className="text-right h3">Amount: <b>{subscriptiondata.itemcurrency + ' ' + ((subscriptiondata.itemunit_discountamount != 0) ? subscriptiondata.itemunit_discountamount + '/' + subscriptiondata.itemrecurring : ((subscriptiondata.itemunit_amount != 0) ? subscriptiondata.itemunit_amount + '/' + subscriptiondata.itemrecurring : null))}</b></div>: null)}
                          </div>
                        </div>
                        <div className="subscription-top-text mt-5 pt-5">
                          <div className="left-box">
                          <span className="status h3">Status: <b className={status}>{(subscriptiondata.account_status) ? subscriptiondata.account_status: 'Trial'}</b></span>
                          </div>
                          <div className="right-box">
                            {(subscriptiondata.subscriptionstated && subscriptiondata.account_status == 'ACTIVE') ? <span className="price-bold h3">Created: <b>{subscriptiondata.subscriptionstated}</b></span> : null}
                          </div>
                        </div>
                        <div className="subscription-top-text mt-5 pt-5">
                          <div className="subscription-cn-text left-box">
                            {(subscriptiondata.currentsubscriptionstated && subscriptiondata.currentsubscriptionend && subscriptiondata.account_status == 'ACTIVE') ? <span className="h3">Current Period: {subscriptiondata.currentsubscriptionstated + ' to ' + subscriptiondata.currentsubscriptionend}</span> : null}
                          </div>
                          <div className="btn-box right-box">
                            <button
                              type="submit"
                              color="primary"
                              className={(subscriptiondata.account_status == "Trial") ? "d-none" : (Buttonaction == "activate") ? "btn btn-primary" : "btn btn-primary bg-transparent text-dark"}
                              disabled={!ButtonStatus}
                            >
                              {(Buttonaction == 'activate') ? "Renew My Subscription" : "Cancel My Subscription"}
                            </button>
                            <Module.Button
                              type="button"
                              color="primary"
                              className={(subscriptiondata.account_status == "Trial") ? null : "d-none"}
                              disabled={!ButtonStatus}
                              onClick={(event) => subscribefirst(event)}
                            >
                              Subscribe
                            </Module.Button>
                          </div>
                        </div>
                        </div>
                        )}
                      </div>
                  </Module.Grid.Col>                    
                </Module.Grid.Row>
              </Module.Form>
              <Module.Grid.Row>
                <Module.Grid.Col lg={12}>
                  <div className="white-box payment-methoad">
                    <h3 class="card-title">Payment Method</h3>
                    {DataLoading || !stripedata ? 
                      <div className="progress">
                        <div className="progress-bar progress-bar-indeterminate bg-green" />
                      </div>
                     : 
                     <div>
                        <Module.Grid.Row>
                          <Module.Grid.Col lg={5}>
                            <div className="payment-top-text mt-2">
                              <h6 class="h6 mt-0 mb-2">Credit Card</h6>
                            </div>
                          </Module.Grid.Col>
                          <Module.Grid.Col lg={4}>
                            <div className="payment-cn-text">
                              <img src={(stripedata.cust_paymentmethod) ? Module.rendercardbrand(stripedata.cust_paymentmethod.brand) : ''} className={(stripedata.cust_paymentmethod) ? Module.rendercardbrand(stripedata.cust_paymentmethod.brand) != '' ? 'paymenttypes' : 'd-none' : ''} />
                              {(stripedata.cust_paymentmethod) ? <input type="text" className="paymentcard" value={stripedata.cust_paymentmethod.cardnumber} /> : <span>You don't have any card to add<br/><b>Click below Add payment Card</b></span>}
                              <span className="paymentcardname">{(stripedata.cust_paymentmethod) ? stripedata.cust_paymentmethod.nameocard : null}</span>
                            </div>
                          </Module.Grid.Col>
                          <Module.Grid.Col lg={2}>
                              {(stripedata.cust_paymentmethod) ? <div className="payment-exp">Exp: <b>{stripedata.cust_paymentmethod.exp_month + '/' + stripedata.cust_paymentmethod.exp_year}</b></div> : null }
                          </Module.Grid.Col>
                        </Module.Grid.Row>
                        <div className="btn-box mt-5 float-right mb-5">
                          <a href="#!" onClick={(event) => creditcardupdatetoggle(event)}>{(stripedata.cust_paymentmethod) ? 'Update Payment Card' : 'Add Payment Card'}</a>
                        </div>
                      </div>
                    }
                    {updatecreditcard ?
                      <div>
                      <Module.StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
                        <Module.Elements>
                          <Module.CardUpdate CardUpdateSuccess={(event) => RedirectSuccessCardUpdate(event)} CreditCardButtonText={(stripedata.cust_paymentmethod) ? 'Update Card' : 'Add Card'} CardUpdatefailed={(event) => RedirectCardUpdateFailed(event)} />
                        </Module.Elements>
                      </Module.StripeProvider>
                      </div>
                    :
                    null
                    }
                  </div>
                </Module.Grid.Col>


                <Module.Grid.Col lg={12} className="mt-55">
                  <div className="white-box invoices">
                    <h3 class="card-title">invoices</h3>
                    {(DataLoading || !stripedata.invoicelist) ?
                      <div className="progress">
                        <div className="progress-bar progress-bar-indeterminate bg-green" />
                      </div>
                    : 
                    <form className="invoice-table">
                    <div className="table-responsive">
                      <table className="table">
						<thead>
                          <tr>
                            <th class="wd-20">Date</th>
                            <th class="wd-20">Plan Name</th>
                            <th class="wd-20">Pricing</th>
                            <th class="wd-20">Status</th>
                            <th class="wd-20">Download Invoice</th>
                          </tr>
                        </thead>
                        <tbody>
                        {stripedata.invoicelist.map((item, i) => (
                          <tr>
                            <td data-label="Date">{item.date}</td>
                            <td data-label="Plan Name">{item.planname}</td>
                            <td data-label="Pricing">{item.pricing}</td>
                            <td data-label="Status"><span class="resend-brn text-capitalize">{item.invoicestatus}</span></td>
                            <td data-label="Download Invoice"><a className="invoicedownload-img" onClick={(event) => downloadinvoice(item.id,item.planname)}> </a></td>
                          </tr>
                        ))}
                        </tbody>
                      </table>
                    </div>
                    </form>
                    }
                  </div>
                </Module.Grid.Col>
              </Module.Grid.Row>
            </Module.Container>
        </div>
        {sweetalert}
        <Module.Modal show={Modaldata.visiblestatus} aria-labelledby={"ModalHeader"} className={"ModalCustomStyle"} onHide={closeModal}>
          <Module.Modal.Header closeButton>
            <Module.Modal.Title id='ModalHeader'>Subscription</Module.Modal.Title>
          </Module.Modal.Header>
          <Module.Modal.Body>
            {(Modaldata.modal == 'isupdatemodalopen' ?
              <Module.StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
                <Module.Elements>
                  <Module.InjectedCheckoutForm PaymentSuccess={(event) => RedirectSuccessPayment(event)} Paymentfailed={(event) => RedirectPaymentFailed(event)} />
                </Module.Elements>
              </Module.StripeProvider>
              : null)}
            {(Modaldata.modal == 'isupdatecreditcardmodalopen' ?
              <Module.StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
                <Module.Elements>
                  <Module.InjectedCheckoutFormWithoutCreditcard PaymentSuccess={(event) => RedirectSuccessPayment(event)} Paymentfailed={(event) => RedirectPaymentFailed(event)} />
                </Module.Elements>
              </Module.StripeProvider>
              : null)}
          </Module.Modal.Body>
        </Module.Modal>
      </Module.SiteWrapper>
  )
}
export default MySubscription;
