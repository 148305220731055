// @flow

import * as React from "react";
import {Link} from "react-router-dom";

type Props = {|
  +href?: string,
  +src?: string,
  +alt?: string,
|};

const SiteLogo = (props: Props): React.Node => (

  <Link className="header-brand" to={"/"}>
    <img src={props.src} className="header-brand-img" alt={props.alt} />
    </Link>
);

SiteLogo.displayName = "Site.Logo";

export default SiteLogo;
