import * as React from "react";
import {useState,useEffect} from "react";
import { Formik } from "formik";

import OuterWrapper from "../OuterWrapper.react";
import { Container, Grid, Form, Card,Button } from "../tabler-react/index";

import { Redirect } from "react-router-dom";

import { ForgotPasswordConfirmation as TablerForgotPasswordConfirmation } from "../tabler-react/index";

import { Client } from "../_services/apireq";

const ar = new Client(process.env.REACT_APP_REMOTE_SERVICE_BASE_URL, window);

const ForgotPasswordConfirmation = (props) => {
  const handleSubmit = (values, actions) => {
    ar.forgotPassword(values.email).then(res => {
      if (res.result == true) {
        return props.history.push("/ForgotPasswordConfirmation");
      }
    });
  };

  return (
    <OuterWrapper>
      <h2>Forgot password confirmation</h2>
      <h5>Please check your email to reset your password.</h5>
      <a href={"#"} color="primary" className="login-link"  onClick={e => props.history.push("/login") }>
        Back To Login
      </a>
    </OuterWrapper>
  );
}

export default ForgotPasswordConfirmation;
