import * as React from "react";
import {useState,useEffect} from "react";
import {Link} from "react-router-dom";
import * as Module from './Modules.react';
import InvestmentInfo from './Investmentinfo.react';

const { forwardRef, useRef, useImperativeHandle } = React;

const SharedInvestment = (props) => {
  const [AllSharedInvestments, setAllSharedInvestments] = React.useState([]);
  const [FamilyMembers, setFamilyMembers] = React.useState([]);
  const [BlobImages, setBlobImages] = React.useState([]);
  const [Nominee, setNominee] = React.useState("");
  const [Loading, setLoading] = React.useState(true);
  const [modal, setmodal] = React.useState([{invinfovisible : false,modelpassid: 0,invinfoismodalopen: false}]);
  const [deletefamily, setdeletefamily] = React.useState();

  const breadcumroute = {
    root: {title: 'Home', path: '/'},
    child: [
      {title: 'Shared Investments', path: ''},
    ]
  }
  const _columns = [
    {
      text: 'Investment Title',
      headerStyle: { width:'20%' },
      attrs: {
        'data-label': 'Investment Title'
      },
      formatter : (cell, row) => <a href="#!" onClick={(event) => { invinfoopenModal(row.investementId) }}>{row.investementname}</a>
    },
    {
      dataField: 'financialinstitution',
      text: 'Financial Institution',
      headerStyle: { width:'20%' },
      attrs: {
        'data-label': 'Financial Institution'
      },
    },
    {
      dataField: 'investementCountry',
      text: 'Investment Country',
      headerStyle: { width:'20%' },
      attrs: {
        'data-label': 'Investment Country'
      },
    },
    {
      text: 'Estimated Value',
      headerStyle: { width:'20%' },
      attrs: {
        'data-label': 'Estimated Value'
      },
      formatter : (cell, row) => (row.estimateValue) ? ((row.estimateCurrency) ? row.estimateCurrency + ' ': '') + Module.formatNumber(row.estimateValue) : 'N/A'
    }, {
      text: 'Shared By',
      headerStyle: { width:'20%' },
      attrs: {
        'data-label': 'Shared By'
      },
      formatter : (cell, row) => (row.userProfile) ? <div class="sharedbynameclass"><span class="avatar avatar-md" style={{backgroundImage: "url(" + BlobImages.find(iitem => iitem.name === row.userProfile).bloburl + ")"}}></span><span class="name">{row.createdBy}</span></div> : <div class="sharedbynameclass"><p className="roundednamesharing" style={{backgroundColor: Module.ColorPicker(row.createdBy)}}>{getfirstlatters(row.createdBy)}</p><span class="name">{row.createdBy}</span></div>
    }];

  const invinfoopenModal = (id) => {
    setmodal({
      invinfovisible : true,
      modelpassid: id,
      invinfoismodalopen: true,
    });
  }
  const invinfocloseModal = () => {
    setmodal({
      invinfovisible : false,
      invinfoismodalopen: false,
    });
  }
  const groupSimilar = arr => {
    let uniqueChars = [];
    arr.forEach((c) => {
        if (!uniqueChars.includes(c)) {
            uniqueChars.push(c);
        }
    });

    return uniqueChars.filter(n => n);
  };
  const LoadSharedInvestments = () => {
    Module.ar.getFamilyMembersListinSharing().then(result => {
      setFamilyMembers(result);
    });
    Module.ar.getAllInvestmentShared().then(result => {
      var imageslist = [];
      var images = [];
      {result.map((rdata, i) => {
        imageslist.push(rdata.userProfile);
      })}
      images = groupSimilar(imageslist);

      var tdata =[];
      Promise.all(images.map(async (element) => {
        let bloburl = await Module.getprofilepicture(element);
        tdata.push({'bloburl':bloburl,'name': element});
      })).then(res => {
        setBlobImages(tdata);
        setAllSharedInvestments(result);
      });
    });
  }
  const reloadtabledata = (emailaddress) => {
    Module.ar.getAllInvestmentShared(emailaddress).then(result => {
      var imageslist = [];
      var images = [];
      {result.map((rdata, i) => {
        imageslist.push(rdata.userProfile);
      })}
      images = groupSimilar(imageslist);

      var tdata =[];
      Promise.all(images.map(async (element) => {
        let bloburl = await Module.getprofilepicture(element);
        tdata.push({'bloburl':bloburl,'name': element});
      })).then(res => {
        setBlobImages(tdata);
        setAllSharedInvestments(result);
      });
    });
  }
  const getfirstlatters = (name) => {
    var result = name.split(' ').pop().trim();
    var shortname = '';
    shortname+= name.charAt(0).toUpperCase();
    if(result)
    shortname+= result.charAt(0).toUpperCase();
    return shortname;
  }

  useEffect(()=>
  {
    LoadSharedInvestments();
  },[])
  return (
    <Module.SiteWrapper>
    <Module.Container>
      <Module.Form className="card shared-documents-table">
        <Module.Card.Body>
          <Module.Grid.Row className="mobile-full">
          <Module.Grid.Col width={6}>
            <Module.Card.Title>Shared Investments</Module.Card.Title>
          </Module.Grid.Col>
          </Module.Grid.Row>
          <Module.Grid.Row>
          <Module.Grid.Col width={12}>
            <Module.BreadCum routes={breadcumroute} />
          </Module.Grid.Col>
          </Module.Grid.Row>
  
     <Module.Grid.Row className="right-select-row">
       <Module.Grid.Col width={6} className="right-select-row">
      <Module.Form.Group>
          <Module.Form.Label className="sharedbylable">Shared By</Module.Form.Label>
          <Module.Form.Select
            name="nominee"
            value={Nominee || ""}
            onChange={evt =>
              { setNominee(evt.target.value),reloadtabledata(evt.target.value)}}
          >
            <option key="00" value="">Show All</option>
            {FamilyMembers.map((el, i) => <option key={el.email} value={el.email}>{el.firstname + ' ' + el.lastname}</option>)}
          </Module.Form.Select>
          </Module.Form.Group>
      </Module.Grid.Col>
    </Module.Grid.Row>
    <Module.Grid.Row cards={true}>
      <Module.Grid.Col width={12}>
        <Module.BootstrapTable keyField='id' classes="shareddatatable" bordered={ false } data={ AllSharedInvestments } columns={ _columns } noDataIndication={ <div className="text-center">Please Wait</div> } pagination={ Module.paginationFactory() } />
      </Module.Grid.Col>
      </Module.Grid.Row>
    </Module.Card.Body>
    </Module.Form>
    </Module.Container>
    <section>
        <Module.Modal show={modal.invinfovisible} className="ModalCustomStyle" onHide={() => invinfocloseModal()}>
          <Module.Modal.Header closeButton>
            <Module.Modal.Title id='ModalHeader'>Investment Details</Module.Modal.Title>
          </Module.Modal.Header>
          <Module.Modal.Body>
            {(modal.invinfoismodalopen == true ? <InvestmentInfo shared={true} fnname={() => invinfocloseModal()} fnid={modal.modelpassid} /> : null)}
          </Module.Modal.Body>
        </Module.Modal>
    </section>
  </Module.SiteWrapper>
  );
}
  
export default SharedInvestment;