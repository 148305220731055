import * as React from "react";
import {useState,useEffect} from "react";
import {Link} from "react-router-dom";
import * as Module from './Module.react';


const { forwardRef, useRef, useImperativeHandle } = React;

const CouponsManagement = (props) => {

const CouponPopupRef = useRef();

 const _columns = [
   {
    text: 'Coupon ID',
    dataField: 'couponId',
    sort: true,
      filter: Module.textFilter(),
 attrs: {
        'data-label': 'Coupon ID'
      }
    
  },
  {
    text: 'Coupon Name',
    dataField: 'couponName',
    sort: true,
      filter: Module.textFilter(),
 attrs: {
        'data-label': 'Coupon Name'
      }
    
  },
    {
      text: 'Coupon Type',
      dataField: 'coupontype',
      sort: true,
      filter: Module.textFilter(),
      attrs: {
        'data-label': 'Coupon Type'
      },
      formatter : (cell, row)=> (row.coupontype === "Percentage") ? "Percentage discount" : "Fixed amount discount",
      sortValue: (cell, row)=> (row.coupontype === "Percentage") ? "Percentage discount" : "Fixed amount discount",
      filterValue: (cell, row)=> (row.coupontype === "Percentage") ? "Percentage discount" : "Fixed amount discount",
    },
    {
      text: 'Coupon Discount',
      dataField: 'coupondiscount',
      sort: true,
      filter: Module.textFilter(),
      attrs: {
        'data-label': 'Coupon Discount'
      },
      formatter : (cell, row)=> (row.coupontype === "Percentage") ? row.couponPercentageOff + '%' : row.couponAmountOffCurrency.toUpperCase() + ' ' + row.couponAmountOff,
      sortValue: (cell, row)=> (row.coupontype === "Percentage") ? row.couponPercentageOff + '%' : row.couponAmountOffCurrency.toUpperCase() + ' ' + row.couponAmountOff,
      filterValue: (cell, row)=> (row.coupontype === "Percentage") ? row.couponPercentageOff + '%' : row.couponAmountOffCurrency.toUpperCase() + ' ' + row.couponAmountOff,
    },
    {
      text: 'IsActive',
      dataField: 'isactive',
      sort: true,
      filter: Module.textFilter(),
      attrs: {
        'data-label': 'IsActive'
      },
      formatter : (cell, row)=> (row.active === true) ? "Active" : "Not Active",
      sortValue: (cell, row)=> (row.active === true) ? "Active" : "Not Active",
      filterValue: (cell, row)=> (row.active === true) ? "Active" : "Not Active",
    },
    {
      text: 'Action',
      dataField: 'action',
      attrs: {
        'data-label': 'Action'
      },
      formatter : (cell, row)=> <div className="button-row">
                <a href="#!" className="btn-primary"  onClick={()=> EditThisCoupon(row.couponId)} >Edit</a>&nbsp;&nbsp;&nbsp;
                <a href="#!" className="btn-secondary" onClick={()=>DeleteThisCoupon(row.couponId)}>Delete</a>
            </div>,
    }
];

    const [UserId, setUserId] = useState(false);
    const [CouponsList, setCouponsList] = React.useState([]);
    const [Filter, setFilter] = React.useState(
    {
        columns:[],
        data:[],
        export:false,
        print:false
    });
    const [DeleteCoupon, setDeleteCoupon] = React.useState({
        show: false,
        id: 0
    });
    const [CouponPopupData, setCouponPopupData] = React.useState({
        show: false,
        id: 0
    });
    const DeleteThisCoupon = (id) => {
        if(id) {
        setDeleteCoupon({
            show: true,
            id: id
        });
        }
    }
    const EditThisCoupon = (id) => {
        if(id) {
          setCouponPopupData({
              show: true,
              id: id
          });
        }
    }
    const handleClose = () => {
      setDeleteCoupon({
        show: false,
        id: 0
      });
    }
    const DeleteCouponConfirm = () => {
    Module.ar.deleteCoupon(DeleteCoupon.id).then(res=>{
        if(res && res == "success") {
            Module.SnackbarUtils.success("Coupon Deleted Successfully.");
            setDeleteCoupon({
                show: false,
                id: 0
            });
            LoadCoupons();
        } else {
            Module.SnackbarUtils.error("Failed to delete Coupon.");
            setDeleteCoupon({
                show: false,
                id: 0
            });
            LoadCoupons();
        }
        },
        error => {
            Module.SnackbarUtils.error("Failed to delete Coupon.");
            setDeleteCoupon({
              show: false,
              id: 0
            });
            LoadCoupons();
        });
    }
    const LoadCoupons =()=>{
        Module.ar.getAllDetails().then(res=>{
            setCouponsList(res.coupons);
            setFilter({
                columns:_columns,
                data:res.coupons,
                export:false,
                print:false
            })
        });
    }
    const CouponPopup =()=> {
        setCouponPopupData({
            show: true,
            id: 0
        });
    }
    const CouponPopupClose = ()=>{
        setCouponPopupData({
            show: false,
            id: 0
        });
        console.log(CouponPopupData);
    }
    const CouponDataReload = () => {
        setCouponPopupData({
            show: false,
            id: 0
        });
        LoadCoupons();
    }
  useEffect(()=>
  {
    LoadCoupons();
  },[])
    return (
      <Module.SiteWrapper>
        {(!DeleteCoupon.show) ? (
            null
        ) : 
            <Module.SweetAlert 
                warning 
                title="Are you sure you want to Delete this coupon?"
                showCancel={true}
                onConfirm={(event) => DeleteCouponConfirm()}
                onCancel={(event) => handleClose()}
                confirmBtnText="Yes delete it"
                cancelBtnText="No"
                closeOnClickOutside={false}
            >
                You will no longer be able to see this coupon
            </Module.SweetAlert>
        }
        <Module.Page.Content>
          <Module.Grid.Row className="mobile-full mb-4">
              <Module.Grid.Col width={6}>
                <Module.Card.Title>Coupon Manager</Module.Card.Title>
              </Module.Grid.Col>
              <Module.Grid.Col width={6}>
                  <Module.Button
                    type="button"
                    color="primary"
                    className="float-right"
                    onClick={e => CouponPopup() }
                  >Create
                  </Module.Button>
              </Module.Grid.Col>
            </Module.Grid.Row>
           <Module.Grid.Row cards={true}>          
            <Module.Grid.Col width={12}>
              <Module.Card className="Userlist">
              {/* <Module.DataTableExtensions
                {...Filter}
              >
              <Module.DataTable
                    columns={_columns}
                    data={CouponsList}
                    pagination
                    customStyles={customStyles}
                    fixedHeader = {true}
              />
             </Module.DataTableExtensions> */}

             

              <Module.BootstrapTable 
              bootstrap4
             keyField='id' 
             bordered={ false } 
             classes="card-table table-vcenter investment-table my-family-table" 
             data={CouponsList }
             columns={ _columns } 
             noDataIndication={ <div className="text-center">Please Wait</div> }
              filter={ Module.filterFactory() }
             filterPosition="top"
             pagination={ Module.paginationFactory() } />

              </Module.Card>
              
            </Module.Grid.Col>
          </Module.Grid.Row>
        </Module.Page.Content>
      {/* <CKEditor data="<p>This is an example CKEditor 4 WYSIWYG editor instance.</p>" /> */}
      {(!CouponPopupData.show) ? (
            null
        ) : 
            <Module.Coupon ref={CouponPopupRef} ReloadTable={CouponDataReload} onClickClose={CouponPopupClose} couponid={CouponPopupData.id}></Module.Coupon>
        }
      </Module.SiteWrapper>
    );
  }
  
  export default CouponsManagement;
  
