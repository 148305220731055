import * as React from "react";
import {useState,useEffect} from "react";
import {Link} from "react-router-dom";
import * as Module from './Modules.react';
import FamilyInfo from './FamilyInfo.react';

const { forwardRef, useRef, useImperativeHandle } = React;

const MyFamily = (props) => {
  const [GetAllFamilyData, setGetAllFamilyData] = React.useState([]);
  const [Loading, setLoading] = React.useState(true);
  const [modal, setmodal] = React.useState([{visible : false,modelpassid: 0,ismodalopen: false}]);
  const [deletefamily, setdeletefamily] = React.useState();

  const breadcumroute = {
    root: {title: 'Home', path: '/'},
    child: [
      {title: 'My Family', path: ''},
    ]
  }
  const _columns = [
    {
      text: 'Name',
      headerStyle: { width:'25%' },
      attrs: {
        'data-label': 'Name'
      },
      formatter : (cell, row) => <><a href="#!" className="text-inherit" onClick={(event) => { openModal(row.id) }}>{row.firstname} {row.lastname}</a><div className="d-block mt-2">{row.emailInFamilyUser}</div></>
    },
    {
      text: 'Status',
      headerStyle: { width:'25%' },
      attrs: {
        'data-label': 'Status'
      },
      formatter : (cell, row) => <><Module.Form.Label className={"d-inline pending "+SetStatusColor(row.status)}>{GetStatusOption(row.status)}</Module.Form.Label>{(row.status == 3) ? <span className="justify-content-start"><a className="resend-brn" href='#!' onClick={(event) => { resentemail(row.id) }}>Resend</a> <a href='#!' className="copytoclipboard" onClick={(event) => { (row.callBackUrl != null) ? (Module.copy(row.callBackUrl),document.execCommand('paste'),Module.SnackbarUtils.success("Link Copied to clipboard.")) : Module.SnackbarUtils.error("Link not Available")}}>Copy Sharing URL</a></span> : null + (row.status == 1) ? <div className="d-block mt-2">{row.email}</div> : null}</>
    },
    {
      text: 'Sharing Options',
      headerStyle: { width:'25%' },
      attrs: {
        'data-label': 'Sharing Options'
      },
      formatter : (cell, row) => GetSharingOption(row.defaultSharing)
    }, {
      text: '',
      classes: 'button-row',
      headerStyle: { width:'15%' },
      attrs: {
        'data-label': ''
      },
      formatter : (cell, row) => <><a href="#!" className="btn-primary" onClick={(e) => { props.history.push("/Family/"+ row.id) }} >Edit</a>&nbsp;&nbsp;&nbsp;<a href="#!" className="btn-secondary" onClick={(e) => { deletefamilyperson(row.id) }} >Delete</a></>
    }];

  const LoadFamily = () =>{
    setLoading(true);
    Module.ar.getFamilyData().then(res=>{
      setLoading(false);
      setGetAllFamilyData(res);
    });
  };
  const GetSharingOption = (defaultSharing) => {
    if(defaultSharing == 1) {
      return 'Share';
    } else if(defaultSharing == 2) {
      return 'Not Share';
    } else {
      return 'Share When Inactive';
    }
  }
  const GetStatusOption = (status) => {
    if(status == 1) {
      return 'Active';
    } else if(status == 2) {
      return 'Inactive';
    } else {
      return 'Pending';
    }
  }
  const SetStatusColor = (status) => {
    if(status == 1) {
      return 'text-success';
    } else if(status == 2) {
      return 'text-danger';
    } else {
      return 'text-warning';
    }
  }
  const deletefamilyperson = (id) => {
    setdeletefamily(
      <Module.SweetAlert 
        warning 
        title="Are you sure you want to delete this family member?"
        showCancel={true}
        onConfirm={(event) => {
          Module.ar.removeFamilyPerson(id).then(res=>{
            setdeletefamily();
            Module.SnackbarUtils.success("Remove Successfully from Family.");
            LoadFamily();
          },error => {
            Module.SnackbarUtils.error("Something Went Wrong! Try later");
          });
        }}
        onCancel={(event) => {setdeletefamily();}}
        confirmBtnText="Yes"
        cancelBtnText="Not Now"
        closeOnClickOutside={false}
      >
        You'll no longer be able to see this family member
      </Module.SweetAlert>
    );
  }
  const openModal = (id) => {
    setmodal({
        visible : true,
        modelpassid: id,
        ismodalopen: true,
    });
  }

  const closeModal = () => {
    setmodal({
        visible : false,
        ismodalopen: false,
    });
  }
  const resentemail = (id) => {
    Module.ar.resentEmailToFamilyMember(id).then(result => {
      Module.SnackbarUtils.success("Email sent successfully.");
        return result;
    },
    error => {
      Module.SnackbarUtils.error("Something Went Wrong! Try later");
      return error;
    });
  }

  useEffect(()=>
  {
    LoadFamily();
  },[])
  return (
  <Module.SiteWrapper>
    <Module.Container>
      <Module.Form className="card">
        <Module.Card.Body>
          <Module.Grid.Row className="mobile-full">
          <Module.Grid.Col width={6}>
            <Module.Card.Title>My Family</Module.Card.Title>
          </Module.Grid.Col>
          <Module.Grid.Col width={6}>
            <Module.Button
              type="button"
              color="primary"
              className="float-right"
              onClick={() => {props.history.push("/Family")}}
            >Add New Family Member</Module.Button>
          </Module.Grid.Col>
          </Module.Grid.Row>
          <Module.Grid.Row>
          <Module.Grid.Col width={12}>
            <Module.BreadCum routes={breadcumroute} />
          </Module.Grid.Col>
          </Module.Grid.Row>
          <Module.Grid.Row cards={true}>
          <Module.Grid.Col width={12}>
          <Module.BootstrapTable keyField='id' bordered={ false } classes="card-table table-vcenter text-nowrap investment-table my-family-table" data={ GetAllFamilyData } columns={ _columns } noDataIndication={ 
            (Loading) ?
            <Module.Loading/>
            :
            <div className="position-relative text-center font-weight-bold"><i class="icon fa fa-users display-1"></i>{<div className="no-data-available-text">'No family members to display'</div>}</div>
            }
            pagination={ Module.paginationFactory() } />
            </Module.Grid.Col>
          </Module.Grid.Row>
        </Module.Card.Body>
      </Module.Form>
    </Module.Container>
    {deletefamily}
    <section>
      {/* <input type="button" value="Open" onClick={() => this.openModal()} /> */}
      <Module.Modal show={modal.visible} className="ModalCustomStyle" onHide={() => closeModal()}>
        <Module.Modal.Header closeButton>
        <Module.Modal.Title id='ModalHeader'>Family Details</Module.Modal.Title>
        </Module.Modal.Header>
        <Module.Modal.Body>
          {(modal.ismodalopen == true ? <FamilyInfo fnname={() => closeModal()} fnid={modal.modelpassid} /> : null)}
        </Module.Modal.Body>
      </Module.Modal>
    </section>
  </Module.SiteWrapper>
  );
}
  
export default MyFamily;