// @flow

import * as React from "react";

import { Error404Page } from "../tabler-react/index";

type Props = {||};

function Error404(props: Props): React.Node {
  return <Error404Page />;
}

export default Error404;
