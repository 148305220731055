import * as React from "react";
import {useState,useEffect} from "react";
const { forwardRef, useRef, useImperativeHandle } = React;
import * as Module from './Module.react';

var PriceInput = Module.PricingInput;
var Pricing = Module.Pricing;

const Coupon = (props) => {
    const [buttonstate, setbuttonstate] = useState(false);
    const [actionstate, setactionstate] = useState('Add');
    const [currencydata, setcurrencydata] = useState([]);
    const [countrydata, setcountrydata] = useState([]);
    const [pricingdata, setpricingdata] = useState(Pricing);

    const handleSubmitAction = (values, actions) => {
        setbuttonstate(true);
        console.log(values.country);
        let countrys = values.country.split('&$');
        let Country = countrys[0];
        let CountryName = countrys[1];
        PriceInput = {
            priceId : (props.priceid) ? props.priceid : null,
            priceName : values.pricename,
            productId : process.env.REACT_APP_PRODUCT_ID,
            priceAmount : values.priceamount,
            priceCurrency : values.pricecurrency,
            priceBillingPeriod : values.billingperiod,
            country : Country,
            countryName : CountryName,
            active : values.isactive,
        };
        Module.ar.pricingManage(PriceInput).then(res=>{
            if(res && res == "success") {
                Module.SnackbarUtils.success((actionstate === 'Add') ? 'Pricing Added Successfully.' : 'Pricing Updated Successfully.');
                props.ReloadTable();
            } else {
                Module.SnackbarUtils.error((actionstate === 'Add') ? 'Failed to Added Pricing.' : 'Failed to Updated Pricing.');
                props.ReloadTable();
            }
        },
        error => {
            setbuttonstate(false);
            Module.SnackbarUtils.error((actionstate === 'Add') ? 'Failed to Added Pricing.' : 'Failed to Updated Pricing.');
        });
    }

    useEffect(()=>{
        Module.ar.getCurrencyData().then(res=>{
            setcurrencydata(res);
        });
        Module.ar.getCountryData().then(res=>{
            setcountrydata(res);
        });
        if(props.priceid) {
            setactionstate('Update');
            Module.ar.getPricing(props.priceid).then(res=>{
                console.log(res);
                setpricingdata(res);
            });
        }
    },[])
	return (
        <Module.Container>
        <Module.Modal show={true} className="ModalCustomStyle" onHide={() => props.onClickClose() } animation={false}>
        <Module.Formik
        enableReinitialize={true}
        initialValues={{
            pricename : (pricingdata) ? pricingdata.priceName : "",
            priceamount : (pricingdata) ? pricingdata.priceAmount : "",
            pricecurrency : (pricingdata) ? pricingdata.priceCurrency.toUpperCase() : "",
            billingperiod : (pricingdata) ? pricingdata.priceBillingPeriod : "",
            country : (pricingdata) ? pricingdata.country+'&$'+ pricingdata.countryName : "",
            isactive : (pricingdata) ? pricingdata.active : true,
        }}
        validate={values => {
          // same as above, but feel free to move this into a class method now.
          let errors = {};
          
          if (!values.pricename) {
            errors.pricename = "Price Text Required";
          }
          if (!values.priceamount) {
            errors.priceamount = "Amount Required";
          }
          if (!values.pricecurrency) {
            errors.pricecurrency = "Select Currency";
          }
          if (!values.billingperiod) {
            errors.billingperiod = "Select Billing Period";
          }
          if (!values.country) {
            errors.country = "Select Country";
          }
          return errors;
        }}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={handleSubmitAction}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
        <Module.Form onSubmit={handleSubmit}>
            <Module.Modal.Header closeButton>
            </Module.Modal.Header>
            <Module.Modal.Body>
                <Module.Grid.Row>
                    <Module.Grid.Col sm={12} md={12}>
                        <Module.Card.Title>{(props.priceid) ? 'Edit' : 'Add'} Pricing</Module.Card.Title>
                    </Module.Grid.Col>
                </Module.Grid.Row>
                <Module.Grid.Row>
                    <Module.Grid.Col sm={6} md={6}>
                        <Module.Form.Group>
                            <Module.Form.Label>Price Text <span class="required">*</span></Module.Form.Label>
                            <Module.Form.Input
                                type="text"
                                placeholder="Price Text"
                                name="pricename"
                                value={values.pricename}
                                error={errors.pricename}
                                onChange={handleChange}
                            />
                        </Module.Form.Group>
                    </Module.Grid.Col>
                    <Module.Grid.Col sm={6} md={6}>
                        <Module.Form.Group>
                            <Module.Form.Label>Amount <span class="required">*</span></Module.Form.Label>
                            <Module.Form.Input
                                type="text"
                                placeholder="Amount"
                                name="priceamount"
                                value={values.priceamount}
                                error={errors.priceamount}
                                onChange={handleChange}
                                disabled={(props.priceid)}
                            />
                        </Module.Form.Group>
                    </Module.Grid.Col>
                </Module.Grid.Row>
                <Module.Grid.Row>
                    <Module.Grid.Col sm={6} md={6}>
                        <Module.Form.Group>
                            <Module.Form.Label>Price Currency <span class="required">*</span></Module.Form.Label>
                            <Module.Form.Select
                                name="pricecurrency"
                                value={values.pricecurrency}
                                error={errors.pricecurrency}
                                onChange={handleChange}
                                disabled={(props.priceid)}
                            >
                                <option value="">Select Currency</option>
                                {currencydata.map((option) => (
                                    <option value={option.currencyCode}>{option.currencyName}</option>
                                ))}
                            </Module.Form.Select>
                        </Module.Form.Group>
                    </Module.Grid.Col>
                    <Module.Grid.Col sm={6} md={6}>
                        <Module.Form.Group>
                            <Module.Form.Label>Billing Period <span class="required">*</span></Module.Form.Label>
                            <Module.Form.Select
                                name="billingperiod"
                                value={values.billingperiod}
                                error={errors.billingperiod}
                                onChange={handleChange}
                                disabled={(props.priceid)}
                            >
                                <option value="">Select Billing Period</option>
                                <option value="day">Day</option>
                                <option value="week">Week</option>
                                <option value="month">Month</option>
                                <option value="year">Year</option>
                            </Module.Form.Select>
                        </Module.Form.Group>
                    </Module.Grid.Col>
                </Module.Grid.Row>
                <Module.Grid.Row>
                    <Module.Grid.Col sm={6} md={6}>
                        <Module.Form.Group>
                            <Module.Form.Label>Country <span class="required">*</span></Module.Form.Label>
                            <Module.Form.Select
                                name="country"
                                value={values.country}
                                error={errors.country}
                                onChange={handleChange}
                            >
                                <option value="">Select Country</option>
                                {countrydata.map((option) => (
                                    <option value={option.countryCode+'&$'+option.countryName}>{option.countryName}</option>
                                ))}
                            </Module.Form.Select>
                        </Module.Form.Group>
                    </Module.Grid.Col>
                    <Module.Grid.Col sm={6} md={6}>
                        <Module.Form.Group>
                            <Module.Form.Checkbox
                                name="isactive"
                                label="Is Active"
                                checked={values.isactive}
                                value={values.isactive || false}
                                className="font-20 mt-6"
                                onChange={handleChange}
                            />
                        </Module.Form.Group>
                    </Module.Grid.Col>
                </Module.Grid.Row>
                </Module.Modal.Body>
            <Module.Modal.Footer>
                <Module.Button
                    type="button"
                    color="primary"
                    className="mr-4 btn-secondary"
                    onClick={e => props.onClickClose() }>
                        Back
                    </Module.Button>
                    <Module.Button
                    type="submit"
                    color="primary"
                    disabled={buttonstate}
                    >
                    {(actionstate === 'Add') ? 'Create Price' : 'Update Price'}
                </Module.Button>
            </Module.Modal.Footer>
        </Module.Form>
        )}
      />
    </Module.Modal>
    </Module.Container>
    );
}
export default Coupon;