import * as React from "react";
import {forwardRef,useEffect, useImperativeHandle } from "react";
import { authenticationService } from '../_services';
import { connect } from "react-redux";
import {updateuser} from "../store/index";

const Logout = (props) => {
  const handleLogout = () => {
    localStorage.removeItem("SUBStatus");
    props.updateuser({
      _Name:'',
      _ProfileImage:'',
      _ProfileName:''
    });
    authenticationService.logout();
    return props.history.push('/login');
  }
  useEffect(()=>
  {
  },[])
  return (
    <div>
      {handleLogout()}
    </div>
  );
}
const mapStatetoProps=(state)=>{
  return {
      userdata:state.user.userdata
  }
}


const mapDispatchtoProps=(dispatch)=>({
  
  updateuser : (_val) => { dispatch(updateuser({type:"UPDATE_USER",val:_val}));},
  
})
export default connect(mapStatetoProps, mapDispatchtoProps,null,{ forwardRef: true })(Logout);
