import * as React from "react";
import {useState,useEffect} from "react";
import {Link} from "react-router-dom";
import * as Module from './Module.react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';

const { forwardRef, useRef, useImperativeHandle } = React;

const PricingManagement = (props) => {

  const PricingPopupRef = useRef();

  const [UserId, setUserId] = useState(false);
  const [PricingList, setPricingList] = React.useState([]);
  const [Filter, setFilter] = React.useState(
  {
      columns:[],
      data:[],
      export:false,
      print:false
  });
  const [DeletePricing, setDeletePricing] = React.useState({
      show: false,
      id: 0
  });
  const [PricingPopupData, setPricingPopupData] = React.useState({
      show: false,
      id: 0
  });

 const _columns = [
   {
    text: 'Price Text',
    dataField: 'priceName',
    sort: true,
    filter: Module.textFilter(),
    attrs: {
      'data-label': 'Price Text',
    },
    headerStyle: { width:'17%' },
  },
  {
    text: 'Amount',
    dataField: 'priceamount',
    sort: true,
    filter: Module.textFilter(),
    attrs: {
      'data-label': 'Amount',
    },
    formatter: (cell, row) => { return (row.priceCurrency.toUpperCase() + ' ' + row.priceAmount)},
    sortValue: (cell, row) => { return (row.priceCurrency.toUpperCase() + ' ' + row.priceAmount)},
    filterValue: (cell, row) => { return (row.priceCurrency.toUpperCase() + ' ' + row.priceAmount)},
  },
  {
    dataField: 'recurring',
    text: 'Recurring / One Time',
    filter: Module.textFilter(),
    attrs: {
      'data-label': 'Recurring / One Time'
    },
    sort: true,
    formatter : (cell, row)=> <div className="text-capitalize">{row.priceType}</div>,
    sortValue: (cell, row)=> <div className="text-capitalize">{row.priceType}</div>,
    filterValue: (cell, row)=> <div className="text-capitalize">{row.priceType}</div>,
  },
  {
    dataField: 'billingperiod',
    text: 'Billing Period',    
    filter: Module.textFilter(),
    attrs: {
      'data-label': 'Billing Period'
    },
    sort: true,
    formatter : (cell, row)=> <div className="text-capitalize">{row.priceBillingPeriod}</div>,
    sortValue: (cell, row)=> <div className="text-capitalize">{row.priceBillingPeriod}</div>,
    filterValue: (cell, row)=> <div className="text-capitalize">{row.priceBillingPeriod}</div>,
  },
  {
    dataField: 'country',
    text: 'Country',
    filter: Module.textFilter(),
    attrs: {
      'data-label': 'Country'
    },
    sort: true,
    formatter : (cell, row)=> row.countryName,
    sortValue: (cell, row)=> row.countryName,
    filterValue: (cell, row)=> row.countryName,
  },
  {
    dataField: 'isactive',
    text: 'Is Active',
    filter: Module.textFilter(),
    attrs: {
      'data-label': 'Is Active'
    },
    sort: true,
    formatter : (cell, row)=> (row.active) ? 'Active' : 'Not Active',
    sortValue: (cell, row)=> (row.active) ? 'Active' : 'Not Active',
    filterValue: (cell, row)=> (row.active) ? 'Active' : 'Not Active',
  },
  {
    dataField: 'action',
    text: 'Action',
    attrs: {
      'data-label': 'Action'
    },
    headerStyle: { width:'17%' },
    formatter : (cell, row)=> <div className="button-row">
            <a href="#!" className="btn-primary"  onClick={()=> EditThisPricing(row.priceId)} >Edit</a>&nbsp;&nbsp;&nbsp;
            <a href="#!" className="btn-secondary" onClick={()=>DeleteThisPricing(row.priceId)}>Deactivate</a>
        </div>
  }
];
    const DeleteThisPricing = (id) => {
        if(id) {
        setDeletePricing({
            show: true,
            id: id
        });
        }
    }
    const EditThisPricing = (id) => {
        if(id) {
          setPricingPopupData({
              show: true,
              id: id
          });
        }
    }
    const handleClose = () => {
      setDeletePricing({
        show: false,
        id: 0
      });
    }
    const DeactivatePricingConfirm = () => {
    Module.ar.deletePricing(DeletePricing.id).then(res=>{
        if(res && res == "success") {
            Module.SnackbarUtils.success("Pricing Deactivate Successfully.");
            setDeletePricing({
                show: false,
                id: 0
            });
            LoadPricing();
        } else {
            Module.SnackbarUtils.error("Failed to Deactivate Pricing.");
            setDeletePricing({
                show: false,
                id: 0
            });
            LoadPricing();
        }
        },
        error => {
            Module.SnackbarUtils.error("Failed to Deactivate Pricing.");
            setDeletePricing({
              show: false,
              id: 0
            });
            LoadPricing();
        });
    }
    const LoadPricing =()=>{
        Module.ar.getAllDetails().then(res=>{
            console.log(res.pricinglist);
            setPricingList(res.pricinglist);
            setFilter({
                columns:_columns,
                data:res.pricinglist,
                export:false,
                print:false
            })
        });
    }
    const PricingPopup =()=> {
      setPricingPopupData({
            show: true,
            id: 0
        });
    }
    const PricingPopupClose = ()=>{
      setPricingPopupData({
            show: false,
            id: 0
        });
        console.log(PricingPopupData);
    }
    const PricingDataReload = () => {
      setPricingPopupData({
            show: false,
            id: 0
        });
        LoadPricing();
    }
  useEffect(()=>
  {
    LoadPricing();
  },[])
    return (
      <Module.SiteWrapper>
        {(!DeletePricing.show) ? (
            null
        ) : 
            <Module.SweetAlert 
                warning 
                title="Are you sure you want to Deactivate this Pricing?"
                showCancel={true}
                onConfirm={(event) => DeactivatePricingConfirm()}
                onCancel={(event) => handleClose()}
                confirmBtnText="Yes Deactivate it"
                cancelBtnText="No"
                closeOnClickOutside={false}
                
            >
                You will have to reactivate the pricing again to be visible on the site
            </Module.SweetAlert>
        }
        <Module.Page.Content>
            <Module.Grid.Row className="mobile-full mb-4">
              <Module.Grid.Col width={6}>
                <Module.Card.Title>Price Settings</Module.Card.Title>
              </Module.Grid.Col>
              <Module.Grid.Col width={6}>
                  <Module.Button
                    type="button"
                    color="primary"
                    className="float-right"
                    onClick={e => PricingPopup() }
                  >Create
                  </Module.Button>
              </Module.Grid.Col>
            </Module.Grid.Row>
           <Module.Grid.Row cards={true}>                
            <Module.Grid.Col width={12}>
              <Module.Card className="Userlist">
              {/* <Module.DataTableExtensions
                {...Filter}
              >
              <Module.DataTable
                    columns={_columns}
                    data={PricingList}
                    pagination
                    customStyles={customStyles}
                    fixedHeader = {true}
              />
             </Module.DataTableExtensions> */}


                <Module.BootstrapTable
                  bootstrap4
                  keyField='id' 
                  bordered={ false } 
                  classes="card-table table-vcenter investment-table my-family-table" 
                  data={PricingList }
                  columns={ _columns } 
                  noDataIndication={ <div className="text-center">Please Wait</div> }
                        filter={ Module.filterFactory() }
                  filterPosition="top"
                  pagination={ Module.paginationFactory() } />
              </Module.Card>
              
            </Module.Grid.Col>
          </Module.Grid.Row>
        </Module.Page.Content>
      {/* <CKEditor data="<p>This is an example CKEditor 4 WYSIWYG editor instance.</p>" /> */}
      {(!PricingPopupData.show) ? (
            null
        ) : 
            <Module.Price ref={PricingPopupRef} ReloadTable={PricingDataReload} onClickClose={PricingPopupClose} priceid={PricingPopupData.id}></Module.Price>
        }
      </Module.SiteWrapper>
    );
  }
  
  export default PricingManagement;
  
