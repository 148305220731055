import * as React from "react";
import {useState,useEffect} from "react";
import * as Module from './Module.react'

const { forwardRef, useRef, useImperativeHandle } = React;

const DeleteUser = forwardRef((props,ref) =>
{

const [show, setShow] = useState(false);
const handleClose = () =>
{ 
     setShow(false)
};

const OnDeleteUser = () =>
{
    if (props.UserId) {
        setShow(false);
        const result = Module.ar.delete4(props.UserId).then(
            result => {
                Module.SnackbarUtils.success("User Remove Successfully.");
                props.ParentFun();
                return result;
            },
            error => {
                Module.SnackbarUtils.error("Something Went Wrong! Try later");
                return error;
            }
        );
    }
}

const handleShow = () => setShow(true);

 useImperativeHandle(ref, () => ({
  
 handleClose()
 {
     setShow(false)
  },
  handleShow()
  {
      setShow(true)
  }
  }));
  

    return (
        <>

        {/* <Modal show={show} onHide={handleClose} animation={false}>
            <Modal.Header closeButton>
            <Modal.Title>Alert</Modal.Title>
            </Modal.Header>
            <Modal.Body>
             Are Want to sure to Delete this user?
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
            <Button variant="primary" onClick={OnDeleteUser}>
                Delete
            </Button>
            </Modal.Footer>
        </Modal> */}
        {(!show) ? (
            null
        ) : 
            <Module.SweetAlert 
                warning 
                title="Are you sure you want to Delete this user?"
                showCancel={true}
                onConfirm={(event) => OnDeleteUser()}
                onCancel={(event) => handleClose()}
                confirmBtnText="Yes delete it"
                cancelBtnText="No"
                closeOnClickOutside={false}
            >
                The user will be removed from the system permanently.
            </Module.SweetAlert>
        }
        </>
    )

});

export default DeleteUser;