import * as React from "react";
import {useState,useEffect} from "react";
import {Link} from "react-router-dom";
import * as Module from './Module.react'

const UserFamily = (props) => 
{
 useEffect(()=>
  {
    LoadFamily();
  },
  []);

  const [FamilyList, setFamilyList] = React.useState([]);
  const [state,setState] = React.useState([
      {
       modelpassid: 0,
       ismodalopen: false,
       visible : true
      }
  ]);
  const [UserDetails, setUserDetails] = React.useState();

  const _columns = [    
      {
        formatter : (cell, row) => <>{row.firstname} {row.lastname}<div className="d-block mt-2">{row.emailInFamilyUser}</div></>,
        text: 'NAME',
        sortable: true,
          filter: Module.textFilter(),
 attrs: {
        'data-label': 'NAME'
        }
      },
      {
        formatter : (cell, row)=> <>
        <Module.Form.Label className={"d-inline pending "+SetStatusColor(row.status)}>
        {GetStatusOption(row.status)}</Module.Form.Label></>,
        text: 'STATUS',
        sortable: true,
          filter: Module.textFilter(),
 attrs: {
        'data-label': 'STATUS'
        }
      },
      {
       formatter : (cell, row) => <>{GetSharingOption(row.defaultSharing)}</>,
        text: 'SHARING OPTIONS',
        sortable: true,
           filter: Module.textFilter(),
 attrs: {
        'data-label': 'SHARING OPTIONS'
        }
        
      },
      {
        formatter : (cell, row) => <><a href="#!"  onClick={()=>openModal(row.id)}>View</a></>,
        text: 'ACTION',
        sortable: false,
 attrs: {
        'data-label': 'ACTION'
        }
      }
  ];

const GetSharingOption = (defaultSharing) => {
    if(defaultSharing == 1) {
      return 'Share';
    } else if(defaultSharing == 2) {
      return 'Not Share';
    } else {
      return 'Share When Inactive';
    }
  }
  
  const  GetStatusOption = (status) => {
    if(status == 1) {
      return 'Active';
    } else if(status == 2) {
      return 'Inactive';
    } else {
      return 'Pending';
    }
  }








const SetStatusColor = (status) => {
    if(status == 1) {
      return 'text-success';
    } else if(status == 2) {
      return 'text-danger';
    } else {
      return 'text-warning';
    }
  }



 const openModal = (id) => {
    setState({
        visible : true,
        modelpassid: id,
        ismodalopen: true,
    });
  }

  const closeModal = () => {
    setState({
        visible : false,
        ismodalopen: false,
    });
  }

  const LoadFamily = () =>{
    console.log(UserDetails);
    Module.ar.getFamilyDataByUserId(props.match.params.id).then(res=>
      {
        console.log(res);
      setFamilyList(res);
      },err=>{
    });
    Module.ar.getUserProfile(props.match.params.id).then(
      result=>{ 
          setUserDetails({
            name:result.firstname+" "+result.lastname,
            email: result.emailaddress,
          });
      },error=>{
    });
}

  return(
     <Module.SiteWrapper>
        <Module.Page.Content>
          <Module.Grid.Row cards={true}>
              <Module.Grid.Col width={6}>
                <Module.Card.Title>View Family Details</Module.Card.Title>
                {(UserDetails) ? (
                <><div className="font-20"><b>Name:</b> {UserDetails.name}</div>
                <div className="font-20 mb-2"><b>Email:</b> {UserDetails.email}</div></>
                ) : null}
              </Module.Grid.Col>
              <Module.Grid.Col width={6}>
                <Link to={"/UsersManagement"}>
                  <Module.Button
                      type="button"
                      color="primary"
                      className="btn-secondary float-right"
                  >Back
                  </Module.Button>
                </Link>
              </Module.Grid.Col>
            </Module.Grid.Row>
           <Module.Grid.Row cards={true}>                
            <Module.Grid.Col width={12}>
              <Module.Card className="UserFamily">
           <Module.Grid.Col lg={12}>            
            
            </Module.Grid.Col>   

             <Module.BootstrapTable 
             keyField='id' 
             bordered={ false } 
             classes="card-table table-vcenter text-nowrap investment-table my-family-table" 
             data={ FamilyList }
             columns={ _columns } 
             noDataIndication={ <div className="text-center">Please Wait</div> }
              filter={ Module.filterFactory() }
             filterPosition="top"
             pagination={ Module.paginationFactory() } />

              </Module.Card>
              
            </Module.Grid.Col>
          </Module.Grid.Row>
        </Module.Page.Content>
         <Module.Modal show={state.visible} className="ModalCustomStyle" onHide={() => closeModal()}>
                <Module.Modal.Header closeButton>
                  <h4 id='ModalHeader'>Family Details</h4>
                </Module.Modal.Header>
                <Module.Modal.Body>
                  {(state.ismodalopen == true ? <Module.FamilyInfo fnname={() => closeModal()} fnid={state.modelpassid} /> : null)}
                </Module.Modal.Body>
              </Module.Modal>
      </Module.SiteWrapper>

     
  )

}

export default UserFamily;