import * as React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import * as Module from "./Module.react";

const { forwardRef, useRef, useImperativeHandle } = React;

const StateManagement = (props) => {
  const DeleteCountryRef = useRef();
  const [deletestate, setdeletestate] = React.useState();
  const [StateList, setStateList] = React.useState([]);

  const ModalDeleteState = (stateid) => {
    setdeletestate(
        <Module.SweetAlert 
          warning 
          title="Are you sure you want to remove this statelist?"
          showCancel={true}
          onConfirm={(event) => {
            Module.ar.deleteStates(stateid).then(res=>{
              setdeletestate();
              Module.SnackbarUtils.success("StateList Remove Successfully");
              LoadState();
            },error => {
              Module.SnackbarUtils.error("Something Went Wrong! Try later");
            });
          }}
          onCancel={(event) => {setdeletestate();}}
          confirmBtnText="Yes"
          cancelBtnText="Not Now"
          closeOnClickOutside={false}
        >
          You'll no longer be able to see this StateList
        </Module.SweetAlert>
      );
  };

  const _columns = [
    {
      text: 'STATE ID',
      dataField: 'id',
      headerStyle: { width:'10%' },
      filter: Module.textFilter(),
      attrs: {
          'data-label': 'STATE ID'
        },
        sort:true,
    },

    {
      text: 'STATE LIST',
      dataField: 'states',
      headerStyle: { width:'50%' },
       filter: Module.textFilter(),
      attrs: {
          'data-label': 'STATE LIST'
        },
        sort:true,
    },
    {
      text: 'COUNTRY',
      dataField: 'countryName',
      headerStyle: { width:'10%' },
       filter: Module.textFilter(),
      attrs: {
          'data-label': 'COUNTRY'
        },
        sort:true,
    },
    {
      text: 'IsActive',
      dataField: 'isActive',
      headerStyle: { width:'10%' },
       filter: Module.textFilter(),
      attrs: {
          'data-label': 'IsActive'
        },
        sort:true,
    },
    {
  
      text: 'Action',
      headerStyle: { width:'15%' },
      headerClasses: 'wd-17',
       
     attrs: {
        'data-label': 'Action'
      },
    
      formatter : (cell, row) => (<div className="button-row">
          <a
            href="#!"
            className="btn-primary"
            onClick={() => props.history.push("/States/" + row.id)}
          >
            Edit
          </a>
          &nbsp;&nbsp;&nbsp;
          <a
            href="#!"
            className="btn-secondary"
            onClick={() => ModalDeleteState(row.id)}
          >
            Delete
          </a>
        </div>
      ),
      name: " ",
      sortable: true,
      width: "200px",
    },
  ];
  const [Filter, setFilter] = React.useState({
    columns: [],
    data: [],
    export: false,
    print: false,
  });

  const LoadState = () => {
    Module.ar.getAllStates().then((res) => {
      setStateList(res);
      setFilter({
        columns: _columns,
        data: res,
        export: false,
        print: false,
      });
    });
  };

  useEffect(() => {
    LoadState();
  }, []);
  return (

<Module.SiteWrapper>
<Module.Page.Content>
    <Module.Grid.Row className="mobile-full mb-4">
      <Module.Grid.Col width={6}>
        <Module.Card.Title>State Settings</Module.Card.Title>
      </Module.Grid.Col>
      <Module.Grid.Col width={6}>
        <Link to={"/States"}>
          <Module.Button
            type="button"
            color="primary"
            className="float-right"
          >Create
          </Module.Button>
        </Link>
      </Module.Grid.Col>
    </Module.Grid.Row>
   <Module.Grid.Row cards={true}>                
    <Module.Grid.Col width={12}>
      <Module.Card classtext="Userlist">  
      <Module.BootstrapTable
      bootstrap4
     keyField='id' 
     bordered={ false } 
     classes="card-table table-vcenter investment-table my-family-table" 
     data={StateList}
     columns={ _columns } 
     noDataIndication={ <div className="text-center">Please Wait</div> }
     filter={ Module.filterFactory() }
     filterPosition="top"
     pagination={ Module.paginationFactory() } />

      </Module.Card>
      
    </Module.Grid.Col>
  </Module.Grid.Row>
</Module.Page.Content>
{deletestate}
</Module.SiteWrapper>

  );
};

export default StateManagement;
