import * as React from "react";
import {useState,useEffect} from "react";
import {Link} from "react-router-dom";
const { forwardRef, useRef, useImperativeHandle } = React;
import * as Module from './Module.react'

const EmailManagement = (props) => {
    const _columns = [
         {
          text: 'Template Name',
          dataField: 'mailerName',
          sort: true,
          filter: Module.textFilter(),
          attrs: {
            'data-label': 'Template Name'
          },
          headerClasses: 'width-20',
          classes: 'templaterows',
        },
        {
          text: 'Subject',
          dataField: 'subject',
          sort: true,
          filter: Module.textFilter(),
          attrs: {
            'data-label': 'Subject'
          },
          headerClasses: 'width-30',
          classes: 'templaterows',
        },
        {
          text: 'To List',
          dataField: 'toList',
          sort: true,
          filter: Module.textFilter(),
          attrs: {
            'data-label': 'To List'
          },
          classes: 'templaterows',
        },
        {
          text: 'CC List',
          dataField: 'ccList',
          sort: true,
          filter: Module.textFilter(),
          attrs: {
            'data-label': 'CC List'
          },
          classes: 'templaterows',
        },
        {
          formatter : (cell, row)=> 
          <div className="button-row">
                <a href="#!" className="btn-primary" onClick={()=>props.history.push('/EmailTemplate/'+row.id)}>Edit</a>&nbsp;&nbsp;&nbsp;
                <a href="#!" className="btn-secondary" onClick={()=>DeleteThisTemplate(row.id)}>Delete</a>
            </div>,
           text: 'Action',
           sortable: true,
           attrs: {
            'data-label': 'Action'
            }
        }
      ];
      const [EmailTemplatesList, setEmailTemplatesList] = React.useState([]);
      const [isSending, setIsSending] = useState(false)

      const [Filter, setFilter] = React.useState(
       {
          columns:[],
          data:[],
          export:false,
          print:false
        });
      const [DeleteTemplate, setDeleteTemplate] = React.useState({
        show: false,
        id: 0
      });
      
    useEffect(()=>{
       Module.ar.getAllEmailTemplate().then(res=>{
            console.log(res);
            setEmailTemplatesList(res);

        });
    },[isSending])
    const DeleteThisTemplate = (id) => {
      if(id) {
        setDeleteTemplate({
          show: true,
          id: id
        });
      }
    }
    const handleClose = () => {
      setDeleteTemplate({
        show: false,
        id: 0
      });
    }
    const DeleteTemplateConfirm = () => {
     Module.ar.deleteTemplate(DeleteTemplate.id).then(res=>{
        if(res && res == "success") {
            Module.SnackbarUtils.success("Email Template deleted Successfully.");
            setDeleteTemplate({
              show: false,
              id: 0
            });
            setIsSending(true);
        } else {
            Module.SnackbarUtils.error("Failed to delete Email Template.");
            setDeleteTemplate({
              show: false,
              id: 0
            });
            setIsSending(true);
        }
      },
      error => {
          Module.SnackbarUtils.error("Failed to delete Email Template.");
          setDeleteTemplate({
            show: false,
            id: 0
          });
          setIsSending(true);
      });
    }
    return (
        <Module.SiteWrapper>
          {(!DeleteTemplate.show) ? (
            null
          ) : 
              <Module.SweetAlert 
                  warning 
                  title="Are you sure you want to Delete this Email Template?"
                  showCancel={true}
                  onConfirm={(event) => DeleteTemplateConfirm()}
                  onCancel={(event) => handleClose()}
                  confirmBtnText="Yes delete it"
                  cancelBtnText="No"
                  closeOnClickOutside={false}
              >
                  You will no longer be able to view this template
              </Module.SweetAlert>
          }
            <Module.Page.Content>
                <Module.Grid.Row className="mobile-full mb-4">
                  <Module.Grid.Col width={8}>
                    <Module.Card.Title>Email Template Manager</Module.Card.Title>
                  </Module.Grid.Col>
                  <Module.Grid.Col width={4}>
                    <Link to={"/EmailTemplate"}>
                      <Module.Button
                        type="button"
                        color="primary"
                        className="float-right"
                      >Create
                      </Module.Button>
                    </Link>
                  </Module.Grid.Col>
                </Module.Grid.Row>
                <Module.Grid.Row cards={true}>
                <Module.Grid.Col width={12}>
                <Module.Card className="Userlist">
                <Module.BootstrapTable 
                  bootstrap4
                  bordered={ false } 
                  keyField='id'
                  classes="card-table table-vcenter investment-table my-family-table" 
                  data={ EmailTemplatesList }
                  columns={ _columns } 
                  noDataIndication={ <div className="text-center">Please Wait</div> }
                  bodyStyle={ {width: 500, maxWidth: 500, wordBreak: 'break-all' } }
                  filter={ Module.filterFactory() }
                  filterPosition="top"
                  pagination={ Module.paginationFactory() } />
                </Module.Card>
                
                </Module.Grid.Col>
            </Module.Grid.Row>
        </Module.Page.Content>
        </Module.SiteWrapper>
        );
}
export default EmailManagement;