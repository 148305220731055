// @flow

import * as React from "react";

import { Error401Page } from "../tabler-react/index";

type Props = {||};

function Error401(props: Props): React.Node {
  return <Error401Page />;
}

export default Error401;
