import * as React from "react";
import {useState,useEffect} from "react";
import {Link} from "react-router-dom";
import * as Module from './Module.react'



const { forwardRef, useRef, useImperativeHandle } = React;

const UsersManagement = (props) => {

const AdminChangePasswordRef = useRef();
const DeleteUserRef = useRef();
const RegisterUserRef = useRef();

const ViewDocumentsRef = useRef();
const ViewNomineesRef = useRef();
const ViewTrustiesRef = useRef();

 const ModalChangePassword = (userid) =>
  {
    setUserId(userid);
    AdminChangePasswordRef.current.handleShow(true);
  }

  const ModalDeleteUser = (userid) =>
  {
    setUserId(userid);
    DeleteUserRef.current.handleShow(true);
  }

   const ModalRegisterUser = (userid) =>
  {
    // setUserId(userid);
    // RegisterUserRef.current.handleShow(true);
    return props.history.push("/RegisterUser");
  }

  const ModalViewDocuments  = (userid) =>
  {
    setUserId(userid);
    ViewDocumentsRef.current.handleShow(true);
  }

  const ModalViewNominees  = (userid) =>
  {
    setUserId(userid);
    ViewNomineesRef.current.handleShow(true);
  }

  const ModalViewTrusties  = (userid) =>
  {
    setUserId(userid);
    ViewTrustiesRef.current.handleShow(true);
  }

   

  const ParentEvent = ()=>{
    alert("Parent Event Called");
  }


 const _columns = [
 
   {
    text: 'USER ID',
    dataField: 'userid',
    headerStyle: { width:'7%' },
    filter: Module.textFilter(),
    attrs: {
        'data-label': 'USER ID'
      },
      sort:true,
  },
  {
    text: 'EMAIL',
    dataField: 'email',
    headerStyle: { width:'15%' },
    filter: Module.textFilter(),
    attrs: {
      'data-label': 'EMAIL'
    },
    sort:true,
  },
  {
    text: 'NAME',
    dataField: 'name',
    headerStyle: { width:'10%' },
    filter: Module.textFilter(),
    attrs: {
      'data-label': 'Name'
    },
    sort:true,
  },
   {
    text: 'PHONE',
    dataField: 'phoneNumber',
    headerStyle: { width:'10%' },
    filter: Module.textFilter(),
    attrs: {
      'data-label': 'PHONE'
    },
    sort:true,
  },
  {
    text: 'Role',
    dataField: 'displayName',
    headerStyle: { width:'10%' },
    filter: Module.textFilter(),
    attrs: {
      'data-label': 'Role'
    },
    sort:true,
  },
  {
    text: 'Action',
    headerClasses: 'wd-17',
    headerStyle: { width:'10%' },
    attrs: {
      'data-label': 'Action'
    },
    formatter : (cell, row) =><div className="button-row">
            <a href="#!" className="btn-primary"  onClick={()=> props.history.push("/EditUser/"+row.userid)} >Edit</a>&nbsp;&nbsp;&nbsp;
            <a href="#!" className="btn-secondary" onClick={()=>ModalDeleteUser(row.userid)}>Delete</a>
            &nbsp;&nbsp;&nbsp;
            <Module.Dropdown
              trigger={
                <Module.Dropdown.Trigger
                  icon="more-vertical"
                  toggle={false}
                />
              }
              position="right"
              items={
                <>
                  <Module.Dropdown.Item onClick={()=>props.history.push("/UserInvestment/"+row.userid)}  icon="tag">View Investement</Module.Dropdown.Item>
                  <Module.Dropdown.Item onClick={()=>props.history.push("/UserFamily/"+row.userid)}  icon="tag">View Family</Module.Dropdown.Item>
                  <Module.Dropdown.Item icon="tag" onClick={() => ModalChangePassword(row.userid)}>Change Password</Module.Dropdown.Item>
                  {(row.stripeCustomerId) ? <Module.Dropdown.Item onClick={()=>props.history.push("/UsersSubscriptionDetails/"+row.stripeCustomerId+"/"+row.userid)}  icon="tag">View Subscription</Module.Dropdown.Item> : null}
                </>
              }
            />
            </div>
  }
];
var data = [];
const [UserId, setUserId] = useState(false);
const [UserList, setUserList] = React.useState([]);
const [Filter, setFilter] = React.useState(
 {
    columns:[],
    data:[],
    export:false,
    print:false
  });

const LoadUser =()=>{
Module.ar.getAllUsers().then(res=>{    
     setUserList(res); 
  })   
  };


  useEffect(()=>
  {
    LoadUser();
  },[])
    return (
      <Module.SiteWrapper>
        <Module.Page.Content>
            <Module.Grid.Row className="mobile-full mb-4">
              <Module.Grid.Col width={6}>
                <Module.Card.Title>User Details</Module.Card.Title>
              </Module.Grid.Col>
              <Module.Grid.Col width={6}>
                <Link to={"/RegisterUser"}>
                  <Module.Button
                    type="button"
                    color="primary"
                    className="float-right"
                  >Create
                  </Module.Button>
                </Link>
              </Module.Grid.Col>
            </Module.Grid.Row>
           <Module.Grid.Row cards={true}>                
            <Module.Grid.Col width={12}>
              <Module.Card classtext="Userlist">  
              <Module.BootstrapTable
              bootstrap4
             keyField='id' 
             bordered={ false } 
             classes="card-table table-vcenter investment-table my-family-table" 
             data={ UserList }
             columns={ _columns } 
             noDataIndication={ (UserList.length > 0) ? <div className="text-center">No Data Available</div> : <div className="text-center">Please Wait</div>}
             filter={ Module.filterFactory() }
             filterPosition="top"
             pagination={ Module.paginationFactory() } />

              </Module.Card>
              
            </Module.Grid.Col>
          </Module.Grid.Row>
        </Module.Page.Content>
      <Module.AdminChangePassword ref={AdminChangePasswordRef} onClick={ParentEvent} UserId = {UserId}></Module.AdminChangePassword>
      <Module.DeleteUser ref={DeleteUserRef} ParentFun={LoadUser} UserId = {UserId} ></Module.DeleteUser>
      
      <Module.ViewDocuments ref={ViewDocumentsRef}> </Module.ViewDocuments >
      <Module.ViewNominees ref={ViewNomineesRef}> </Module.ViewNominees >
      <Module.ViewTrusties ref={ViewTrustiesRef}> </Module.ViewTrusties >
      {/* <CKEditor data="<p>This is an example CKEditor 4 WYSIWYG editor instance.</p>" /> */}
      </Module.SiteWrapper>
    );
  }
  
  export default UsersManagement;
  
