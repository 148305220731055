import * as React from "react";
import {useState,useEffect} from "react";
import {Link} from "react-router-dom";
import * as Module from './Modules.react';

const { forwardRef, useRef, useImperativeHandle } = React;

const Investment = (props) => {
  const [InvestmentData, setInvestmentData] = React.useState({
    investmentname: '',
    financialinstitution: '',
    country: '',
    accountholdername: '',
    customInvestmentNumber: '',
    jointowenership: '',
    holding: '',
    currency: '',
    estimatedvalue: '',
    Comment: '',
  });
  const [Nominees, setNominees] = React.useState([]);
  const [Documents, setDocuments] = React.useState([]);
  const [Loading, setLoading] = React.useState(true);

  const SetResult = (result) => {
    setInvestmentData({
      ...InvestmentData,
      investmentname: result.investementname,
      financialinstitution: result.financialinstitution,
      country: result.investementCountry,
      accountholdername: result.accountHolderName,
      customInvestmentNumber: result.customInvestmentNumber,
      jointowenership: result.jointOwnership,
      holding: result.holding,
      currency: result.estimateCurrency,
      estimatedvalue: result.estimateValue,
      Comment: result.comments,
    });
    var nomineesbind = result.nominees.map((el, i) => (
      <Module.Grid.Row>
        <Module.Grid.Col sm={4} md={4}>
          <b>{el.name}</b>
        </Module.Grid.Col>
        <Module.Grid.Col sm={4} md={4}>
          <Module.Form.Checkbox
            name={"minordisabled" + i}
            label="Minor/Disabled"
            checked={el.minorDisabled}
            value={el.minorDisabled || ''}
            disabled="disabled"
          />
        </Module.Grid.Col>
        <Module.Grid.Col sm={4} md={4}>
          <b>{el.trustee}</b>
        </Module.Grid.Col>
      </Module.Grid.Row>
    ));
    setNominees(nomineesbind);

    var documentsbind = result.documents.map(file => (
      <Module.Table.Row key={file.documentName} className="row">
        <Module.Table.Col className="col-md-9">
            {file.documentName}
        </Module.Table.Col>
        <Module.Table.Col className="col-md-3">
          <a href="#!" onClick={e => getfilefromserver(props.fnid,file.id)}>Download</a>
        </Module.Table.Col>
      </Module.Table.Row>
    ));
    setDocuments(documentsbind);
    setLoading(false);
  }
  const LoadInvestmentData = () => {
    if(props.fnid) {
      if(props.shared) 
      {
        Module.ar.getSharedInvestment(props.fnid).then(res=>{
          SetResult(res);
        });
      }
      else if(props.data != undefined && props.data.IsAdmin)
      {
        Module.ar.getInvestmentById(props.data.uid,props.fnid).then(res=>{
          SetResult(res);
        });
      }
      else {
        Module.ar.getInvestment(props.fnid).then(res=>{
          SetResult(res);
        });
      }
    }
  }
  const getfilefromserver = (iid,id) => {
    Module.ar.getFileURL(iid,id).then(result => {
      window.open(result, "_blank");
    });
  }
  useEffect(()=>
  {
    LoadInvestmentData();
  },[])
  return (
    <div>
      {Loading ? (
        <Module.Loading />
      ) : (
        <Module.Container className="modal-popup-dt">
          <Module.Form>
                <Module.Card.Body className="card">
                  <Module.Grid.Row>
                    <Module.Grid.Col sm={4} md={4}>
                      <Module.Form.Group>
                        <Module.Form.Label>Investment Title</Module.Form.Label>
                        <Module.Form.Label>{InvestmentData.investmentname || "-"}</Module.Form.Label>
                      </Module.Form.Group>
                    </Module.Grid.Col>
                    <Module.Grid.Col sm={4} md={4}>
                      <Module.Form.Group>
                        <Module.Form.Label>Account Holder Name</Module.Form.Label>
                        <Module.Form.Label>{InvestmentData.accountholdername || "-"}</Module.Form.Label>
                      </Module.Form.Group>
                    </Module.Grid.Col>
                    <Module.Grid.Col sm={4} md={4}>
                      <Module.Form.Group>
                        <Module.Form.Label>Financial Institution</Module.Form.Label>
                        <Module.Form.Label>{InvestmentData.financialinstitution || "-"}</Module.Form.Label>
                      </Module.Form.Group>
                    </Module.Grid.Col>
                    </Module.Grid.Row>
                    <Module.Grid.Row>
                    <Module.Grid.Col sm={4} md={4}>
                      <Module.Form.Group>
                        <Module.Form.Label>Account Number / Policy Number</Module.Form.Label>
                        <Module.Form.Label>{InvestmentData.customInvestmentNumber || "-"}</Module.Form.Label>
                      </Module.Form.Group>
                    </Module.Grid.Col>
                    <Module.Grid.Col sm={4} md={4}>
                      <Module.Form.Group>
                      <Module.Form.Label>Joint Ownership</Module.Form.Label>
                      <Module.Form.Label>{(InvestmentData.jointowenership.toString() == 'false') ? 'No' : 'Yes'}</Module.Form.Label>
                      </Module.Form.Group>
                    </Module.Grid.Col>
                    <Module.Grid.Col sm={4} md={4}>
                      <Module.Form.Group>
                        <Module.Form.Label>Holding %</Module.Form.Label>
                        <Module.Form.Label>{InvestmentData.holding || "-"}</Module.Form.Label>
                      </Module.Form.Group>
                    </Module.Grid.Col>
                    </Module.Grid.Row>
                    <Module.Grid.Row>
                    <Module.Grid.Col md={4}>
                      <Module.Form.Group>
                        <Module.Form.Label>Investment Country</Module.Form.Label>
                        <Module.Form.Label>{InvestmentData.country || "-"}</Module.Form.Label>
                      </Module.Form.Group>
                    </Module.Grid.Col>
                    <Module.Grid.Col sm={4} md={4}>
                      <Module.Form.Group>
                        <Module.Form.Label>Estimated Value</Module.Form.Label>
                        <Module.Form.Label>{InvestmentData.currency || ""} - {InvestmentData.estimatedvalue || "-"}</Module.Form.Label>
                      </Module.Form.Group>
                    </Module.Grid.Col>
                    <Module.Grid.Col sm={12} md={12}>
                      <Module.Form.Group>
                        <Module.Form.Label>Comment</Module.Form.Label>
                        <Module.Form.Label>{InvestmentData.Comment || "-"}</Module.Form.Label>
                      </Module.Form.Group>
                    </Module.Grid.Col>
                    </Module.Grid.Row>
                    <Module.Grid.Row>
                    <Module.Grid.Col sm={12} md={12} className={"mb-2"}>
                      <Module.Grid.Row>
                        <Module.Grid.Col sm={12} md={12} className="nominees-row">
                        <h3 class="card-title card-title2 top-mt25">Nominees as per investment</h3>
                        </Module.Grid.Col>
                      </Module.Grid.Row>
                    </Module.Grid.Col>
                    <Module.Grid.Col sm={10} md={10}>
                      <Module.Grid.Row className="headingbottomborder nominee-text">
                        <Module.Grid.Col sm={4} md={4}>
                          <b>Nominee Name</b>
                        </Module.Grid.Col>
                        <Module.Grid.Col sm={4} md={4}>
                          <b>Minor/Disabled</b>
                        </Module.Grid.Col>
                        <Module.Grid.Col sm={4} md={4}>
                          <b>Trustee</b>
                        </Module.Grid.Col>
                      </Module.Grid.Row>
                    </Module.Grid.Col>
                    <Module.Grid.Col sm={10} md={10} className={"mt-3"}>
                        {Nominees.length > 0 ? Nominees :
                        <Module.Grid.Row>
                          <Module.Grid.Col sm={12} md={12} className="text-center text-danger">
                              <b>No Records Available</b>
                            </Module.Grid.Col>
                        </Module.Grid.Row>
                        }
                    <Module.Grid.Col sm={12} md={12}>
                    </Module.Grid.Col>
                    </Module.Grid.Col>
                    </Module.Grid.Row>
                    <Module.Grid.Row>
                    <Module.Grid.Col sm={8} md={8} className="documents-row mt-5">
                      <Module.Form.Group>
                        <h3 class="card-title card-title2 top-mt25 mb-0">Uploaded Documents</h3>
                        <Module.Table>
                        <Module.Table.Header>
                          <Module.Table.Row className="row">
                            <Module.Table.ColHeader className="col-md-9">File Name</Module.Table.ColHeader>
                            <Module.Table.ColHeader className="col-md-3">Download</Module.Table.ColHeader>
                          </Module.Table.Row>
                        </Module.Table.Header>
                        <Module.Table.Body>
                          {Documents.length > 0 ? Documents :
                          <Module.Grid.Row>
                          <Module.Grid.Col sm={12} md={12} className="text-center text-danger mt-3">
                                <b>No Records Available</b>
                              </Module.Grid.Col>
                          </Module.Grid.Row>
                          }
                        </Module.Table.Body>
                        </Module.Table>
                      </Module.Form.Group>
                    </Module.Grid.Col>
                    </Module.Grid.Row>
                </Module.Card.Body>
                <Module.Card.Footer className="text-right mt-25">
                </Module.Card.Footer>
              </Module.Form>
        </Module.Container>
      )}
  </div>
  )
}
  
export default Investment;