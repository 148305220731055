// @flow

import * as React from "react";
import {useState,useEffect} from "react";
import { Client, SubscriptionOutput, contactNumber } from "../_services/apireq";
import { StripeProvider } from "react-stripe-elements";
import { Elements } from "react-stripe-elements";
import InjectedCheckoutForm from "../_services/Stripe/CheckoutForm";

const ar = new Client(process.env.REACT_APP_REMOTE_SERVICE_BASE_URL, window);

const Subscription = (props) => {
  useEffect(()=>
  {
  },[])
  return (
    <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
      <Elements>
        <InjectedCheckoutForm />
      </Elements>
    </StripeProvider>
  );
}
export default Subscription;
