import * as React from "react";
import {useState} from "react";
import SweetAlert from 'react-bootstrap-sweetalert';
import * as Module from './Module.react'

const { forwardRef, useRef, useImperativeHandle } = React;

const DeleteCountry = forwardRef((props,ref) =>
{

const [show, setShow] = useState(false);
const handleClose = () =>
{ 
     setShow(false)
};

const OnDeleteCountry = (e,value) =>
{
    if (props.CountryId) {
        setShow(false);
        const result = Module.ar.deleteCountry(props.CountryId).then(
            result => {
                Module.SnackbarUtils.success("Country Removed Successfully.");
            props.ParentFun();
            return result;
            },
            error => {
                Module.SnackbarUtils.error("Something Went Wrong! Try later");
            return error;
            }
        );
    }
}

const handleShow = () => setShow(true);

 useImperativeHandle(ref, () => ({
  
 handleClose()
 {
     setShow(false)
  },
  handleShow()
  {
      setShow(true)
  }
  }));
  
    return (
        <>
        {(!show) ? (
            null
        ) : 
            <SweetAlert 
                warning 
                title="Are you sure you want to Delete this country?"
                showCancel={true}
                onConfirm={(event) => OnDeleteCountry(event,"onConfirmpopup")}
                onCancel={(event) => handleClose()}
                confirmBtnText="Yes delete it"
                cancelBtnText="No"
                closeOnClickOutside={false}
            >
                You will no longer be able to see this Country
            </SweetAlert>
        }
        </>
    )

});

export default DeleteCountry;