

export const GET_USERNAME = "GET_USERNAME";
export const UPDATE_USER = "UPDATE_USER";

const initialState={
   // datausername:'doshi',
    userdata:{
        Name:'',
        ProfileImage:'',
        ProfileName:''
    }
}




const userReducer = (state=initialState,action) => 
{
    switch(action.type)
    {
        case GET_USERNAME:return{
                ...state,
                Name:'New Doshi'
        }
        case UPDATE_USER:return{
            ...state,
             userdata:{...state.userdata,
                Name:action.playload.val._Name,
                ProfileImage:action.playload.val._ProfileImage,
                ProfileName:action.playload.val._ProfileName
            }
         }
       default:return state
       
    }
    

}



export default userReducer;


export const getusername=()=>{
    return{
        type:"GET_USERNAME"
    }
}

export const updateuser=(val)=>{  // we can run function here like http Request and all
    return{
        type:"UPDATE_USER",
        playload:val
    }
}
