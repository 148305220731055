import * as React from "react";
import {useState,useEffect} from "react";
import {Link} from "react-router-dom";
import * as Module from './Modules.react';

const { forwardRef, useRef, useImperativeHandle } = React;

const Family = (props) => {
  const [FamilyData, setFamilyData] = React.useState([]);
  const [Loading, setLoading] = React.useState(true);

  const LoadFamilyData = () =>{
    Module.ar.getFamilyMemberDetail(props.fnid).then(result=>{
      var numberformat = result.phoneNumber.split(/-(.+)/)[0].replace('+','');
      var number = result.phoneNumber.split(/-(.+)/)[1];
      var reletionstatus = (result.reletionship === "Son" ||
      result.reletionship === "Father" ||
      result.reletionship === "Daughter" ||
      result.reletionship === "Mother" ||
      result.reletionship === "Spouse" ||
      result.reletionship === "Sibling");

      setFamilyData({
        firstname:result.firstname,
        lastname:result.lastname,
        emailaddress:result.email,
        countrycode:numberformat,
        phonenumber:number,
        reletionship: (reletionstatus) ? result.reletionship : 'Other',
        otherreletionship: (reletionstatus) ? null : result.reletionship,
        otherreletionshiptoggle: (reletionstatus) ? ' d-none' : ' mt-3',
        minordisabled:result.minorDisabled,
        trustee:result.trustee,
        trusteedisabled : (result.minorDisabled == false) ? true : false,
        defaultsharing : result.defaultSharing.toString()
      });
      setLoading(false);
    });
  }
  const closemodal = () => {
    props.fnname();
  }
  useEffect(()=>
  {
    LoadFamilyData();
  },[])
  return (
    Loading ? (
      <Module.Loading />
    ) : (
      <Module.Container>
        <Module.Form className="card">
              <Module.Card.Body>
                <Module.Grid.Row>
                  <Module.Grid.Col sm={3} md={3}>
                    <Module.Form.Group>
                      <Module.Form.Label>First Name</Module.Form.Label>
                      <Module.Form.Label>{FamilyData.firstname}</Module.Form.Label>
                    </Module.Form.Group>
                  </Module.Grid.Col>
                  <Module.Grid.Col sm={3} md={3}>
                    <Module.Form.Group>
                      <Module.Form.Label>Last Name</Module.Form.Label>
                      <Module.Form.Label>{FamilyData.lastname}</Module.Form.Label>
                    </Module.Form.Group>
                  </Module.Grid.Col>
                  <Module.Grid.Col sm={3} md={3}>
                    <Module.Form.Group>
                      <Module.Form.Label>Email address</Module.Form.Label>
                      <Module.Form.Label>{FamilyData.emailaddress}</Module.Form.Label>
                    </Module.Form.Group>
                  </Module.Grid.Col>
                  <Module.Grid.Col sm={3} md={3}>
                    <Module.Form.Group>
                      <Module.Form.Label>Phone Number</Module.Form.Label>
                      <Module.Form.Label>{'+'+FamilyData.countrycode.replace('~','')+' '+FamilyData.phonenumber}</Module.Form.Label>
                    </Module.Form.Group>
                  </Module.Grid.Col>
                  <Module.Grid.Col sm={3} md={3}>
                    <Module.Form.Group>
                      <Module.Form.Label>Relationship</Module.Form.Label>
                      <Module.Form.Label>{(FamilyData.reletionship == 'Other') ? FamilyData.otherreletionship : FamilyData.reletionship}</Module.Form.Label>
                    </Module.Form.Group>
                  </Module.Grid.Col>
                  <Module.Grid.Col sm={3} md={3}>
                    <Module.Form.Group>
                      <Module.Form.Checkbox
                        name="minordisabled"
                        label="Minor / Disabled"
                        value={FamilyData.minordisabled || false}
                        checked={FamilyData.minordisabled}
                        className="mt-3"
                      />
                    </Module.Form.Group>
                  </Module.Grid.Col>
                  <Module.Grid.Col sm={3} md={3} className={(FamilyData.minordisabled == false) ? 'd-none' : null}>
                    <Module.Form.Group>
                      <Module.Form.Label>Trustee</Module.Form.Label>
                      <Module.Form.Label>{(FamilyData.trustee) ? FamilyData.trustee : '-'}</Module.Form.Label>
                    </Module.Form.Group>
                  </Module.Grid.Col>
                </Module.Grid.Row>
              </Module.Card.Body>
              <Module.Card.Footer className="text-right mt-25">
                <Module.Button
                  type="button"
                  color="primary"
                  onClick={(event) => {closemodal()}}
                >
                  Close
                </Module.Button>
              </Module.Card.Footer>
            </Module.Form>
      </Module.Container>
    )
  );
}
  
export default Family;