import * as React from "react";
import {useState} from "react";
import SweetAlert from 'react-bootstrap-sweetalert';
import * as Module from './Module.react'

const { forwardRef, useRef, useImperativeHandle } = React;

const DeleteCurrency = forwardRef((props,ref) =>
{

const [show, setShow] = useState(false);

const handleClose = () =>
{ 
     setShow(false)
};

const OnDeleteCurrency = (e,value) =>
{
    if (props.id) {
        setShow(false);
        const result = Module.ar.deleteCurrency(props.id).then(
            result => {
                Module.SnackbarUtils.success("Currency Removed Successfully.");
                props.ParentFun();
                return result;
            },
            error => {
                Module.SnackbarUtils.error("Something Went Wrong! Try later");
                return error;
            }
        );
    }
}

const handleShow = () => setShow(true);

 useImperativeHandle(ref, () => ({
  
 handleClose()
 {
     setShow(false)
  },
  handleShow()
  {
      setShow(true)
  }
  }));
  
    return (
        <>
        {(!show) ? (
            null
        ) : 
            <SweetAlert 
                warning 
                title="Are you sure you want to Delete this currency?"
                showCancel={true}
                onConfirm={(event) => OnDeleteCurrency(event,"onConfirmpopup")}
                onCancel={(event) => handleClose()}
                confirmBtnText="Yes delete it"
                cancelBtnText="No"
                closeOnClickOutside={false}
            >
                You will no longer be able to see this Currency
            </SweetAlert>
        }
        </>
    )

});

export default DeleteCurrency;