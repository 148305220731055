import * as React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import * as Module from "./Module.react";

const { forwardRef, useRef, useImperativeHandle } = React;

const CountryManagement = (props) => {
  const DeleteCountryRef = useRef();

  const ModalDeleteCountry = (countryid) => {
    setCountryId(countryid);
    DeleteCountryRef.current.handleShow(true);
  };

  const _columns = [
    {
      text: 'COUNTRY ID',
      dataField: 'id',
       filter: Module.textFilter(),
      attrs: {
          'data-label': 'COUNTRY ID'
        },
        sort:true,
    },

    {
      text: 'COUNTRY NAME',
      dataField: 'countryName',
       filter: Module.textFilter(),
      attrs: {
          'data-label': 'COUNTRY NAME'
        },
        sort:true,
    },
    {
      text: 'COUNTRY CODE',
      dataField: 'countryCode',
       filter: Module.textFilter(),
      attrs: {
          'data-label': 'COUNTRY CODE'
        },
        sort:true,
    },
    {
      text: 'PHONE CODE',
      dataField: 'code',
       filter: Module.textFilter(),
      attrs: {
          'data-label': 'PHONE CODE'
        },
        sort:true,
    },
    {
      text: 'CURRENCY NAME',
      dataField: 'currencyName',
       filter: Module.textFilter(),
      attrs: {
          'data-label': 'CURRENCY NAME'
        },
        sort:true,
    },
    {
  
      text: 'Action',
      headerClasses: 'wd-17',
       
     attrs: {
        'data-label': 'Action'
      },
    
      formatter : (cell, row) => (<div className="button-row">
          <a
            href="#!"
            className="btn-primary"
            onClick={() => props.history.push("/EditCountry/" + row.id)}
          >
            Edit
          </a>
          &nbsp;&nbsp;&nbsp;
          <a
            href="#!"
            className="btn-secondary"
            onClick={() => ModalDeleteCountry(row.id)}
          >
            Delete
          </a>
        </div>
      ),
      name: " ",
      sortable: true,
      width: "200px",
    },
  ];

  const [CountryId, setCountryId] = useState(false);
  const [CountryList, setCountryList] = React.useState([]);
  const [Filter, setFilter] = React.useState({
    columns: [],
    data: [],
    export: false,
    print: false,
  });

  const LoadCountry = () => {
    Module.ar.getAllCountries().then((res) => {
      let finalData= res.map((data,index)=>{
          if (data.countryCode.toLowerCase() == "ca") {
            let dataObject = {...data};
            dataObject.code = data.code.replace("+~","+").replace("~","+")
            return dataObject;
          }
          return data;
      })
      setCountryList(finalData);

      setFilter({
        columns: _columns,
        data: res,
        export: false,
        print: false,
      });
    });
  };

  useEffect(() => {
    LoadCountry();
  }, []);
  return (

<Module.SiteWrapper>
<Module.Page.Content>
    <Module.Grid.Row className="mobile-full mb-4">
      <Module.Grid.Col width={6}>
        <Module.Card.Title>Country Settings</Module.Card.Title>
      </Module.Grid.Col>
      <Module.Grid.Col width={6}>
        <Link to={"/CreateCountry"}>
          <Module.Button
            type="button"
            color="primary"
            className="float-right"
          >Create
          </Module.Button>
        </Link>
      </Module.Grid.Col>
    </Module.Grid.Row>
   <Module.Grid.Row cards={true}>                
    <Module.Grid.Col width={12}>
      <Module.Card classtext="Userlist">  
      <Module.BootstrapTable
      bootstrap4
     keyField='id' 
     bordered={ false } 
     classes="card-table table-vcenter investment-table my-family-table" 
     data={CountryList }
     columns={ _columns } 
     noDataIndication={ <div className="text-center">Please Wait</div> }
     filter={ Module.filterFactory() }
     filterPosition="top"
     pagination={ Module.paginationFactory() } />

      </Module.Card>
      
    </Module.Grid.Col>
  </Module.Grid.Row>
</Module.Page.Content>

<Module.DeleteCountry ref={DeleteCountryRef} ParentFun={LoadCountry} CountryId = {CountryId} ></Module.DeleteCountry>
</Module.SiteWrapper>

  );
};

export default CountryManagement;
