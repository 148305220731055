// @flow
import * as React from "react";
import {forwardRef,useEffect, useImperativeHandle } from "react";
import { NavLink, withRouter, Redirect } from "react-router-dom";
import * as Module from './pages/Modules.react';
import Role from './_services/Roles';
import { connect } from "react-redux";
import {updateuser} from "./store/index";

const SiteWrapper = forwardRef((props, ref) => {
  const [StateData, setStateData] = React.useState([{
    loginperson: 'Welcome',
    loginpersonlatters: '',
    profilepic: null,
  }]);
  const [CheckAccess, setCheckAccess] = React.useState();

  const navBarItems = [
    {
      value: "Home",
      to: "/",
      icon: "home",
      LinkComponent: withRouter(NavLink),
      useExact: true,
    },
    {
      value: "My Investments",
      icon: "box",
      to: "/MyInvestment",
      LinkComponent: withRouter(NavLink),
      useExact: true,
    },
    {
      value: "My Family",
      icon: "file",
      to: "/MyFamily",
      LinkComponent: withRouter(NavLink),
      useExact: true,
    },
    {
      value: "Investments Shared With Me",
      icon: "calendar",
      to: "/SharedInvestment",
      LinkComponent: withRouter(NavLink),
      useExact: true,
    },
  ];

  const AdminNavBarItems = [
    {
      value: "User Manager",
      icon: "home",
      subItems: [
        { value: "User Details", to: "/UsersManagement", LinkComponent: withRouter(NavLink) },
        { value: "User Subscriptions", to: "/UsersSubscriptions", LinkComponent: withRouter(NavLink) },
      ],
    },
    {
      value: "Pricing",
      icon: "home",
      subItems: [
        { value: "Price Settings", to: "/PricingManagement", LinkComponent: withRouter(NavLink) },
        { value: "Coupon Manager", to: "/CouponsManagement", LinkComponent: withRouter(NavLink) },
      ],
    },
    {
      value: "Master Settings",
      icon: "home",
      subItems: [
        { value: "Country Settings", to: "/CountryManagement", LinkComponent: withRouter(NavLink) },
        { value: "State Settings", to: "/StateManagement", LinkComponent: withRouter(NavLink) },
        { value: "Currency Settings", to: "/CurrencyManagement", LinkComponent: withRouter(NavLink) },
        { value: "Investment Title Manager", to: "/InvestmentTitleManagement", LinkComponent: withRouter(NavLink) },
        { value: "Email Template Manager", to: "/EmailManagement", LinkComponent: withRouter(NavLink) },
      ],
    }
  ];

  const accountDropdownProps = {
    avatarURL: "./demo/faces/female/25.jpg",
    avatarpic: false,
    name: "Welcome",
    options: [
      { icon: "user", value: "Profile", to: '/profile', LinkComponent: withRouter(NavLink) },
      { icon: "settings", value: "My Subscription", to: '/MySubscription', LinkComponent: withRouter(NavLink) },
      {
        icon: "log-out",
        value: "Sign out",
        to: "/logout",
        LinkComponent: withRouter(NavLink),
      },
    ],
  };
  const adminaccountDropdownProps = {
    avatarURL: "./demo/faces/female/25.jpg",
    avatarpic: false,
    name: "Welcome",
    options: [
      {
        icon: "log-out",
        value: "Sign out",
        to: "/logout",
        LinkComponent: withRouter(NavLink),
      },
    ],
  };
  useImperativeHandle(ref, () => ({
    Reload() 
    {      
      OnLoadData();
    }
  }));

  const OnLoadData = () => {
    Module.ar.getCurrentSubscribeStatus().then(res=>{
      setCheckAccess(res);
    });
    let personame = "Welcome";
    let loginpersonlatters = '';
    Module.ar.getCurrentLoginInformations(Module.AuthorizationToken).then(res=>{
      if(res) {
        if(res != null && res.user != null) {
          if(res.user.name == res.user.surname) {
            personame = res.user.name;
            let fname,lname;
            try {
              fname = res.user.name.split(' ')[0];
              lname = res.user.name.split(' ')[1];
            } catch (e) {
            fname = res.user.name;
            }
            loginpersonlatters = Module.getfirstlattersconditional(fname,lname);
          }
          else {
            personame = res.user.name + ' ' + res.user.surname;
            loginpersonlatters = Module.getfirstlattersconditional(res.user.name,res.user.surname);
          }
          if(res.user.profilePicture) {
            Module.getprofilepicture().then(result=> {
              loginpersonlatters = result;
              props.updateuser({
                _Name:personame,
                _ProfileImage:loginpersonlatters,
                _ProfileName:''
              });
              setStateData({ loginperson: personame,loginpersonlatters: loginpersonlatters,profilepic: res.user.profilePicture });
            });
          } else {
            props.updateuser({
              _Name:personame,
              _ProfileImage:'',
              _ProfileName:loginpersonlatters
            });
            setStateData({ loginperson: personame,loginpersonlatters: loginpersonlatters,profilepic: res.user.profilePicture });
          }
        }
      }
    });
   
}
    
  const data = StateData.result || null;
  if (StateData.result != null && !StateData.result) {
    return <Redirect to="/Subscription" />;
  }
  //if(this.state.result != null && !this.state.result) { return window.location.replace(process.env.REACT_APP_PRE_BIND_DIRECTION + 'Subscription'); }
  accountDropdownProps.name = StateData.loginperson;
  accountDropdownProps.avatarURL = StateData.loginpersonlatters;
  accountDropdownProps.avatarpic = (StateData.profilepic) ? true : false;
  adminaccountDropdownProps.name = StateData.loginperson;
  adminaccountDropdownProps.avatarURL = StateData.loginpersonlatters;

  useEffect(()=>
  {
    if(props.userdata.Name == "") {
    OnLoadData();
    }
    else
    {
      setStateData({
         loginperson: props.userdata.Name,
         loginpersonlatters: (props.userdata.ProfileImage) ? props.userdata.ProfileImage : props.userdata.ProfileName,
         profilepic: (props.userdata.ProfileImage) ? "Yes.jpg" : null });
    }
  },[])

  return (
    <Module.Site.Wrapper
      headerProps={{
        href: process.env.REACT_APP_PRE_BIND_DIRECTION + "/",
        alt: "Tabler React",
        imageURL: process.env.REACT_APP_PRE_BIND_DIRECTION + "/demo/brand/mwl-logo-h-sml-w2.png",
        accountDropdown: (localStorage.getItem("UserType") == Role.Admin) ? adminaccountDropdownProps : accountDropdownProps,
      }}
      navProps={{ itemsObjects: localStorage.getItem("UserType") == Role.Admin ? AdminNavBarItems : navBarItems }}
      routerContextComponentType={withRouter(Module.RouterContextProvider)}
      footerProps={{
        copyright: (
          <React.Fragment>
            Copyright © {new Date().getFullYear()} My Wealth Locker. All Rights Reserved.
          </React.Fragment>
        ),
        nav: (
          <React.Fragment>
            <Module.Grid.Col auto={true}>
              <Module.List className="list-inline list-inline-dots mb-0">
                <Module.List.Item className="list-inline-item">
                  <a href="./docs/index.html">Documentation</a>
                </Module.List.Item>
                <Module.List.Item className="list-inline-item">
                  <a href="./faq.html">FAQ</a>
                </Module.List.Item>
              </Module.List>
            </Module.Grid.Col>
            <Module.Grid.Col auto={true}>
              <Module.Button
                href="https://github.com/tabler/tabler-react"
                size="sm"
                outline
                color="primary"
                RootComponent="a"
              >
                Source code
              </Module.Button>
            </Module.Grid.Col>
          </React.Fragment>
        ),
      }}
    >
      {props.children}
    </Module.Site.Wrapper>
  );
})


const mapStatetoProps=(state)=>{
  return {
      userdata:state.user.userdata
  }
}


const mapDispatchtoProps=(dispatch)=>({
  
  updateuser : (_val) => { dispatch(updateuser({type:"UPDATE_USER",val:_val}));},
  
})

export default connect(mapStatetoProps, mapDispatchtoProps,null,{ forwardRef: true })(SiteWrapper);