import Cookies from "js-cookie";
import React from 'react';
import SnackbarUtils from './SnackbarUtils';
export const appPath = "/";
export const tokenCookieName = "Abp.AuthToken";
export const AuthorizationToken = "Bearer " + getCookieValue("Abp.AuthToken");
export function CheckAuth() {
  if (
    getCookieValue("Abp.AuthToken") != null &&
    getCookieValue("Abp.AuthToken") != "undefined" &&
    getCookieValue("Abp.AuthToken") != ""
  ) {
    return true;
  } else {
    return false;
  }
}
export function breadcum(data) {
}
export function errorresult(error) {
  var data = JSON.stringify(error);
  data = JSON.parse(data);
  var error = [];
  if(data)
    if(data.message.error)
      if(data.message.error.message != null && data.message.error.details != null)
        error = [data.message.error.message,data.message.error.details];
      else if(data.message.error.message != null)
        error = ['Error',data.message.error.message];
      else if(data.message.error.details != null)
        error = ['Error',data.message.error.details];
      else
        error = ['Internal Error', 'We\'ll Fix That Soon'];

      console.log(error);
      return SnackbarUtils.error(<div key="1" className="errortoster">
             <h4 key="2">{error[0]}</h4>
             <div key="3">{error[1]}</div>
           </div>);
}
export function setToken(authToken, expireDate) {
  setCookieValue(tokenCookieName, authToken, expireDate, appPath, null);
}
export function setCookieValue(key, value, expireDate, path, domain) {
  Cookies.set(key, value, { expires: expireDate, path: path, domain: domain });
  // var cookieValue = encodeURIComponent(key) + '=';

  // if (value) {
  //     cookieValue = cookieValue + encodeURIComponent(value);
  // }

  // if (expireDate) {
  //     cookieValue = cookieValue + "; expires=" + expireDate.toUTCString();
  // }

  // if (path) {
  //     cookieValue = cookieValue + "; path=" + path;
  // }

  // if (domain) {
  //     cookieValue = cookieValue + "; domain=" + domain;
  // }
  // document.cookie = cookieValue;
}
export function setTenantIdCookie(tenantId, domain) {
  var tenantIdCookieName = "Abp.TenantId";
  if (tenantId) {
    setCookieValue(
      tenantIdCookieName,
      tenantId.toString(),
      new Date(new Date().getTime() + 5 * 365 * 86400000), //5 years
      appPath,
      domain
    );
  } else {
    deleteCookie(tenantIdCookieName, appPath);
  }
}
export function getTenantIdCookie() {
  var tenantIdCookieName = "Abp.TenantId";
  var value = getCookieValue(tenantIdCookieName);
  if (!value) {
    return null;
  }
  return parseInt(value);
}
export function getCookieValue(key) {
  // var equalities = document.cookie.split('; ');
  // for (var i = 0; i < equalities.length; i++) {
  //     if (!equalities[i]) {
  //         continue;
  //     }

  //     var splitted = equalities[i].split('=');
  //     if (splitted.length != 2) {
  //         continue;
  //     }

  //     if (decodeURIComponent(splitted[0]) === key) {
  //         return decodeURIComponent(splitted[1] || '');
  //     }
  // }

  // return null;
  return Cookies.get(key);
}
export function deleteCookie(key, path) {
  Cookies.remove(key);
  // var cookieValue = encodeURIComponent(key) + '=';

  // cookieValue = cookieValue + "; expires=" + (new Date(new Date().getTime() - 86400000)).toUTCString();

  // if (path) {
  //     cookieValue = cookieValue + "; path=" + path;
  // }
  // document.cookie = cookieValue;
  //setCookieValue(key,'','',appPath,'');
}
