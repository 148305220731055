import * as React from "react";
import {useState,useEffect} from "react";
const { forwardRef, useRef, useImperativeHandle } = React;
import * as Module from './Module.react';

var InvestmentTitleInput = Module.InvestmentTitleInput;

const InvestmentTitle = (props) => {
    const [buttonstate, setbuttonstate] = useState(false);
    const [actionstate, setactionstate] = useState('Add');
    const [investmenttitledata, setinvestmenttitledata] = useState(Module.InvestmentTitleOutput);

    const handleSubmitAction = (values, actions) => {
        setbuttonstate(true);
        InvestmentTitleInput = {
            id : (props.investmentitleid) ? props.investmentitleid : 0,
            investmentTitle : values.investmenttitle,
            isActive : values.isactive
        };
        Module.ar.addInvestmentTitles(InvestmentTitleInput).then(res=>{
            if(res && res == "success") {
                Module.SnackbarUtils.success((actionstate === 'Add') ? 'Investment Title Added Successfully' : 'Investment Title Updated Successfully');
                props.ReloadTable();
            } else {
                Module.SnackbarUtils.error((actionstate === 'Add') ? 'Failed to Add Investment Title.' : 'Failed to Update Investment Title.');
                props.ReloadTable();
            }
        },
        error => {
            setbuttonstate(false);
            Module.SnackbarUtils.error((actionstate === 'Add') ? 'Failed to Add Investment Title.' : 'Failed to Update Investment Title.');
        });
    }

    useEffect(()=>{
        if(props.investmentitleid) {
            setbuttonstate(true);
            setactionstate('Update');
            Module.ar.getInvestmentTitleDetails(props.investmentitleid).then(res=>{
                setbuttonstate(false);
                setinvestmenttitledata(res);
            });
        }
    },[])
	return (
        <Module.Container>
        <Module.Modal show={true} onHide={() => props.onClickClose() } animation={false}>
        <Module.Formik
        enableReinitialize={true}
        initialValues={{
            investmenttitle : (investmenttitledata) ? investmenttitledata.investmentTitle : "",
            isactive : (investmenttitledata) ? investmenttitledata.isActive : true,
        }}
        validate={values => {
          let errors = {};
          
          if (!values.investmenttitle) {
            errors.investmenttitle = "Investment Title Required";
          }

          return errors;
        }}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={handleSubmitAction}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
        <Module.Form onSubmit={handleSubmit}>
            <Module.Modal.Header closeButton>
            </Module.Modal.Header>
            <Module.Modal.Body>
                <Module.Grid.Row>
                    <Module.Grid.Col sm={12} md={12}>
                        <Module.Card.Title>{(props.investmentitleid) ? 'Edit' : 'Add'} Investment Title</Module.Card.Title>
                    </Module.Grid.Col>
                </Module.Grid.Row>
                <Module.Grid.Row>
                    <Module.Grid.Col sm={6} md={6}>
                        <Module.Form.Group>
                            <Module.Form.Label>Investment Title <span class="required">*</span></Module.Form.Label>
                            <Module.Form.Input
                                type="text"
                                placeholder="Investment Title"
                                name="investmenttitle"
                                value={values.investmenttitle}
                                error={errors.investmenttitle}
                                onChange={handleChange}
                            />
                        </Module.Form.Group>
                    </Module.Grid.Col>
                    <Module.Grid.Col sm={6} md={6}>
                        <Module.Form.Group>
                            <Module.Form.Checkbox
                                name="isactive"
                                label="Is Active"
                                checked={values.isactive}
                                value={values.isactive || true}
                                className="font-20 mt-6"
                                onChange={handleChange}
                            />
                        </Module.Form.Group>
                    </Module.Grid.Col>
                </Module.Grid.Row>
            </Module.Modal.Body>
            <Module.Modal.Footer>
                <Module.Button
                    type="button"
                    color="primary"
                    className="mr-4 btn-secondary"
                    onClick={e => props.onClickClose() }>
                        Back
                    </Module.Button>
                    <Module.Button
                    type="submit"
                    color="primary"
                    disabled={buttonstate}
                    >
                    {(actionstate === 'Add') ? 'Create' : 'Update'}
                </Module.Button>
            </Module.Modal.Footer>
        </Module.Form>
        )}
      />
    </Module.Modal>
    </Module.Container>
    );
}
export default InvestmentTitle;