import SweetAlert from 'react-bootstrap-sweetalert';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import { Client,UpdateProfileInput,MyFamilyInput,SubscriptionData,SubscriptionResponse } from "../_services/apireq";
import { CClient } from "../_services/customapireq";
import Modal from 'react-bootstrap/Modal';
import { Formik } from "formik";
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import SnackbarUtils from '../_services/SnackbarUtils';
import {getfirstlatters,formatNumber,getfirstlattersconditional,rendercardbrand} from '../_services/helpers';
import {Page,List,Site,Avatar,Icon,Grid,Card,Text,Table,Alert,Button,Progress,colors,Dropdown,StampCard,StatsCard,ProgressCard,Badge,Form,Container,RouterContextProvider} from "../tabler-react/index";
import C3Chart from "react-c3js";

import SiteWrapper from "../SiteWrapper.react";
import OuterWrapper from "../OuterWrapper.react";
import { authenticationService } from "../_services";
import { errorresult,getCookieValue } from "../_services/abp";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Loading from "../_services/loadingpage";
import ColorPicker from "../_services/colorpicker";
import getprofilepicture from "../_services/getprofilepic";
import BreadCum from "../_services/breadcum";
import Tooltip from '@material-ui/core/Tooltip';
import Cropper from 'react-easy-crop';
import Slider from '@material-ui/core/Slider';
import { AuthorizationToken } from "../_services/abp";
import getCroppedImg from '../_services/cropImage';

import { StripeProvider } from "react-stripe-elements";
import { Elements } from "react-stripe-elements";
import InjectedCheckoutForm from "../_services/Stripe/CheckoutFormInner";
import CardUpdate from "../_services/Stripe/CardUpdateForm";
import InjectedCheckoutFormWithoutCreditcard from "../_services/Stripe/CheckoutFormInnerWithoutCreditCard";
import copy from 'clipboard-copy';
import createuserModel from "../_services/dto/createuserModel";

const ar = new Client(process.env.REACT_APP_REMOTE_SERVICE_BASE_URL, window);
const ar2 = new CClient(process.env.REACT_APP_REMOTE_SERVICE_BASE_URL, window);

export 
{
ar,
ar2,
SweetAlert,
makeStyles,
LinearProgress,
Client,
CClient,
Formik,
errorresult,
getCookieValue,
Page,
List,
Site,
Avatar,
Icon,
Grid,
Card,
Text,
Table,
Alert,
Button,
Progress,
Container,
colors,
Dropdown,
StampCard,
StatsCard,
ProgressCard,
BootstrapTable,
paginationFactory,
filterFactory,
textFilter,
Badge,
Form,
C3Chart,
SiteWrapper,
OuterWrapper,
RouterContextProvider,
authenticationService,
SnackbarUtils,
getfirstlatters,
getfirstlattersconditional,
rendercardbrand,
formatNumber,
Loading,
ColorPicker,
getprofilepicture,
BreadCum,
Tooltip,
Cropper,
Slider,
getCroppedImg,
AuthorizationToken,
UpdateProfileInput,
MyFamilyInput,
StripeProvider,
Elements,
CardUpdate,
InjectedCheckoutForm,
InjectedCheckoutFormWithoutCreditcard,
Modal,
SubscriptionData,
SubscriptionResponse,
copy,
createuserModel,
}