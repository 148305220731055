import * as React from "react";

import { FormCardForgot, FormTextInput, StandaloneFormPage } from "../../../";

function ForgotPasswordConfirmation(props: Props): React.Node {
  return (
    <StandaloneFormPage imageURL={"./demo/brand/mwl-logo-h-sml-w2.png"}>
      <h2>Forgot password confirmation</h2>
      <h5>Please check your email to reset your password.</h5>
    </StandaloneFormPage>
  );
}

// const ForgotPasswordConfirmationWithTouchedErrors: React.ComponentType<Props> = withTouchedErrors(

// )(ForgotPasswordConfirmation);

export default ForgotPasswordConfirmation;
