import * as React from "react";
import {useState,useEffect} from "react";
import { Redirect } from "react-router-dom";
import * as Module from './Module.react';
import { Client, CurrencyInput } from "../../_services/apireq";

const { forwardRef, useRef, useImperativeHandle } = React;

var currencyinput = CurrencyInput;

const CreateCurrency = forwardRef((props,ref) =>
{
    const [state, setstate] = useState({
        loading: true,
        CurrencyName: null,
        CurrencyCode: null,
        Priority: null,
        isdataavailable: false,       
        isbuttondisable: false,
      });
    
      return (
        <Module.SiteWrapper>
           <Module.Container>
         <Module.Formik

         initialValues={{
           CurrencyName: (state.isdataavailable) ? state.CurrencyName : "",
           CurrencyCode: (state.isdataavailable) ? state.CurrencyCode : "",
           Priority: (state.isdataavailable) ? state.Priority : "",
         }}

         onSubmit={(
           { CurrencyName, CurrencyCode,  Priority },
           { setStatus, setSubmitting }
         ) => {
            currencyinput = {
            id : 0,
            CurrencyName : CurrencyName,
            CurrencyCode : CurrencyCode,
            Priority : 4,
           }

           Module.ar.createCurrency(currencyinput).then(
             result => {
                Module.SnackbarUtils.success("Thanks! Currency Has been Created Successfully");
                props.history.push("/CurrencyManagement");
             },
             error => {
             Module.errorresult(error);
             }
           );
         }}
         validate={values => {
           let errors = {};
          
           if (!values.CurrencyName) {
             errors.CurrencyName = "Currency Name Required";
           }
           if (!values.CurrencyCode) {
             errors.CurrencyCode = "Currency Code Required";
           }
           return errors;
         }}
         validateOnChange={false}
         validateOnBlur={false}
         render={({
           values,
           errors,
           touched,
           handleChange,
           handleBlur,
           handleSubmit,
           isSubmitting,
         }) => (
             <Module.Form  onSubmit={handleSubmit}>
               <Module.Card.Body className="card p-6">
                 <Module.Card.Title RootComponent="div">Create New Currency</Module.Card.Title>
                 <p>Enter your details below to create currency</p>
                 <Module.Form.Group>
                   <Module.Form.Label>Currency Name <span className="required">*</span></Module.Form.Label>
                   <Module.Form.Input
                     name="CurrencyName"
                     placeholder="CurrencyName"
                     disabled = {state.isdataavailable}
                     value={values.CurrencyName}
                     error={errors.CurrencyName}
                     onChange={handleChange}
                     onBlur={handleBlur}
                   />
                 </Module.Form.Group>
                 <Module.Form.Group>
                   <Module.Form.Label>Currency Code <span className="required">*</span></Module.Form.Label>
                   <Module.Form.Input
                     name="CurrencyCode"
                     disabled = {state.isdataavailable}
                     placeholder="Currency Code"                    
                     value={values.CurrencyCode}
                     error={errors.CurrencyCode}
                     onChange={handleChange}
                     onBlur={handleBlur}
                   />
                 </Module.Form.Group>

             </Module.Card.Body>
              <Module.Card.Footer className="text-right mt-25">
 
                 <Module.Button type="button" color="btn btn-primary mr-4 btn-secondary" onClick={e => props.history.push("/CurrencyManagement") }>Back</Module.Button>
                 <Module.Button type="submit" color="primary">
                   Create Currency
                 </Module.Button>
               
               </Module.Card.Footer>
           </Module.Form>
         )}
       />
        </Module.Container>
       </Module.SiteWrapper>
     )

});

export default CreateCurrency;
