import * as React from "react";
import {useState,useEffect} from "react";
import {Link} from "react-router-dom";
import * as Module from './Module.react'

const UserInvestment = (props) => 
{

  useEffect(()=>
  {
    LoadInvestment();
  },[])

const [InvestmentList, setInvestmentList] = React.useState([]);
const [displayInvinfo, setdisplayInvinfo] = React.useState(false);
const [InvestementId, setInvestementId] = React.useState(18);
const [UserDetails, setUserDetails] = React.useState();

const [Filter, setFilter] = React.useState(
 {
    columns:[],
    data:[],
    export:false,
    print:false
  });

const customStyles = {
  rows: {
    style: {
      minHeight: '72px', // override the row height
    }
  },
  headCells: {
    style: {
      paddingLeft: '8px', // override the cell padding for head cells
      paddingRight: '8px',
      fontSize:'15px'
    },
  },
  cells: {
    style: {
      paddingLeft: '8px', // override the cell padding for data cells
      paddingRight: '8px',
    },
  },
};

 const formatNumber = (n) =>{
    // format number 1000000 to 1,234,567
    if (n.indexOf('.') > -1) {
      n = n.split('.')[0]
    }
    return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

const _columns = 
[
 
     {
    text: 'INVESTMENT ID',
    dataField: 'investementId',
    filter: Module.textFilter(),
    attrs: {
      'data-label': 'INVESTMENT ID'
    },
    sort: true,
  },{
    dataField: 'investementtitle',
    text: 'INVESTMENT TITLE',
    sort: true,
    filter: Module.textFilter(),
    attrs: {
      'data-label': 'INVESTMENT TITLE'
    },
    formatter : (cell, row)=><a href="#!" onClick={()=>openModal(row.investementId)}>{row.investementname}</a>,
    sortValue: (cell, row)=> row.investementname,
    filterValue: (cell, row)=> row.investementname,
}, {
  dataField: 'investementCountry',
  text: 'COUNTRY',
  sort: true,
     filter: Module.textFilter(),
 attrs: {
        'data-label': 'COUNTRY'
      },
}, {
  dataField: 'financialinstitution',
  text: 'FINANCIAL INSTITUTION',
  sort: true,
      filter: Module.textFilter(),
 attrs: {
        'data-label': 'FINANCIAL INSTITUTION'
      },
}, {
   formatter : (cell, row) => ((row.documents.length > 0) ? <div className="view-button-image"></div> : ""),
  text: 'ATTACHMENT',
 attrs: {
        'data-label': 'ATTACHMENT'
      }
}, {
   formatter : (cell, row)=> ((row.estimateValue) ? ((row.estimateCurrency) ? row.estimateCurrency + ' ': '') + formatNumber(row.estimateValue) : 'N/A'),
  text: 'ESTIMATED VALUE',
  sort: false,
 attrs: {
        'data-label': 'ESTIMATED VALUE'
      },
      sortValue:(cell, row)=> ((row.estimateValue) ? ((row.estimateCurrency) ? row.estimateCurrency + ' ': '') + formatNumber(row.estimateValue) : 'N/A'),
      filterValue:(cell, row)=> ((row.estimateValue) ? ((row.estimateCurrency) ? row.estimateCurrency + ' ': '') + formatNumber(row.estimateValue) : 'N/A'),
},

];


 const invinfocloseModal = ()=> {
    setdisplayInvinfo(false)
  }

const LoadInvestment =()=>
{
  Module.ar.getAllInvestmentByUserId(props.match.params.id).then(res=>
    {
      setInvestmentList(res);
    },err=>{
  });
  Module.ar.getUserProfile(props.match.params.id).then(
    result=>{ 
        setUserDetails({
          name:result.firstname+" "+result.lastname,
          email: result.emailaddress,
        });
    },error=>{
  })
}

const openModal = (investementId) =>
{
    setdisplayInvinfo(true);
    setInvestementId(investementId);
}
    return(
         <Module.SiteWrapper>
        <Module.Page.Content>
            <Module.Grid.Row cards={true}>
              <Module.Grid.Col width={6}>
                <Module.Card.Title>View Investments</Module.Card.Title>
                {(UserDetails) ? (
                <><div className="font-20"><b>Name:</b> {UserDetails.name}</div>
                <div className="font-20 mb-2"><b>Email:</b> {UserDetails.email}</div></>
                ) : null}
              </Module.Grid.Col>
              <Module.Grid.Col width={6}>
                <Link to={"/UsersManagement"}>
                  <Module.Button
                      type="button"
                      color="primary"
                      className="btn-secondary float-right"
                  >Back
                  </Module.Button>
                </Link>
              </Module.Grid.Col>
            </Module.Grid.Row>
           <Module.Grid.Row cards={true}>
            <Module.Grid.Col width={12}>
              <Module.Card className="UserInvestmentlist">
             <Module.BootstrapTable 
             bootstrap4
             keyField='id' 
             bordered={ false } 
             classes="card-table table-vcenter text-nowrap investment-table my-family-table" 
             data={InvestmentList }
             columns={ _columns } 
             noDataIndication={ <div className="text-center">Please Wait</div> }
              filter={ Module.filterFactory() }
             filterPosition="top"
             pagination={ Module.paginationFactory() } />

              </Module.Card>
              
            </Module.Grid.Col>
          </Module.Grid.Row>
            
            <Module.Modal show={displayInvinfo} className="ModalCustomStyle" onHide={() => invinfocloseModal()}>
                <Module.Modal.Header closeButton>
                  <h4 id='ModalHeader'>Investment Details</h4>
                </Module.Modal.Header>
                <Module.Modal.Body>
                  {(displayInvinfo == true ? <Module.InvestmentInfo fnname={() => invinfocloseModal()} fnid={InvestementId} data={{ uid : props.match.params.id,IsAdmin:true }} /> : null)}
                </Module.Modal.Body>
              </Module.Modal>

        </Module.Page.Content>
      </Module.SiteWrapper>
    )
}
  
  export default UserInvestment;