import * as React from "react";
import {useState,useEffect} from "react";
import {Link} from "react-router-dom";
import * as Module from './pages/Modules.react';
import InvestmentInfo from './pages/Investmentinfo.react';
import FamilyInfo from './pages/FamilyInfo.react';

const { forwardRef, useRef, useImperativeHandle } = React;

const HomePage = (props) => {
  const [DashboardData, setDashboardData] = React.useState();
  const [Loading, setLoading] = React.useState(true);
  const [ImagesLoading, setImagesLoading] = React.useState(true);
  const [ProfileImages, setProfileImages] = React.useState();
  const [modalinvestment, setmodalinvestment] = React.useState([{displayInvinfo : false, modelpassid:0}]);
  const [modalfamily, setmodalfamily] = React.useState([{visible : false,ismodalopen: false,modelpassid: 0}]);


  const LoadHomePage = () =>{
    Module.ar.dashboardData().then(res=>{
      setDashboardData(res);
      setLoading(false);
      var tdata =[];
      Promise.all(res.myfamily.map(async (element) => {
        let bloburl = await Module.getprofilepicture(element.profilepicture);
        tdata.push({'bloburl':bloburl,'id': element.id});
      })).then(result=> {
        setProfileImages(tdata);
        setImagesLoading(false);
      });
    });
    Module.ar.getCurrentSubscribeStatus().then(result => {
        localStorage.setItem('SUBStatus', (result) ? '00b46157' : 'c286de5aa9ff');
        if(!result)
          props.history.push("/login");
    });
  };
  const displayInvinfovisible = (id) => {
    setmodalinvestment({displayInvinfo : true,modelpassid:id});
  }
  const invinfocloseModal = () => {
    setmodalinvestment({displayInvinfo : false, modelpassid:0});
  }
  const openModal = (id) => {
    setmodalfamily({visible : true,modelpassid: id,ismodalopen: true});
  }
  const closeModal = () => {
    setmodalfamily({visible : false,ismodalopen: false,modelpassid: 0});
  }
  useEffect(()=>
  {
    LoadHomePage();
  },[])
  return (
    <>
    <Module.SiteWrapper>
      <Module.Page.Content title="Dashboard">
      {Loading || !DashboardData ? (
                  <div className="progress">
                    <div className="progress-bar progress-bar-indeterminate bg-green" />
                  </div>
                ) : (
        <div>
        <Module.Grid.Row cards={true}>
          <Module.Grid.Col md={12} className="investment-cl">
            <Module.Card className="p-3 font-20 mb-5 font-weight-bold">
              A quick view of all your investments, your family members and the investments shared with you
            </Module.Card>
          </Module.Grid.Col>
          <Module.Grid.Col md={12} className="investment-cl">
            <Module.Grid.Row>
              <Module.Grid.Col sm={4}>
                  <div className="card">
                    <div className="card-body text-center">
                      <div className="h5">
                        <a href='#!' className="dashboard-counts-link" onClick={e => props.history.push("/MyInvestment") }>
                          My Investments
                        </a>
                      </div>
                      <div className="display-4 font-weight-bold mb-4">
                        <a href='#!' className="dashboard-counts-link-count text-primary" onClick={e => props.history.push("/MyInvestment") }>
                          {DashboardData.investmentCounts}
                        </a>
                      </div>
                        <a href="#!" className="mt-3 d-block" onClick={e => props.history.push("/Investment")}>Add New</a>
                    </div>
                  </div>
              </Module.Grid.Col>
              <Module.Grid.Col sm={4}>
                {/* <a href='#!' className="dashboard-counts-link" onClick={e => props.history.push("/SharedInvestment") }><ProgressCard header="Investments Shared With Me" content={state.data.sharedInvestmentCounts} /></a> */}
                <div className="card">
                  <div className="card-body text-center">
                    <div className="h5">
                      <a href='#!' className="dashboard-counts-link" onClick={e => props.history.push("/SharedInvestment") }>
                      Investments Shared With Me
                      </a>
                    </div>
                    <div className="display-4 font-weight-bold mb-4">
                      <a href='#!' className="dashboard-counts-link-count text-primary" onClick={e => props.history.push("/SharedInvestment") }>
                        {DashboardData.sharedInvestmentCounts}
                      </a>
                    </div>
                    <div className="mt-2 d-block">&nbsp;&nbsp;</div>
                  </div>
                </div>
              </Module.Grid.Col>
              <Module.Grid.Col sm={4}>
                {/* <a href='#!' className="dashboard-counts-link" onClick={e => props.history.push("/MyFamily") }><ProgressCard header="Family Members" content={state.data.familyMembersCounts} /></a> */}
                <div className="card">
                  <div className="card-body text-center">
                    <div className="h5">
                      <a href='#!' className="dashboard-counts-link" onClick={e => props.history.push("/MyFamily") }>
                        Family Members
                      </a>
                    </div>
                    <div className="display-4 font-weight-bold mb-4">
                      <a href='#!' className="dashboard-counts-link-count text-primary" onClick={e => props.history.push("/MyFamily") }>
                        {DashboardData.familyMembersCounts}
                      </a>
                    </div>
                    <a href="#!" className="mt-3 d-block" onClick={e => props.history.push("/Family") }>Add New</a>
                  </div>
                </div>
              </Module.Grid.Col>
            </Module.Grid.Row>
          </Module.Grid.Col>
          <Module.Grid.Col width={12}>
            <Module.Card>
              <h3 className="font-23 p-5 mb-0 border-bottom">My Recent Investment</h3>
              <Module.Table
                cards={true}
                striped={true}
                responsive={true}
                className="card-table table-vcenter text-nowrap investment-table recent-investment-table" 
              >
                <Module.Table.Header>
                  <Module.Table.Row>
                    <Module.Table.ColHeader className="wd-75">Investment Title</Module.Table.ColHeader>
                    <Module.Table.ColHeader className="wd-75">Country</Module.Table.ColHeader>
                    <Module.Table.ColHeader className="wd-15">Financial Institution</Module.Table.ColHeader>
                    <Module.Table.ColHeader className="wd-15">Estimated Value</Module.Table.ColHeader>
                    <Module.Table.ColHeader className="wd-15">Joint Owner</Module.Table.ColHeader>
                  </Module.Table.Row>
                </Module.Table.Header>
                {Loading || !DashboardData.myinvestments ? (
                  <Module.Table.Body>
                    <Module.Table.Row>
                      <Module.Table.Col colSpan="5" className="position-relative">
                        <Module.Loading />
                      </Module.Table.Col>
                    </Module.Table.Row>
                  </Module.Table.Body>
                ) : (
                  <Module.Table.Body>
                  {(DashboardData.myinvestments.length > 0) ? (DashboardData.myinvestments.map((item, i) => (
                    <Module.Table.Row>
                      <Module.Table.Col datalable="title">
                      <a href="#!" onClick={(e) => { displayInvinfovisible(item.investementId) }} >
                                {item.investementname}
                      </a>
                      </Module.Table.Col>
                      <Module.Table.Col datalable="Country">{item.investementCountry}</Module.Table.Col>
                      <Module.Table.Col datalable="Financial Institution">{item.financialinstitution}</Module.Table.Col>
                      <Module.Table.Col datalable="Estimated Value">{(item.estimateValue) ? ((item.estimateCurrency) ? item.estimateCurrency + ' ': '') + Module.formatNumber(item.estimateValue) : 'N/A'}</Module.Table.Col>
                      <Module.Table.Col datalable="Joint Owner">{(item.jointOwnership) ? 'Yes' : 'No'}</Module.Table.Col>
                    </Module.Table.Row>
                  ))) : <Module.Table.Row>
                    <Module.Table.Col colSpan="5" className="react-bs-table-no-data">
                        <div className="position-relative text-center font-weight-bold">
                          <i class="icon fa fa-balance-scale display-1"></i>
                          <React.Fragment>
                            {<span className="no-data-available-text">'No investments to display'</span>}
                          </React.Fragment>
                        </div>
                    </Module.Table.Col>
                  </Module.Table.Row>}
                  </Module.Table.Body>
                )}
              </Module.Table>
            </Module.Card>
          </Module.Grid.Col>
        </Module.Grid.Row>
        <Module.Grid.Row className="membres-row">
          <Module.Grid.Col md={6} lg={6}>
            <Module.Card>
              <h3 className="font-23 p-5 mb-0">Family Members</h3>
              <Module.Card.Body>
                
                  {Loading || !DashboardData.myfamily ? (
                    <ul className="list-unstyled list-separated">
                      <li><Loading /></li>
                    </ul>
                  ) : (
                    <ul className="list-unstyled list-separated">
                    {(DashboardData.myfamily.length > 0) ? (DashboardData.myfamily.map((item, i) => (
                      <li className="list-separated-item">
                      <Module.Grid.Row className="align-items-center">
                        <Module.Grid.Col auto className={(!ImagesLoading ? ((item.profilepicture) ? "" : "pt-3") : "pt-3")}>
                          {/* <Avatar
                            size="md"
                            className="d-block"
                            imageURL="demo/faces/female/12.jpg"
                          /> */}
                          {/* <span className="roundedstyle" style={{backgroundColor: colors[Math.floor(Math.random() * colors.length)]}}>{this.getfirstlatters(item.firstname,item.lastname)}</span> */}
                          {/* //getprofilepicture(item.profilepicture) */}
                          {(item.profilepicture) ?
                          (!ImagesLoading ? 
                          <span class="avatar avatar-md d-block" style={{backgroundImage: "url(" + ProfileImages.find(iitem => iitem.id === item.id).bloburl + ")"}}></span>
                          :
                          <p className="roundedname" style={{backgroundColor: Module.ColorPicker(item.firstname)}}>{Module.getfirstlatters(item.firstname,item.lastname)}</p>)
                          :
                          <p className="roundedname" style={{backgroundColor: Module.ColorPicker(item.firstname)}}>{Module.getfirstlatters(item.firstname,item.lastname)}</p>
                          }
                        </Module.Grid.Col>
                        <Module.Grid.Col>
                          <div>
                            <a className="t ext-inherit" href="#!" onClick={(event) => { openModal(item.id) }}>
                              {item.firstname + ' ' + item.lastname}
                            </a>
                          </div>
                          <Module.Text.Small muted className="d-block item-except h-1x">
                            {item.email}
                          </Module.Text.Small>
                        </Module.Grid.Col>
                        <Module.Grid.Col auto>
                          <Module.Dropdown
                            trigger={
                              <Module.Dropdown.Trigger
                                icon="more-vertical"
                                toggle={false}
                              />
                            }
                            position="right"
                            items={
                              <React.Fragment>
                                <a href="#!" onClick={(e) => { props.history.push("/Family/"+ item.id) }} >
                                  <Module.Dropdown.Item icon="edit-2">
                                  Edit
                                  </Module.Dropdown.Item>
                                </a>
                                {/* <Dropdown.Item icon="delete">
                                  Delete
                                </Dropdown.Item> */}
                              </React.Fragment>
                            }
                          />
                        </Module.Grid.Col>
                      </Module.Grid.Row>
                    </li>
                    ))) : <ul className="list-unstyled list-separated">
                    <li>
                      <div className="position-relative text-center font-weight-bold">
                        <i class="icon fa fa-users display-1"></i>
                        {<div className="no-data-available-text">'No family members to display'</div>}
                      </div>
                    </li>
                  </ul>}
                    </ul>
                  )}                
              </Module.Card.Body>
            </Module.Card>
          </Module.Grid.Col>
          <Module.Grid.Col md={6} lg={6}>
            <Module.Card>
              <h3 className="font-23 p-5 mb-0">Recent Announcement</h3>
              <Module.Card.Body>
                <ul className="list-unstyled list-separated">
                  <li className="list-separated-item">
                    <Module.Grid.Row className="align-items-center">
                        <Module.Grid.Col>
                        Thank you for subscribing. Come here for recent announcements / notifications related to your account
                        </Module.Grid.Col>
                      </Module.Grid.Row>
                  </li>
                </ul>
              </Module.Card.Body>
            </Module.Card>
          </Module.Grid.Col>
        </Module.Grid.Row>
        </div>
        )}
      </Module.Page.Content>
        {/* <input type="button" value="Open" onClick={() => this.openModal()} /> */}
    </Module.SiteWrapper>
      <Module.Modal show={modalfamily.visible} className="ModalCustomStyle" onHide={() => closeModal()}>
      <Module.Modal.Header closeButton>
        <Module.Modal.Title id='ModalHeader' className="modal-title">Family Details</Module.Modal.Title>
      </Module.Modal.Header>
      <Module.Modal.Body>
        {(modalfamily.ismodalopen == true ? <FamilyInfo fnname={() => closeModal()} fnid={modalfamily.modelpassid} /> : null)}
      </Module.Modal.Body>
    </Module.Modal>
    <Module.Modal show={modalinvestment.displayInvinfo} className="ModalCustomStyle" onHide={() => invinfocloseModal()}>
      <Module.Modal.Header closeButton>
        <Module.Modal.Title id='ModalHeader' className="d-inline modal-title">Investment Details</Module.Modal.Title>
        <Link to={"/Investment/"+ modalinvestment.modelpassid} className="btn btn-primary float-right">Edit Investment</Link>
      </Module.Modal.Header>
      <Module.Modal.Body>
        {(modalinvestment.displayInvinfo == true ? <InvestmentInfo fnname={() => invinfocloseModal()} fnid={modalinvestment.modelpassid} /> : null)}
      </Module.Modal.Body>
    </Module.Modal>
    </>
  );
}
  
  export default HomePage;
  
