import * as React from "react";
import {useState,useEffect} from "react";
import { Formik } from "formik";

import OuterWrapper from "../OuterWrapper.react";
import { Container, Grid, Form, Card,Button } from "../tabler-react/index";

import { Redirect } from "react-router-dom";

import { Client } from "../_services/apireq";

const ar = new Client(process.env.REACT_APP_REMOTE_SERVICE_BASE_URL, window);

const ForgotPasswordPage = (props) => {
  const handleSubmit = (values, actions) => {
    ar.forgotPassword(values.email).then(res => {
      if (res.result == true) {
        return props.history.push("/ForgotPasswordConfirmation");
      }
    });
  };
  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validate={values => {
        // same as above, but feel free to move this into a class method now.
        let errors = {};
        if (!values.email) {
          errors.email = "Email address Required";
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
        ) {
          errors.email = "Invalid email address";
        }
        return errors;
      }}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleSubmit}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <OuterWrapper>
          <Form className="card" onSubmit={handleSubmit}>
            <Card.Body className="p-6">
              <Card.Title RootComponent="div">FORGOT PASSWORD</Card.Title>
              <p>Enter your email address and your password will be reset and emailed to you.</p>
              <Form.Group>
                <Form.Label>Email Address <span class="required">*</span></Form.Label>
                <Form.Input
                  name="email"
                  type="email"
                  placeholder="Enter email"
                  value={values.email}
                  error={errors.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Form.Group>
              <Form.Footer>
              <Button type="submit" color="primary" block={true}>
                Reset Password
              </Button>
              <div className="link-box">
                <span>Already a member?</span>
                <a href={"#"} color="primary" onClick={e => props.history.push("/login") }>
                  Login
                </a>
              </div>
            </Form.Footer>
          </Card.Body>
        </Form>
        </OuterWrapper>
      )}
    />
  );
}

export default ForgotPasswordPage;
