import * as React from "react";
import {useState,useEffect} from "react";
const { forwardRef, useRef, useImperativeHandle } = React;
import * as Module from './Module.react';

var TemplateInput = Module.EmailTemplatesInput;

const EmailTemplates = (props) => {
    const editor = useRef(null)
	const [content, setContent] = useState('')
    const [buttonstate, setbuttonstate] = useState(false);
    const [Loading, setLoading] = useState(true);
    const [actionstate, setactionstate] = useState('Add');
	
	const config = {
        "askBeforePasteHTML": false,
        "askBeforePasteFromWord": false,
        "defaultActionOnPaste": "insert_only_text",
        "disablePlugins": "font,iframe,hr,add-new-line,about,focus,class-span,delete,copy-format,clipboard,paste-storage,color,drag-and-drop-element,enter,error-messages,format-block,fullsize,hotkeys,inline-popup,justify,limit,link,ordered-list,placeholder,redo-undo,resizer,search,select,size,resize-handler,stat,sticky,tooltip,symbols,image-properties,image-processor,image,media,video,file,resize-cells,select-cells,table-keyboard-navigation,table,print,xpath,preview,paste,clean-html,drag-and-drop,indent"
	}
    const handleSubmitAction = (values, actions) => {
        setbuttonstate(true);
        TemplateInput = {
            id:props.match.params.id,
            mailerName:values.mailername,
            toList:values.toList,
            ccList:values.cclist,
            fromName:values.fromname,
            fromEmail:values.fromemail,
            subject:values.subject,
            messageBody:values.content,
            isHTML:values.ishtml,
        };
        Module.ar.manageMessagingMailer(TemplateInput).then(res=>{
            if(res && res == "success") {
                Module.SnackbarUtils.success((actionstate === 'Add') ? 'Email Template Added Successfully' : 'Email Template Updated Successfully');
                return props.history.push("/EmailManagement");
            } else {
                Module.SnackbarUtils.error((actionstate === 'Add') ? 'Failed to Added Email Template.' : 'Failed to Updated Email Template.');
                return props.history.push("/EmailManagement");
            }
        },
        error => {
            setbuttonstate(false);
            Module.SnackbarUtils.error((actionstate === 'Add') ? 'Failed to Added Email Template.' : 'Failed to Updated Email Template.');
        });
    }
    
    const [EmailTemplatesData, setEmailTemplatesData] = React.useState([]);
    const [RenderHTMLLive, setRenderHTMLLive] = React.useState('');

    const handleEditorChange = (data) => 
    {
        if(data)
            setRenderHTMLLive(data);
    }

    useEffect(()=>{
        if(props.match.params.id) {
            setactionstate('Update');
            Module.ar.getEmailTemplate(props.match.params.id).then(res=>{
                setEmailTemplatesData(res);
                setLoading(false);
                if(res) {
                    setRenderHTMLLive(res.messageBody);
                }
            });
        } else
        setLoading(false);

    },[])
	return (
        <Module.SiteWrapper>
        <Module.Container>
        {(!Loading) ?
        <>
        <Module.Formik
        initialValues={{
            mailername: (EmailTemplatesData) ? EmailTemplatesData.mailerName : "",
            subject: (EmailTemplatesData) ? EmailTemplatesData.subject : "",
            tolist: (EmailTemplatesData) ? EmailTemplatesData.toList : "",
            cclist: (EmailTemplatesData) ? EmailTemplatesData.ccList : "",
            fromname: (EmailTemplatesData) ? EmailTemplatesData.fromName : "",
            fromemail: (EmailTemplatesData) ? EmailTemplatesData.fromEmail : "",
            content: (EmailTemplatesData) ? EmailTemplatesData.messageBody : "",
            ishtml: (EmailTemplatesData) ? EmailTemplatesData.isHTML : "",
        }}
        validate={values => {
          // same as above, but feel free to move this into a class method now.
          let errors = {};
          
          if (!values.mailername) {
            errors.mailername = "Mailer Name Required";
          }
          if (!values.subject) {
            errors.subject = "Subject Required";
          }
          if (!values.content) {
            errors.content = "Provide Email Content";
          }
          return errors;
        }}
        onSubmit={handleSubmitAction}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
        <Module.Form onSubmit={handleSubmit}>
            <Module.Card.Body className="card">
                <Module.Grid.Row>
                    <Module.Card.Title>{(actionstate === 'Add') ? 'Add' : 'Edit'} Email Template</Module.Card.Title>
                </Module.Grid.Row>
                <Module.Grid.Row>
                    <Module.Grid.Col sm={6} md={6}>
                        <Module.Form.Group>
                            <Module.Form.Label>Mailer Name <span class="required">*</span></Module.Form.Label>
                            <Module.Form.Input
                                type="text"
                                placeholder="Mailer Name"
                                name="mailername"
                                disabled={(EmailTemplatesData.length != 0) ? true : false}
                                value={values.mailername}
                                error={errors.mailername}
                                onChange={handleChange}
                            />
                        </Module.Form.Group>
                    </Module.Grid.Col>
                    <Module.Grid.Col sm={6} md={6}>
                        <Module.Form.Group>
                            <Module.Form.Label>Subject <span class="required">*</span></Module.Form.Label>
                            <Module.Form.Input
                                type="text"
                                placeholder="Subject"
                                name="subject"
                                disabled={(EmailTemplatesData.length != 0) ? true : false}
                                value={values.subject}
                                error={errors.subject}
                                onChange={handleChange}
                            />
                        </Module.Form.Group>
                    </Module.Grid.Col>
                </Module.Grid.Row>
                <Module.Grid.Row>
                    <Module.Grid.Col sm={6} md={6}>
                        <Module.Form.Group>
                            <Module.Form.Label>To List</Module.Form.Label>
                            <Module.Form.Input
                                type="text"
                                placeholder="To List"
                                name="tolist"
                                value={values.tolist}
                                error={errors.tolist}
                                onChange={handleChange}
                            />
                        </Module.Form.Group>
                    </Module.Grid.Col>
                    <Module.Grid.Col sm={6} md={6}>
                        <Module.Form.Group>
                            <Module.Form.Label>CC List</Module.Form.Label>
                            <Module.Form.Input
                                type="text"
                                placeholder="CC List"
                                name="cclist"
                                value={values.cclist}
                                error={errors.cclist}
                                onChange={handleChange}
                            />
                        </Module.Form.Group>
                    </Module.Grid.Col>
                </Module.Grid.Row>
                <Module.Grid.Row>
                    <Module.Grid.Col sm={6} md={6}>
                        <Module.Form.Group>
                            <Module.Form.Label>From Name</Module.Form.Label>
                            <Module.Form.Input
                                type="text"
                                placeholder="From Name"
                                name="From Name"
                                value={values.fromname}
                                error={errors.fromname}
                                onChange={handleChange}
                            />
                        </Module.Form.Group>
                    </Module.Grid.Col>
                    <Module.Grid.Col sm={6} md={6}>
                        <Module.Form.Group>
                            <Module.Form.Label>From Email</Module.Form.Label>
                            <Module.Form.Input
                                type="text"
                                placeholder="From Email"
                                name="fromemail"
                                value={values.fromemail}
                                error={errors.fromemail}
                                onChange={handleChange}
                            />
                        </Module.Form.Group>
                    </Module.Grid.Col>
                </Module.Grid.Row>
                <Module.Grid.Row cards={true}>
                    <Module.Grid.Col width={6} className={(errors.content) ? "editorerror" : null}>
                        <Module.JoditEditor
                            ref={editor}
                            value={(values.content) ? values.content.replace("<p>","").replace("</p>","") : ""}
                            config={config}
                            onChange={(newContent) => {(newContent) ? values.content = newContent.replace("<p>","").replace("</p>","") : null}}
                            onBlur={(newContent) => {(newContent) ? values.content = newContent.replace("<p>","").replace("</p>","") : null; (newContent) ? handleEditorChange((newContent) ? newContent.replace("<p>","").replace("</p>","") : null) : ''}}
                            height={"150px"}
                        />
                        <div className={(errors.content) ? 'invalid-feedback d-block' : "d-none"}>{errors.content}</div>
                    </Module.Grid.Col>
                    <Module.Grid.Col width={6}>
                        <div dangerouslySetInnerHTML={{__html: RenderHTMLLive}}></div>
                    </Module.Grid.Col>
                </Module.Grid.Row>
                <Module.Grid.Row cards={true}>
                    <Module.Grid.Col width={6}>
                    <Module.Form.Checkbox
                        name="ishtml"
                        label="Is Html?"
                        checked={values.ishtml}
                        value={values.ishtml || false}
                        className="font-20 mt-7"
                        onChange={handleChange}
                    />
                    </Module.Grid.Col>
                </Module.Grid.Row>
            </Module.Card.Body>
            <Module.Card.Footer className="text-right mt-25">
                <Module.Button
                type="button"
                color="primary"
                className="mr-4 btn-secondary"
                onClick={e => this.props.history.push("/EmailManagement") }>
                    Back
                </Module.Button>
                <Module.Button
                type="submit"
                color="primary"
                disabled={buttonstate}
                >
                {(actionstate === 'Add') ? 'Create Template' : 'Update Template'}
                </Module.Button>
            </Module.Card.Footer>
        </Module.Form>
        )}
      />
      </>
      : null }
      </Module.Container>
      </Module.SiteWrapper>
    );
}
export default EmailTemplates;