// @flow

import React from "react";

import OuterWrapper from "../OuterWrapper.react";
import { Formik  } from "formik";
import { Container, Grid, Form, Card,Button } from "../tabler-react/index";

class Empty extends React.Component {
  handleSubmit = (values, actions) => {
  }
  render() {
    return (
      <OuterWrapper>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validate={values => {
          let errors = {};
          if (!values.email) {
            errors.email = "Email address Required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }
          if (!values.password) {
            errors.password = "Password Required";
          }
          return errors;
        }}
        onSubmit={this.handleSubmit}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form>
            <Form.Input
              type="text"
              placeholder="Email"
              name="email"
              value={values.email}
              error={errors.email}
              onChange={handleChange}
            />
            <Form.Input
              type="text"
              placeholder="Password"
              name="password"
              value={values.password}
              error={errors.password}
              onChange={handleChange}
            />
            <Button type="button" color="primary" className="mr-4 btn-secondary"
            onClick={handleSubmit}>
            Back</Button>
          </Form>
        )}
      />
      </OuterWrapper>
    );
  }
}

export default Empty;