// @flow

import * as React from "react";
import { Container, Site, Notification, AccountDropdown } from "../";
import type { Props as NotificationTrayProps } from "../Notification/NotificationTray.react";
import type { Props as AccountDropdownProps } from "../AccountDropdown/AccountDropdown.react";
import cn from "classnames";
import { Grid, Nav } from "../../";

type subNavItem = {|
  +value: string,
  +to?: string,
  +icon?: string,
  +LinkComponent?: React.ElementType,
  +useExact?: boolean,
|};

type navItem = {|
  +value: string,
  +to?: string,
  +icon?: string,
  +active?: boolean,
  +LinkComponent?: React.ElementType,
  +subItems?: Array<subNavItem>,
  +useExact?: boolean,
|};
type navItems = Array<navItem>;

export type Props = {|
  +children?: React.Node,
  /**
   * header alignment
   */
  +align?: string,
  /**
   * href attribute for the logo
   */
  +href?: string,
  /**
   * Logo image URL
   */
  +imageURL?: string,
  /**
   * The logo alt attribute
   */
  +alt?: string,
  /**
   * Include a notifications tray
   */
  +notificationsTray?: NotificationTrayProps,
  +accountDropdown?: AccountDropdownProps,
  +navProps?: navProps,
  +navItems?: React.Node,

  +items?: React.ChildrenArray<React.Element<typeof Nav.Item>>,
  +itemsObjects?: navItems,
  /**
   * Display a search form to the right of the nav items
   */
  +withSearchForm?: boolean,
  /**
   * Provide your own component to replace the search form
   */
  +rightColumnComponent?: React.Node,
  /**
   * Toggle the collapsed state of the nav
   */
  +collapse?: boolean,
  +routerContextComponentType?: React.ElementType,
  /**
   * Handle toggling/collapsing of the mobile menu when the collapse icon is clicked
   */
  +onMenuToggleClick?: () => void,
|};


/**
 * The very top header bar of your website, containing the logo and some optional
 * action components, such as a NotificationTray or an AccountDropdown on the right hand side
 */
const SiteHeader = ({
  children,
  href,
  align,
  imageURL,
  alt,
  notificationsTray: notificationsTrayFromProps,
  accountDropdown: accountDropdownFromProps,
  navProps: navProps,
  navItems,
  onMenuToggleClick,
  items,
  itemsObjects,
  withSearchForm = true,
  rightColumnComponent,
  collapse = true,
  routerContextComponentType,
  navPropsWithCollapse
}: Props): React.Node => {

  const nav =
  navPropsWithCollapse &&
  React.createElement(Site.Nav, navPropsWithCollapse);

  const notificationsTray =
    notificationsTrayFromProps &&
    React.createElement(Notification.Tray, notificationsTrayFromProps);

  const accountDropdown =
    accountDropdownFromProps &&
    React.createElement(AccountDropdown, accountDropdownFromProps);

  const classes = cn("header d-lg-flex p-0", { collapse });
  return (
    <div className="header d-lg-flex p-0 py-4">
      <Container className={align}>
        <div className="d-flex">
          {children || (
            <React.Fragment>
              <Site.Logo href={href} alt={alt} src={imageURL} />
              {nav}
              <div className="d-flex order-lg-2">
                {navItems}
                {notificationsTray}
                {accountDropdown}
              </div>
              <a
                className="header-toggler d-lg-none ml-3 ml-lg-0"
                onClick={onMenuToggleClick}
              >
                <span className="header-toggler-icon" />
              </a>
            </React.Fragment>
          )}
        </div>
      </Container>
    </div>
  );
};

SiteHeader.displayName = "Site.Header";

export default SiteHeader;
