// @flow

import * as React from "react";

import { Page, Card, Grid, Form, Button, Dropdown } from "./tabler-react/index";


import SiteWrapper from "./SiteWrapper.react";

function FormElements() {
  return (
    <SiteWrapper>
      <Page.Card>
       
      </Page.Card>
    </SiteWrapper>
  );
}

export default FormElements;
