import * as React from "react";
import {useState,useEffect} from "react";
import {Link} from "react-router-dom";
import * as Module from './Module.react';


const { forwardRef, useRef, useImperativeHandle } = React;

const InvestmentTitleManagement = (props) => {

const InvestmentTitleRef = useRef();

 const _columns = [
    {
        text: 'Investment Title',
        dataField: 'investmentTitle',
        sort: true,
        headerStyle: { width:'40%' },
        filter: Module.textFilter(),
        attrs: {
            'data-label': 'Investment Title'
        }
    },
    {
        text: 'Is Active',
        dataField: 'isActive',
        sort: true,
        headerStyle: { width:'40%' },
        filter: Module.textFilter(),
        attrs: {
            'data-label': 'Is Active'
        },
        formatter : (cell, row)=> (row.isActive) ? 'Yes': 'No',
        sortValue: (cell, row)=> (row.isActive) ? 'Yes': 'No',
        filterValue: (cell, row)=> (row.isActive) ? 'Yes': 'No',
    },
    {
      text: 'Action',
      dataField: 'action',
      headerStyle: { width:'15%' },
      attrs: {
        'data-label': 'Action'
      },
      formatter : (cell, row)=> <div className="button-row">
                <a href="#!" className="btn-primary"  onClick={()=> EditThisTitle(row.id)} >Edit</a>&nbsp;&nbsp;&nbsp;
                <a href="#!" className="btn-secondary" onClick={()=>DeleteThisTitle(row.id)}>Delete</a>
            </div>,
    }
];

    const [InvestmentTitleList, setInvestmentTitleList] = React.useState([]);
    const [DeleteTitle, setDeleteTitle] = React.useState({
        show: false,
        id: 0
    });
    const [TitlePopupData, setTitlePopupData] = React.useState({
        show: false,
        id: 0
    });
    const DeleteThisTitle = (id) => {
        if(id) {
        setDeleteTitle({
            show: true,
            id: id
        });
        }
    }
    const EditThisTitle = (id) => {
        if(id) {
          setTitlePopupData({
              show: true,
              id: id
          });
        }
    }
    const handleClose = () => {
      setDeleteTitle({
        show: false,
        id: 0
      });
    }
    const DeleteTitleConfirm = () => {
        Module.ar.removeInvestmentTitle(DeleteTitle.id).then(res=>{
        if(res && res == "success") {
            Module.SnackbarUtils.success("Investment Title Deleted Successfully.");
            setDeleteTitle({
                show: false,
                id: 0
            });
            LoadInvestmentTitle();
        } else {
            Module.SnackbarUtils.error("Failed to delete Investment Title.");
            setDeleteTitle({
                show: false,
                id: 0
            });
            LoadInvestmentTitle();
        }
        },
        error => {
            Module.SnackbarUtils.error("Failed to delete Investment Title.");
            setDeleteTitle({
              show: false,
              id: 0
            });
            LoadInvestmentTitle();
        });
    }
    const LoadInvestmentTitle =()=>{
        Module.ar.getAllInvestmentTitles().then(res=>{
            setInvestmentTitleList(res);
        });
    }
    const InvestmentTitle =()=> {
        setTitlePopupData({
            show: true,
            id: 0
        });
    }
    const InvestmentTitleClose = ()=>{
        setTitlePopupData({
            show: false,
            id: 0
        });
    }
    const CouponDataReload = () => {
        setTitlePopupData({
            show: false,
            id: 0
        });
        LoadInvestmentTitle();
    }
  useEffect(()=>
  {
    LoadInvestmentTitle();
  },[])
    return (
      <Module.SiteWrapper>
        {(!DeleteTitle.show) ? (
            null
        ) : 
            <Module.SweetAlert 
                warning 
                title="Are you sure you want to Delete this Investment Title?"
                showCancel={true}
                onConfirm={(event) => DeleteTitleConfirm()}
                onCancel={(event) => handleClose()}
                confirmBtnText="Yes delete it"
                cancelBtnText="No"
                closeOnClickOutside={false}
            >
                You will no longer be able to see this Investment Title
            </Module.SweetAlert>
        }
        <Module.Page.Content>
          <Module.Grid.Row className="mobile-full mb-4">
              <Module.Grid.Col width={6}>
                <Module.Card.Title>Investment Title Manager</Module.Card.Title>
              </Module.Grid.Col>
              <Module.Grid.Col width={6}>
                  <Module.Button
                    type="button"
                    color="primary"
                    className="float-right"
                    onClick={e => InvestmentTitle() }
                  >Create
                  </Module.Button>
              </Module.Grid.Col>
            </Module.Grid.Row>
           <Module.Grid.Row cards={true}>          
            <Module.Grid.Col width={12}>
              <Module.Card className="Userlist">
              {/* <Module.DataTableExtensions
                {...Filter}
              >
              <Module.DataTable
                    columns={_columns}
                    data={InvestmentTitleList}
                    pagination
                    customStyles={customStyles}
                    fixedHeader = {true}
              />
             </Module.DataTableExtensions> */}

             

              <Module.BootstrapTable 
                bootstrap4
                keyField='id' 
                bordered={ false } 
                classes="card-table table-vcenter investment-table my-family-table" 
                data={InvestmentTitleList }
                columns={ _columns } 
                noDataIndication={ <div className="text-center">Please Wait</div> }
                filter={ Module.filterFactory() }
                filterPosition="top"
                pagination={ Module.paginationFactory() } />
              </Module.Card>
              
            </Module.Grid.Col>
          </Module.Grid.Row>
        </Module.Page.Content>
      {/* <CKEditor data="<p>This is an example CKEditor 4 WYSIWYG editor instance.</p>" /> */}
      {(!TitlePopupData.show) ? (
            null
        ) : 
            <Module.InvestmentTitle ref={InvestmentTitleRef} ReloadTable={CouponDataReload} onClickClose={InvestmentTitleClose} investmentitleid={TitlePopupData.id}></Module.InvestmentTitle>
        }
      </Module.SiteWrapper>
    );
  }
  
  export default InvestmentTitleManagement;
  
