import * as React from "react";
import {useState,useEffect} from "react";
import {Link} from "react-router-dom";
import * as Module from './Modules.react';

const { forwardRef, useRef, useImperativeHandle } = React;

const Family = (props) => {
  const [FamilyData, setFamilyData] = React.useState({
    firstname:'',
    lastname:'',
    emailaddress:'',
    countrycode:'',
    phonenumber:'',
    reletionship:'',
    otherreletionship:'',
    otherreletionshiptoggle:'',
    minordisabled:false,
    trustee:'',
    defaultsharing:'1',
  });
  const [Loading, setLoading] = React.useState(true);
  const [Countries, setCountries] = React.useState();
  const [Buttonaction, setButtonaction] = React.useState('add');
  const [ButtonStatus, setButtonStatus] = React.useState(false);

  var familyinput = Module.MyFamilyInput;

  const LoadFamilyData = () =>{
    Module.ar.getCountryData().then(res=>{
      setCountries(res);
    });
    setLoading(false);
    setButtonaction('add');
    setButtonStatus(true);
  };
  const updateprofile = (values, actions) => {
    setButtonStatus(false);
    familyinput = {
      id:0,
      userid:0,
      Firstname:values.firstname,
      Lastname:values.lastname,
      Email:values.emailaddress,
      PhoneNumber: values.countrycode + '-' + values.phonenumber,
      Reletionship: (values.reletionship == "Other") ? values.otherreletionship : values.reletionship,
      MinorDisabled:values.minordisabled,
      Trustee: (values.minordisabled == true) ? values.trustee : null,
      DefaultSharing:values.defaultsharing,
    };
    Module.ar.addFamilyData(familyinput).then(result => {
        if(result == "success") {
          closeModal();
          setButtonStatus(true);
          Module.SnackbarUtils.success((Buttonaction == 'update') ? "Updated Successfully in Family." : "Added Successfully in Family.");
          return props.history.push("/MyFamily");
        } else {
          closeModal();
          setButtonStatus(true);
          Module.SnackbarUtils.error(result);
        }
      },
      error => {
        Module.SnackbarUtils.error("Failed to add in Family.");
      }
    );
  }
  const closeModal = () => {
    props.fnname();
  }
  useEffect(()=>
  {
    LoadFamilyData();
  },[])
  return (
    Loading ? (
      <Module.Loading />
    ) : (
      <Module.Container className="family-modal">
        <Module.Formik
              initialValues={{
                firstname: FamilyData.firstname,
                lastname: FamilyData.lastname,
                emailaddress: FamilyData.emailaddress,
                countrycode: FamilyData.countrycode,
                phonenumber: FamilyData.phonenumber,
                reletionship: FamilyData.reletionship,
                otherreletionship:FamilyData.otherreletionship,
                minordisabled: FamilyData.minordisabled,
                trustee: FamilyData.trustee,
                defaultsharing: FamilyData.defaultsharing,
              }}
              validate={values => {
                let errors = {};
                if (!values.emailaddress) {
                  errors.emailaddress = "Email address Required";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.emailaddress)
                ) {
                  errors.emailaddress = "Invalid email address";
                }
                if (!values.firstname) {
                  errors.firstname = "First name Required";
                }
                else if (values.firstname.length > 26) {
                  errors.firstname = 'Must be 26 characters or less';
                }
                if (!values.lastname) {
                  errors.lastname = "Last name Required";
                }
                else if (values.lastname.length > 26) {
                  errors.lastname = 'Must be 26 characters or less';
                }
                if (!values.countrycode) {
                  errors.countrycode = "Country Code Required";
                }
                if (!values.phonenumber) {
                  errors.phonenumber = "Phone Number Required";
                } else if (!/^([0-9])*$/i.test(values.phonenumber)) {
                  errors.phonenumber = "Invalid Phone Number";
                }                  
                if (!values.reletionship) {
                  errors.reletionship = "Reletionship Required";
                }
                if(values.reletionship == "Other") {
                    if (!values.otherreletionship) {
                      errors.otherreletionship = "Reletionship Required";
                    }
                } else {
                }
                if(values.minordisabled == true) {
                  if (!values.trustee) {
                    errors.trustee = "Truste Required";
                  }
                }
                return errors;
              }}
              validateOnChange={false}
              handleChange={values => {
                console.log(values);
              }}
              onSubmit={updateprofile}
              render={({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <Module.Form onSubmit={handleSubmit}>
                  <Module.Card.Body className="card">
                  <Module.Grid.Row>
                  <Module.Grid.Col sm={12} md={12}>
                      <Module.Card.Title>{(Buttonaction == 'add') ? 'Add' : 'Update'} Family</Module.Card.Title>
                  </Module.Grid.Col>
                  </Module.Grid.Row>
                    <Module.Grid.Row>
                      <Module.Grid.Col sm={4} md={4}>
                        <Module.Form.Group>
                          <Module.Form.Label>First Name <span class="required">*</span></Module.Form.Label>
                          <Module.Form.Input
                            type="text"
                            placeholder="First Name"
                            name="firstname"
                            value={values.firstname}
                            error={errors.firstname}
                            onChange={handleChange}
                          />
                        </Module.Form.Group>
                      </Module.Grid.Col>
                      <Module.Grid.Col sm={4} md={4}>
                        <Module.Form.Group>
                          <Module.Form.Label>Last Name <span class="required">*</span></Module.Form.Label>
                          <Module.Form.Input
                            type="text"
                            placeholder="Last Name"
                            name="lastname"
                            value={values.lastname}
                            error={errors.lastname}
                            onChange={handleChange}
                          />
                        </Module.Form.Group>
                      </Module.Grid.Col>
                      <Module.Grid.Col sm={4} md={4}>
                        <Module.Form.Group>
                          <Module.Form.Checkbox
                            name="minordisabled"
                            label="Minor / Disabled"
                            checked={values.minordisabled}
                            value={values.minordisabled || false}
                            className="font-20 mt-6 ml-5"
                            onChange={handleChange}
                          />
                        </Module.Form.Group>
                      </Module.Grid.Col>
                      <Module.Grid.Col sm={4} md={4}>
                        <Module.Form.Group>
                          <Module.Form.Label>{(values.minordisabled === false) ? 'Email address' : 'Trustee Email Address'} <span class="required">*</span></Module.Form.Label>
                          <Module.Form.Input
                            type="email"
                            placeholder="Email"
                            name="emailaddress"
                            value={values.emailaddress}
                            error={errors.emailaddress}
                            onChange={handleChange}
                          />
                        </Module.Form.Group>
                      </Module.Grid.Col>
                      <Module.Grid.Col sm={8} md={8} className="add-full-wd-80">
                        <Module.Form.Group>
                          <Module.Form.Label>{(values.minordisabled === false) ? 'Phone Number' : 'Trustee Phone Number'} <span class="required">*</span></Module.Form.Label>
                          <Module.Grid.Row className="add-mb-wd-50">
                            <Module.Grid.Col sm={5} md={5}>
                              <Module.Form.Group className="mb-0">
                              <Module.Form.Select
                                name="countrycode"
                                value={values.countrycode}
                                error={errors.countrycode}
                                onChange={handleChange}
                              >
                                <option value="">Select Country Code</option>
                                {Countries && Countries.map((option) => (
                                        <option value={option.code}>{option.countryName + " (" + option.code.replace("~","") + ")"}</option>
                                ))}
                              </Module.Form.Select>
                              </Module.Form.Group>
                            </Module.Grid.Col>
                            <Module.Grid.Col sm={7} md={7}>
                              <Module.Form.Group className="mb-0">
                              <Module.Form.Input
                                  type="text"
                                  placeholder="Phone Number"
                                  name="phonenumber"
                                  value={values.phonenumber}
                                  error={errors.phonenumber}
                                  onChange={handleChange}
                                />
                              </Module.Form.Group>
                            </Module.Grid.Col>
                          </Module.Grid.Row>
                        </Module.Form.Group>
                      </Module.Grid.Col>
                      <Module.Grid.Col sm={4} md={4} className="add-full-wd-40">
                        <Module.Form.Group>
                          <Module.Form.Label>Relationship <span class="required">*</span></Module.Form.Label>
                          <Module.Form.Select
                            name="reletionship"
                            value={values.reletionship}
                            error={errors.reletionship}
                            onChange={handleChange}
                          >
                            <option value="">Select Relationship</option>
                            <option value="Father">Father</option>
                            <option value="Mother">Mother</option>
                            <option value="Spouse">Spouse</option>
                            <option value="Son">Son</option>
                            <option value="Daughter">Daughter</option>
                            <option value="Sibling">Sibling</option>
                            <option value="Other">Other</option>
                          </Module.Form.Select>
                          <Module.Form.Input
                            type="text"
                            placeholder="Relationship"
                            name="otherreletionship"
                            className={(values.reletionship == "Other") ? 'mt-3' : 'd-none' + ' w-50'}
                            value={values.otherreletionship}
                            error={errors.otherreletionship}
                            onChange={handleChange}
                          />
                        </Module.Form.Group>
                      </Module.Grid.Col>
                      <Module.Grid.Col sm={4} md={4} className={(!values.minordisabled === false) ? '' : 'd-none'}>
                        <Module.Form.Group>
                          <Module.Form.Label>Trustee Name</Module.Form.Label>
                          <Module.Form.Input
                            type="text"
                            placeholder="Trustee Name"
                            name="trustee"
                            disabled={!values.minordisabled}
                            value={values.trustee}
                            error={errors.trustee}
                            onChange={handleChange}
                          />
                        </Module.Form.Group>
                      </Module.Grid.Col>
                    </Module.Grid.Row>
                  </Module.Card.Body>
                  <Module.Card.Footer className="text-right">
                    <Module.Button
                      type="button"
                      color="primary"
                      className="mr-4 btn-secondary"
                      onClick={e => closeModal() }>
                        Back
                    </Module.Button>
                    <Module.Button
                      type="submit"
                      color="primary"
                      disabled={!ButtonStatus}
                    >
                      {(Buttonaction == 'add') ? 'Add' : 'Update'}
                    </Module.Button>
                  </Module.Card.Footer>
                </Module.Form>
              )}
            />
      </Module.Container>
    )
  )
}
  
  export default Family;
  
