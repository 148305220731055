import { BehaviorSubject } from "rxjs";
import AppConsts from "./AppConsts";
import AuthenticationModel from "./dto/authenticationModel";
import AuthenticationResultModel from "./dto/authenticationResultModel";
import {
  appPath,
  setToken,
  setCookieValue,
  AuthorizationToken,
  CheckAuth,
  deleteCookie,
  errorresult,
} from "./abp";
import { Client } from "./apireq";
import { Redirect } from "react-router-dom";

var auth = AuthenticationModel;
var authresult = AuthenticationResultModel;

const ar = new Client(process.env.REACT_APP_REMOTE_SERVICE_BASE_URL, window);

const currentUserSubject = CheckAuth();

export const authenticationService = {
  login,
  logout,
  routecheckauth,
  get checkRole() {return GetRole() } ,
  getCurrentLoginInformationsfn,
  currentUser: currentUserSubject,
};
export async function CheckSubscription() {
  return await ar
    .getCurrentSubscribeStatus()
    .then(result => {
      if (result) {
        return true;
      } else {
        return false;
      }
    })
    .catch(error => {
      return false;
    });
}
function login(username, password, GUID, rememberme) {
  auth.userNameOrEmailAddress = username;
  auth.password = password;
  auth.GUID = GUID;
  auth.rememberClient = rememberme;

  //return ar.authenticate(auth);
  return ar
    .authenticate(auth)
    .then(result => {
      //localStorage.setItem('currentUser', JSON.stringify(result));
      // currentUserSubject.next(result);

      authresult.accessToken = result.accessToken;
      authresult.encryptedAccessToken = result.encryptedAccessToken;
      authresult.expireInSeconds = result.expireInSeconds;
      authresult.userId = result.userId;

      console.log(rememberme);
      console.log(authresult.expireInSeconds);
      const curdatetime = new Date();
      //var tokenExpireDate = rememberme
        //? new Date(new Date().getTime() + authresult.expireInSeconds)
        //: undefined;
      var tokenExpireDate = rememberme
          ? curdatetime.setSeconds(curdatetime.getSeconds() + authresult.expireInSeconds)
          : undefined;
      setToken(authresult.accessToken, tokenExpireDate);
      localStorage.setItem("UserType",result.userRole[0])
      setCookieValue(
        AppConsts.authorization.encrptedAuthTokenName,
        authresult.encryptedAccessToken,
        tokenExpireDate,
        appPath
      );
      return result;
    }).catch(error => {
      throw errorresult(error);
    });
}
function routecheckauth() {
  const auth = CheckAuth();
  return auth;
}
function GetRole() {
  return localStorage.getItem("UserType") !=null || undefined ?  localStorage.getItem("UserType") : "Users";
}
function getCurrentLoginInformationsfn() {
  const result = ar.getCurrentLoginInformations(AuthorizationToken)
    .then(result => {
      return result;
    })
    .catch(error => {
      if (error) {
        return error;
      }
    });
  return result;
}
function logout() {
  // remove user from local storage to log user out
  //localStorage.removeItem('currentUser');
  //currentUserSubject.next(null);
  deleteCookie("enc_auth_token");
  deleteCookie("Abp.AuthToken");
  localStorage.clear();
  sessionStorage.clear();
}
