import * as React from "react";
import {useState,useEffect} from "react";
import { Redirect } from "react-router-dom";
import * as Module from './Module.react';

const { forwardRef, useRef, useImperativeHandle } = React;

function replaceAll(string, search, replace) {
  return string.split(search).join(replace);
}

const CreateCountry = forwardRef((props,ref) =>
{
    const [count, setCount] = useState(0);
    const [state, setstate] = useState({
        id:0,
        loading: true,
        CountryName: null,
        CountryCode: null,
        Code: null,
        CurrencyId: null,
        Priority: null,
        isdataavailable: false,        
        URL: null,        
        isbuttondisable: false,
      });

  const [curencyDataApi, setCurencyDataApi] = useState([])

   useEffect(()=>
  {
    Module.ar.getCurrencyData().then(
      result=>{
        setCurencyDataApi(result)
      },error=>{
         console.log(error);
      })

    Module.ar.getCountryDetailsById(props.match.params.id).then(
    result=>{
         setstate(
             { ...state,
                isdataavailable: true,
                CountryName: result[0].countryName,
                CountryCode: result[0].countryCode,
                id:result[0].id,
                Code: result[0].code ? replaceAll(result[0].code, "+",""): "",
                CurrencyId: result[0].currencyId
                
             })
    },error=>{

    console.log(error);
    })

  },[])
      return (
        <Module.SiteWrapper>
           <Module.Container>
         <Module.Formik

        enableReinitialize
        initialValues={{
          CountryName: (state.isdataavailable) ? state.CountryName : "",
          CountryCode: (state.isdataavailable) ? state.CountryCode : "",
          Code: (state.isdataavailable) ? state.Code : "",
          CurrencyId: (state.isdataavailable) ? state.CurrencyId : ""
        }}
         onSubmit={(
           { CountryName, CountryCode, Code, CurrencyId, Priority },
           { setStatus, setSubmitting }
         ) => {
            var Updatecountry = {};
            Updatecountry.Id = props.match.params.id;
            Updatecountry.countryName = CountryName;
            Updatecountry.countryCode = CountryCode;
            Updatecountry.code = "+" + (CountryName.toLowerCase() == "canada" ? ("~" + Code) : Code);
            Updatecountry.currencyId = CurrencyId;
            //Updatecountry.priority = 4;

           Module.ar.updateCountryDetails(Updatecountry).then(
             result => {
               Module.SnackbarUtils.success("Country Update successfully !!"); 
               props.history.push("/CountryManagement");
             },
             error => {
             Module.errorresult(error);
             }
           );
         }}
         validate={values => {
           let errors = {};
          
           if (!values.CountryName) {
             errors.CountryName = "Country Name Required";
           }
           if (!values.CountryCode) {
             errors.CountryCode = "Country Code Required";
           }
           if (!values.Code) {
             errors.Code = "Phone Code Required";
           }
           if (!values.CurrencyId) {
             errors.CurrencyId = "Select Currency Name";
           }
           return errors;
         }}
         validateOnChange={false}
         validateOnBlur={false}
         render={({
           values,
           errors,
           touched,
           handleChange,
           handleBlur,
           handleSubmit,
           isSubmitting,
         }) => (
             <Module.Form  onSubmit={handleSubmit}>
               <Module.Card.Body className="card p-6">
                 <Module.Card.Title RootComponent="div">Edit Country</Module.Card.Title>
                 <p>Enter your details below to create country</p>
                 <Module.Form.Group>
                   <Module.Form.Label>Country Name <span className="required">*</span></Module.Form.Label>
                   <Module.Form.Input
                     name="CountryName"
                     placeholder="CountryName"
                     value={values.CountryName}
                     error={errors.CountryName}
                     onChange={handleChange}
                     onBlur={handleBlur}
                   />
                 </Module.Form.Group>
                 <Module.Form.Group>
                   <Module.Form.Label>Country Code <span className="required">*</span></Module.Form.Label>
                   <Module.Form.Input
                     name="CountryCode"
                     placeholder="Country Code"
                     value={values.CountryCode}
                     error={errors.CountryCode}
                     onChange={handleChange}
                     onBlur={handleBlur}
                   />
                 </Module.Form.Group>
                 <Module.Form.Group>
                   <Module.Form.Label>Phone Code <span className="required">*</span><span >  (Enter number only)</span></Module.Form.Label>
                   <Module.Form.Input
                     name="Code"
                     placeholder="Code"
                     value={values.Code}
                     error={errors.Code}
                     onChange={handleChange}
                     onBlur={handleBlur}
                   />
                 </Module.Form.Group>
                 <Module.Form.Group>
                   <Module.Form.Label>Currency Name <span className="required">*</span></Module.Form.Label>
                   <Module.Form.Select
                     name="CurrencyId"
                     value={values.CurrencyId}
                     error={errors.CurrencyId}
                     onChange={handleChange}
                   >
                     <option value="">Select Currency Name</option>
                     {curencyDataApi.length && curencyDataApi.map((option) => (
                       <option value={option.id}>{option.currencyName}</option>
                     ))}
                   </Module.Form.Select>
                 </Module.Form.Group>

             </Module.Card.Body>
              <Module.Card.Footer className="text-right mt-25">
 
                 <Module.Button type="button" color="btn btn-primary mr-4 btn-secondary" onClick={e => props.history.push("/CountryManagement") }>Back</Module.Button>
                 <Module.Button type="submit" color="primary">
                   Update
                 </Module.Button>
               
               </Module.Card.Footer>
           </Module.Form>
         )}
       />
        </Module.Container>
       </Module.SiteWrapper>
     )

});

export default CreateCountry;
