import * as React from "react";
import {useState,useEffect} from "react";
//import { storiesOf } from '@storybook/react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Client, SubscriptionOutput, contactNumber } from "../../_services/apireq";
import Modal from 'react-bootstrap/Modal';
import {
  Page,
  Avatar,
  Icon,
  Grid,
  Card,
  Text,
  Table,
  Alert,
  Progress,
  colors,
  Dropdown,
  Button,
  StampCard,
  StatsCard,
  ProgressCard,
  Badge,
  Form
} from "../../tabler-react/index";

const { forwardRef, useRef, useImperativeHandle } = React;

const AdminChangePassword = forwardRef((prors,ref) =>
{
const [show, setShow] = useState(false);
const [data,setdata] = useState({
    NewPassword:"",
    ConfirmPassword:""
});

const [MainState,setMainState] = useState({
    fields: {},
    errors: {}
});        

const handleClose = () =>
{ 
     setShow(false)
};

const UpdatePassword = () =>
{
    console.log(data);
}

const handleShow = () => setShow(true);

const handleChange = (evt, field)  => 
{
   setdata({ ...data, [field] : evt.target.value });
}

 useImperativeHandle(ref, () => ({
  
 handleClose()
 {
     setShow(false)
  },
  handleShow()
  {
      setShow(true)
  }
  }));
  

    return (
        <>

        <Modal show={show} onHide={handleClose} animation={false}>
            <Modal.Header closeButton>
            <Modal.Title>Change Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Grid.Row>
              <Grid.Col sm={12} md={12}>
                  <Form.Group>
                     <Form.Label>New Password</Form.Label>
                       <Form.Input type="text" placeholder="New Password" name="NewPassword"
                       onChange={event =>
                                handleChange(event, "NewPassword")
                              } />

                               
                  </Form.Group>
              </Grid.Col>

               <Grid.Col sm={12} md={12}>
                  <Form.Group>
                     <Form.Label>Confirm Password</Form.Label>
                       <Form.Input type="text" placeholder="Confirm Password" name="ConfirmPassword"
                       onChange={event =>
                                handleChange(event, "ConfirmPassword")
                              }
                               />

                                
                </Form.Group>
              </Grid.Col>
              </Grid.Row>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
            <Button variant="primary" onClick={UpdatePassword}>
                Save Changes
            </Button>
            </Modal.Footer>
        </Modal>
        </>
    )

});

export default AdminChangePassword;