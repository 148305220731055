// @flow

import * as React from "react";

import Form from "../components/Form";
import Card from "../components/Card";
import Button from "../components/Button";

type Props = {|
  +action?: string,
  +children?: React.Node,
  +method?: string,
  +title: string,
  +buttonText: string,
  +onSubmit?: Function,
|};

function FormCard({
  children,
  action,
  method,
  onSubmit,
  title,
  buttonText,
  forgotbuttonText
}: Props): React.Node {
  return (
    <Form className="card" onSubmit={onSubmit} action={action} method={method}>
      <Card.Body className="p-6">
        <Card.Title RootComponent="div">{title}</Card.Title>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
        {children}
        <Form.Footer>
        <a href="/app/forgot-password" color="primary" className="mb25">
            {forgotbuttonText}
          </a>
          <Button type="submit" color="primary" block={true}>
            {buttonText}
          </Button>
          <div className="link-box">
            <span> Don't have an account?</span>
          <a href="/app/register" color="primary" className="register-link">
          Sign Up
          </a>
          </div>
        </Form.Footer>
      </Card.Body>
    </Form>
  );
}

export default FormCard;
