// @flow

import * as React from "react";
import {useState,useEffect} from "react";
import {Link} from "react-router-dom";
import * as Module from './Module.react'

const UsersSubscriptionDetails = (props) => {
    const [buttonstate, setbuttonstate] = useState(false);
    const [subscriptionsdata, setsubscriptionsdata] = useState([]);
    const [stripedata, setstripedata] = useState([]);
    const [subdataloading, setsubdataloading] = useState(true);
    const [stripeloading, setstripeloading] = useState(true);
    const [UserDetails, setUserDetails] = React.useState();

    useEffect(()=>{
        if(props.match.params.id)
        Module.ar.getUsersSubscription(props.match.params.id).then(res=>{
            setsubscriptionsdata(res);
            setsubdataloading(false);
        });
        Module.ar.retriveUserSubscriptionData(props.match.params.id).then(res=>{
            setstripedata(res);
            setstripeloading(false);
        });
        Module.ar.getUserProfile(props.match.params.userid).then(
          result=>{ 
              setUserDetails({
                name:result.firstname+" "+result.lastname,
                email: result.emailaddress,
              });
          },error=>{
        });
    },[])

    const renderSwitch = (brand) => {
        switch(brand.toLowerCase()) {
          case 'amex':
            return process.env.REACT_APP_PRE_BIND_DIRECTION + '/demo/cards/card_amex.png';
          case 'cartes_bancaires':
            return process.env.REACT_APP_PRE_BIND_DIRECTION + '/demo/cards/card_cartes_bancaires.png';
          case 'diners_club':
            return process.env.REACT_APP_PRE_BIND_DIRECTION + '/demo/cards/card_diners_club.png';
          case 'discover':
            return process.env.REACT_APP_PRE_BIND_DIRECTION + '/demo/cards/card_discover.png';
          case 'jcb':
            return process.env.REACT_APP_PRE_BIND_DIRECTION + '/demo/cards/card_jcb.png';
          case 'mastercard':
            return process.env.REACT_APP_PRE_BIND_DIRECTION + '/demo/cards/card_mastercard.png';
          case 'visa':
            return process.env.REACT_APP_PRE_BIND_DIRECTION + '/demo/cards/card_visa.png';
          case 'unionpay':
            return process.env.REACT_APP_PRE_BIND_DIRECTION + '/demo/cards/card_unionpay.png';
          default:
            return '';
        }  
    }
    const downloadinvoice = (id,name) => {
        let url = process.env.REACT_APP_REMOTE_SERVICE_BASE_URL + '/api/services/app/Subscription/DownloadInvoice?InvoiceNumber='+ id;
        fetch(url,
            { 
              method: "GET",
              headers: { "Content-Type": "application/json",
            }
        }).then(response => response.blob()).then(response => 
          {
            var url = window.URL.createObjectURL(response);
            var a = document.createElement('a');
            a.href = url;
            a.download = name + "_" + id + ".pdf";
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();
          });
    }
    return (
        <Module.SiteWrapper>
          <div className="my-3 my-md-5">
              <Module.Container className="mysubscription-pages">
                <Module.Form className="card">
                  <Module.Grid.Row cards={true}>
                    <Module.Grid.Col width={6}>
                      <Module.Card.Title>Subscription Plan Details</Module.Card.Title>
                      {(UserDetails) ? (
                      <><div className="font-20"><b>Name:</b> {UserDetails.name}</div>
                      <div className="font-20 mb-2"><b>Email:</b> {UserDetails.email}</div></>
                      ) : null}
                    </Module.Grid.Col>
                    <Module.Grid.Col width={6}>
                      <Link to={(props.match.params.action) ? "/UsersSubscriptions" : "/UsersManagement"}>
                        <Module.Button
                            type="button"
                            color="primary"
                            className="btn-secondary float-right"
                        >Back
                        </Module.Button>
                      </Link>
                    </Module.Grid.Col>
                  </Module.Grid.Row>
                  <Module.Grid.Row>
                    <Module.Grid.Col lg={12} className="mysubscription">
                      <div className="white-box subscription-paln">
                        {(subdataloading || stripeloading) ? (
                          <div className="progress">
                            <div className="progress-bar progress-bar-indeterminate bg-green" />
                          </div>
                        ) : (
                        <div>
                        <div className="subscription-top-text mt-5 pt-5">
                            <div className="float-left">
                                <h3 class="h3 mt-0 mb-2">{(stripedata.products && subscriptionsdata && subscriptionsdata.account_status == 'ACTIVE') ? stripedata.products.find(item => item.id === subscriptionsdata.product_id).name : stripedata.products[0].name}</h3>
                                <span className="h5">{(stripedata.products && subscriptionsdata && subscriptionsdata.account_status == 'ACTIVE') ? stripedata.products.find(item => item.id === subscriptionsdata.product_id).description : stripedata.products[0].description}</span>
                            </div>
                            <div className="float-right">
                              {((subscriptionsdata.itemcurrency != null) ? <div className="text-right h3"><b>{subscriptionsdata.itemcurrency + ' ' + ((subscriptionsdata.itemunit_amount != 0) ? subscriptionsdata.itemunit_amount + '/' + subscriptionsdata.itemrecurring : null)}</b></div>: null)}
                              {(subscriptionsdata.couponid) ? <div className="text-right h5">Discount: <b>{subscriptionsdata.couponid + ' - ' + ((subscriptionsdata.couponamount_off != null) ? ((subscriptionsdata.itemcurrency) ? subscriptionsdata.itemcurrency : '-') + ' ' +  subscriptionsdata.couponamount_off + ' off' : subscriptionsdata.couponpercentage_off + '% off')}</b></div> : null}
                              {((subscriptionsdata.itemcurrency != null && subscriptionsdata.itemunit_discountamount != null) ? <div className="text-right h3">Amount: <b>{subscriptionsdata.itemcurrency + ' ' + ((subscriptionsdata.itemunit_discountamount != 0) ? subscriptionsdata.itemunit_discountamount + '/' + subscriptionsdata.itemrecurring : ((subscriptionsdata.itemunit_amount != 0) ? subscriptionsdata.itemunit_amount + '/' + subscriptionsdata.itemrecurring : null))}</b></div>: null)}
                            </div>
                          </div>
                          <div className="subscription-top-text mt-5 pt-5">
                            <div className="left-box">
                            <span className="status h3">Status: <b className={status}>{(subscriptionsdata.account_status) ? subscriptionsdata.account_status: 'Trial'}</b></span>
                            </div>
                            <div className="right-box">
                              {(subscriptionsdata.subscriptionstated && subscriptionsdata.account_status == 'ACTIVE') ? <span className="price-bold h3">Created: <b>{subscriptionsdata.subscriptionstated}</b></span> : null}
                            </div>
                          </div>
                          <div className="subscription-top-text mt-5 pt-5">
                            <div className="subscription-cn-text left-box">
                              {(subscriptionsdata.currentsubscriptionstated && subscriptionsdata.currentsubscriptionend && subscriptionsdata.account_status == 'ACTIVE') ? <span className="h3">Current Period: {subscriptionsdata.currentsubscriptionstated + ' to ' + subscriptionsdata.currentsubscriptionend}</span> : null}
                            </div>
                          </div>
                          </div>
                          )}
                        </div>
                    </Module.Grid.Col>                    
                  </Module.Grid.Row>
                </Module.Form>
                <Module.Grid.Row>
                  <Module.Grid.Col lg={12}>
                    <div className="white-box payment-methoad">
                      <h3 class="card-title">Payment Method</h3>
                      {stripeloading ? 
                        <div className="progress">
                          <div className="progress-bar progress-bar-indeterminate bg-green" />
                        </div>
                       : 
                       <div>
                          <Module.Grid.Row>
                            <Module.Grid.Col lg={5}>
                              <div className="payment-top-text mt-2">
                                <h6 class="h6 mt-0 mb-2">Credit Card</h6>
                              </div>
                            </Module.Grid.Col>
                            <Module.Grid.Col lg={4}>
                              <div className="payment-cn-text">
                                <img src={(stripedata.cust_paymentmethod) ? renderSwitch(stripedata.cust_paymentmethod.brand) : ''} className={(stripedata.cust_paymentmethod) ? renderSwitch(stripedata.cust_paymentmethod.brand) != '' ? 'paymenttypes' : 'd-none' : ''} />
                                {(stripedata.cust_paymentmethod) ? <input type="text" className="paymentcard" value={stripedata.cust_paymentmethod.cardnumber} /> : <span>You don't have any card to add<br/><b>Click below Add payment Card</b></span>}
                                <span className="paymentcardname">{(stripedata.cust_paymentmethod) ? stripedata.cust_paymentmethod.nameocard : null}</span>
                              </div>
                            </Module.Grid.Col>
                            <Module.Grid.Col lg={2}>
                                {(stripedata.cust_paymentmethod) ? <div className="payment-exp">Exp: <b>{stripedata.cust_paymentmethod.exp_month + '/' + stripedata.cust_paymentmethod.exp_year}</b></div> : null }
                            </Module.Grid.Col>
                          </Module.Grid.Row>
                        </div>
                      }
                    </div>
                  </Module.Grid.Col>
                  <Module.Grid.Col lg={12} className="mt-55">
                    <div className="white-box invoices">
                      <h3 class="card-title">invoices</h3>
                      {(!stripedata.invoicelist) ?
                        <div className="progress">
                          <div className="progress-bar progress-bar-indeterminate bg-green" />
                        </div>
                      : 
                      <form className="invoice-table">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th class="wd-20">Date</th>
                              <th class="wd-20">Plan Name</th>
                              <th class="wd-20">Pricing</th>
                              <th class="wd-20">Status</th>
                              <th class="wd-20">Download Invoice</th>
                            </tr>
                          </thead>
                          <tbody>
                          {stripedata.invoicelist.map((item, i) => (
                            <tr>
                              <td data-label="Date">{item.date}</td>
                              <td data-label="Plan Name">{item.planname}</td>
                              <td data-label="Pricing" className="text-uppercase">{item.pricing}</td>
                              <td data-label="Status" className="text-uppercase"><span class="resend-brn">{item.invoicestatus}</span></td>
                              <td data-label="Download Invoice"><a className="invoicedownload-img" onClick={(event) => downloadinvoice(item.id,item.planname)}> </a></td>
                            </tr>
                          ))}
                          </tbody>
                        </table>
                      </div>
                      </form>
                      }
                    </div>
                  </Module.Grid.Col>
                </Module.Grid.Row>
              </Module.Container>
          </div>
        </Module.SiteWrapper>
      );
}

export default UsersSubscriptionDetails;
