import * as React from "react";
import {useState,useEffect} from "react";
import { Client, SubscriptionOutput, contactNumber } from "../../_services/apireq";
import Modal from 'react-bootstrap/Modal';
import {
  Page,
  Avatar,
  Icon,
  Grid,
  Card,
  Text,
  Table,
  Alert,
  Progress,
  colors,
  Dropdown,
  Button,
  StampCard,
  StatsCard,
  ProgressCard,
  Badge,
  Form
} from "../../tabler-react/index";

const { forwardRef, useRef, useImperativeHandle } = React;

const ViewNominees = forwardRef((prors,ref) =>
{

const [show, setShow] = useState(false);
const handleClose = () =>
{ 
     setShow(false)
};


const handleShow = () => setShow(true);

 useImperativeHandle(ref, () => ({
  
 handleClose()
 {
     setShow(false)
  },
  handleShow()
  {
      setShow(true)
  }
  }));
  

    return (
        <>

        <Modal show={show} onHide={handleClose} animation={false}>
            <Modal.Header closeButton>
            <Modal.Title>Alert</Modal.Title>
            </Modal.Header>
            <Modal.Body>
           ViewNominees
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>
        </>
    )

});

export default ViewNominees;