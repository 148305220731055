import React from "react";
import {useState,useEffect} from "react";
import { Redirect } from "react-router-dom";
import { Formik } from "formik";
import OuterWrapper from "../../OuterWrapper.react";
import { Grid,Button,Form,Table } from "../../tabler-react/index";
import {
  Client,
  SubscriptionOutput,
  SubscriptionInput,
  Couponasyncinput,
} from "../apireq";
import { CheckAuth } from "../abp";

import { injectStripe } from "react-stripe-elements";

import SnackbarUtils from '../SnackbarUtils';
import { withRouter } from "react-router";
import { Elements,CardNumberElement,CardExpiryElement,CardCVCElement } from "react-stripe-elements";
import * as Module from '../../pages/Modules.react';

const CheckoutForm = (props) => {
  var subinput = SubscriptionInput;
  var couponinput = Couponasyncinput;
  const [CheckoutFormData, setCheckoutFormData] = React.useState({
    selectplan: null,
    coupon: null,
    nameoncard: null,
    couponresult: "",
    couponrestogle: " d-none",
    coupontxttogle: "",
    couponresultdata: null,
  });
  const [Loading, setLoading] = React.useState(true);
  const [data, setdata] = React.useState(SubscriptionOutput);
  const [dataall, setdataall] = React.useState(SubscriptionOutput);
  const [substatusresult, setsubstatusresult] = React.useState(null);
  const [buttonstatus, setbuttonstatus] = React.useState(true);
  const [couponloaading, setcouponloaading] = React.useState("");
  const [carddetailsvalidate, setcarddetailsvalidate] = React.useState({
    carderror: null,
    experror: null,
    cvcerror: null,
  });

  const LoadData = () => {
    Module.ar.getSubscriptionPackage().then(result => {
      setdata(result);
      Module.ar.getAllSubscriptionPackage().then(result2 => {
        setdataall(result2);
        Module.ar.getCurrentSubscribeStatus().then(result3 => {
          localStorage.setItem('SUBStatus', (result3) ? '00b46157' : 'c286de5aa9ff');
          setsubstatusresult(result3);
          setLoading(false);
        });
      });
    });
  }
  const paynextstep = (values, actions) => {
    setbuttonstatus(false);
    subinput = {
      subscription_Plan: values.selectplan,
      coupon: (CheckoutFormData.couponresultdata != null) ? values.coupon : null,
    };
    Module.ar.createCustomerSubscription(subinput).then(
      result => {
        SnackbarUtils.success("You have subscribed successfully.");
        localStorage.setItem('SUBStatus', '00b46157');
        props.PaymentSuccess();
      },
      error => {
        SnackbarUtils.error("Failed Pay Subscription.");
        props.Paymentfailed();
      }
    );
  }
  const canclesubscription = () => {
    props.Paymentfailed();
  }
  const PriceRecurring = (recurring) => {
    if (recurring) {
      return " / " + recurring.interval;
    }
  }
  const elementFontSize = window.innerWidth < 450 ? '14px' : '18px';
  const createOptions = (fontSize, padding) => {
    return {
      style: {
        base: {
          fontSize,
          color: '#424770',
          letterSpacing: '0.025em',
          fontFamily: 'Source Code Pro, monospace',
          '::placeholder': {
            color: '#aab7c4',
          },
          display:'block',
          padding,
        },
        invalid: {
          color: '#9e2146',
        },
      },
    };
  };
  const handleChangecustom = (e) => {
    if(e.elementType == "cardNumber") {
      if(e.error != null && e.error.message != null && e.error.message != "") {
        setcarddetailsvalidate({
          ...carddetailsvalidate,
          carderror: e.error.message
        });
      } else {
        setcarddetailsvalidate({
          ...carddetailsvalidate,
          carderror: null
        });
      }
    }
    else if(e.elementType == "cardExpiry") {
      if(e.error != null && e.error.message != null && e.error.message != "") {
        setcarddetailsvalidate({
          ...carddetailsvalidate,
          experror: e.error.message
        });
      } else {
        setcarddetailsvalidate({
          ...carddetailsvalidate,
          experror: null
        });
      }
    }
    else if(e.elementType == "cardCvc") {
      if(e.error != null && e.error.message != null && e.error.message != "") {
        setcarddetailsvalidate({
          ...carddetailsvalidate,
          cvcerror: e.error.message
        });
      } else {
        setcarddetailsvalidate({
          ...carddetailsvalidate,
          cvcerror: null
        });
      }
    }
    else {
      setcarddetailsvalidate({
        ...carddetailsvalidate,
        carderror: null,
        experror: null,
        cvcerror: null
      });
    }
  }

  useEffect(()=>
  {
    if (!CheckAuth()) return <Redirect to="/login" />;
    if (substatusresult) {
      return <Redirect to="/" />;
    }
    LoadData();
  },[])
  return (
    <div className="inner-row mb-pd-w0">
    <div className="text-center mb-6">
      <Formik
      initialValues={{
        selectplan: "",
        nameoncard: "",
        coupon: "",
      }}
      validate={values => {
        let errors = {};
        if (!values.selectplan) {
          errors.selectplan = "Select Plan";
        } else {
          setcouponloaading(" loadinggif");
          if(values.coupon) {
            couponinput = {
              couponid: values.coupon,
              subscriptionid: values.selectplan,
            };
          } else {
            couponinput = {
              subscriptionid: values.selectplan,
            };
          }
          Module.ar
            .couponsync(couponinput)
            .then(result => {
              setcouponloaading("");
              if (result.issuccess == false) {
                setCheckoutFormData({
                  ...CheckoutFormData,
                  couponresultdata: null,
                  couponresult: result.error,
                  couponrestogle: " invalid-feedback",
                  coupontxttogle: " is-invalid"
                });
              } else {
                setCheckoutFormData({
                  ...CheckoutFormData,
                  couponresultdata: result,
                  couponresult: "",
                  couponrestogle: " d-none",
                  coupontxttogle: "",
                });
              }
            })
            .catch(error => {});
        }
        if (!values.nameoncard) {
          errors.nameoncard = "Name on Card Required";
        }
        if (values.coupon != "") {
          if (!values.selectplan) {
            errors.coupon = "Select Plan";
          } else {
          setcouponloaading(" loadinggif");
          couponinput = {
            couponid: values.coupon,
            subscriptionid: values.selectplan,
          };
          Module.ar
            .couponsync(couponinput)
            .then(result => {
              setcouponloaading("");
              if (result.issuccess == false) {
                setCheckoutFormData({
                  ...CheckoutFormData,
                  couponresultdata: null,
                  couponresult: result.error,
                  couponrestogle: " invalid-feedback",
                  coupontxttogle: " is-invalid"
                });
              } else {
                setCheckoutFormData({
                  ...CheckoutFormData,
                  couponresultdata: result,
                  couponresult: "",
                  couponrestogle: " d-none",
                  coupontxttogle: "",
                });
              }
            })
            .catch(error => {});
          }
        }
        return errors;
      }}
      onSubmit={paynextstep}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        handleFocus,
        handleReady,
        isSubmitting,
      }) => (
        <Form className="card" onSubmit={handleSubmit}>
          <div className="card-body p-6">
            {Loading || !data ? (
              <div className="progress">
                <div className="progress-bar progress-bar-indeterminate bg-green" />
              </div>
            ) : (
              <div>
                <Form.Group>
                  <Form.Label>Currency Plan</Form.Label>
                  <Form.Select
                    name="selectplan"
                    value={values.selectplan}
                    error={errors.selectplan}
                    onChange={handleChange}
                    onBlur={handleChange}
                  >
                    <option value="">Select Plan</option>
                      {dataall.map((item, i) => (
                        <option key={i} value={item.id}>
                          {item.nickname +
                            " - " +
                            item.unitAmount +
                            " " +
                            item.currency.toUpperCase() +
                            PriceRecurring(item.recurring)}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Enter Coupon</Form.Label>
                  <Form.Input
                    name="coupon"
                    className={"form-control" + couponloaading + CheckoutFormData.coupontxttogle}
                    placeholder="Enter Coupon"
                    value={values.coupon}
                    error={errors.coupon}
                    onChange={handleChange}
                    onBlur={handleChange}
                  />
                  <div className={CheckoutFormData.couponrestogle}>
                    {CheckoutFormData.couponresult}
                  </div>
                </Form.Group>
                {CheckoutFormData.couponresultdata ? (
                  <div className="form-group">
                    <Table className="coupontable">
                      <Table.Body>
                        <Table.Row className="text-right">
                          <Table.Col>
                            <b className="text-success">
                              Subscription Plan:{" "}
                            </b>
                            <b>
                              {
                                CheckoutFormData.couponresultdata
                                  .subscriptionprice
                              }{" "}
                              {
                                CheckoutFormData.couponresultdata
                                  .subscriptioncurrecny
                              }{" "}
                              /{" "}
                              {
                                CheckoutFormData.couponresultdata
                                  .subscriptionduration
                              }
                            </b>
                          </Table.Col>
                        </Table.Row>
                        {CheckoutFormData.couponresultdata.couponapplieddesc ? 
                        (
                          <Table.Row className="text-right">
                            <Table.Col>
                              <b className="text-success">Coupon Applied: </b>{" "}
                              {CheckoutFormData.couponresultdata.couponapplieddesc}
                            </Table.Col>
                          </Table.Row>
                        ) : null
                        }
                        {CheckoutFormData.couponresultdata.discountappliedprice != 0 ?
                        (
                          <Table.Row className="text-right">
                            <Table.Col>
                              <b className="text-success">Plan Price: </b>
                              <b>
                                {
                                  CheckoutFormData.couponresultdata
                                    .discountappliedprice
                                }{" "}
                                {
                                  CheckoutFormData.couponresultdata
                                    .subscriptioncurrecny
                                }{" "}
                                /{" "}
                                {
                                  CheckoutFormData.couponresultdata
                                    .subscriptionduration
                                }
                              </b>
                            </Table.Col>
                          </Table.Row>
                        ) : null
                        }
                      </Table.Body>
                    </Table>
                  </div>
                ) : (
                  ""
                )}
                <Form.Footer>
                  <Button type="submit" className="btn-block d-inline" color="primary" disabled={!buttonstatus}>
                    Pay
                  </Button>
                  <div className="link-box d-inline ml-5">
                    <a className="cancel-link" disabled={!buttonstatus} onClick={canclesubscription}>Cancel</a>
                  </div>
                </Form.Footer>
              </div>
            )}
          </div>
        </Form>
      )}
    />
    </div>
    </div>
  );
}

export default withRouter(injectStripe(CheckoutForm));
