import React from "react";

class LoadingPage extends React.Component {
    render() {
        return (
            <div className="progress">
              <div className="progress-bar progress-bar-indeterminate bg-green" />
            </div>
        );
    }
}
export default LoadingPage;