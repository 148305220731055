import * as React from "react";
import {useState,useEffect} from "react";
const { forwardRef, useRef, useImperativeHandle } = React;
import * as Module from './Module.react';

var Couponinput = Module.CouponInput;
var Coupons = Module.Coupons;

const Coupon = (props) => {
    const [buttonstate, setbuttonstate] = useState(false);
    const [actionstate, setactionstate] = useState('Add');
    const [currencydata, setcurrencydata] = useState([]);
    const [coupondata, setcoupondata] = useState(Coupons);

    const handleSubmitAction = (values, actions) => {
        setbuttonstate(true);
        Couponinput = {
            CouponId : (props.couponid) ? props.couponid : null,
            setcouponid : values.couponidval,
            CouponName : values.couponname,
            Coupontype : values.coupontype,
            CouponPercentageOff : values.percentageoff,
            CouponAmountOff : (values.amountoff) ? values.amountoff : 0,
            CouponAmountOffCurrency : values.amountoffcurrency,
            ProductId : process.env.REACT_APP_PRODUCT_ID,
            Duration : values.duration,
            Active : values.isactive,
            durationperuser: (values.duration == "repeating") ? values.durationperuser : 0,
            maxredemptions: (values.maxredemptions != 0) ? values.maxredemptions : 0,
        };
        Module.ar.couponManage(Couponinput).then(res=>{
            if(res && res == "success") {
                Module.SnackbarUtils.success((actionstate === 'Add') ? 'Coupon Added Successfully' : 'Coupon Updated Successfully');
                props.ReloadTable();
            } else {
                Module.SnackbarUtils.error((actionstate === 'Add') ? 'Failed to Added Coupon.' : 'Failed to Updated Coupon.');
                props.ReloadTable();
            }
        },
        error => {
            setbuttonstate(false);
            Module.SnackbarUtils.error((actionstate === 'Add') ? 'Failed to Added Coupon.' : 'Failed to Updated Coupon.');
        });
    }
    const changecoupontolower  = (value) => {
        console.log(value);
    }
    const handlecouponname = (values) => {
        console.log(values);
        values.couponname = values.couponname.replace(/[^\w]/g, "");
        values.couponidval = values.couponname.toLowerCase();
    }

    useEffect(()=>{
        Module.ar.getCurrencyData().then(res=>{
            setcurrencydata(res);
        });
        if(props.couponid) {
            setactionstate('Update');
            Module.ar.getCoupon(props.couponid).then(res=>{
                console.log(res);
                setcoupondata(res);
            });
        }
    },[])
	return (
        <Module.Container>
        <Module.Modal show={true} className="ModalCustomStyle" onHide={() => props.onClickClose() } animation={false}>
        <Module.Formik
        enableReinitialize={true}
        initialValues={{
            couponidval : (coupondata) ? coupondata.couponId : "",
            couponname : (coupondata) ? coupondata.couponName : "",
            coupontype : (coupondata) ? coupondata.coupontype : "",
            percentageoff : (coupondata) ? coupondata.couponPercentageOff : "",
            amountoffcurrency : (coupondata) ? coupondata.couponAmountOffCurrency : "",
            amountoff : (coupondata) ? coupondata.couponAmountOff : "",
            duration : (coupondata) ? coupondata.duration : "",
            isactive : (coupondata) ? coupondata.active : true,
            durationperuser : (coupondata) ? coupondata.durationperuser : "",
            maxredemptions : (coupondata) ? coupondata.maxredemptions : "",
        }}
        validate={values => {
          // same as above, but feel free to move this into a class method now.
          let errors = {};
          values.couponidval = values.couponidval.toLowerCase();
          if (!values.couponidval) {
            errors.couponidval = "Coupon ID Required";
          }
          if (!values.couponname) {
            errors.couponname = "Coupon Name Required";
          }
          if (!values.coupontype) {
            errors.coupontype = "Coupon Type Required";
          }
          if(values.coupontype === "Percentage") {
            if (!values.percentageoff) {
                errors.percentageoff = "Percentage Value Required";
              }
          } else {
              if (!values.amountoffcurrency) {
                errors.amountoffcurrency = "Select Currency";
              }
              if (!values.amountoff) {
                errors.amountoff = "Enter Amount";
              }
          }
          if (!values.duration) {
            errors.duration = "Select Duration";
          }
          if (values.duration == "repeating") {
            errors.durationperuser = "Required";
          }

          return errors;
        }}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={handleSubmitAction}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
        <Module.Form onSubmit={handleSubmit}>
            {handlecouponname(values)}
            <Module.Modal.Header closeButton>
            </Module.Modal.Header>
            <Module.Modal.Body>
                <Module.Grid.Row>
                    <Module.Grid.Col sm={12} md={12}>
                        <Module.Card.Title>{(props.couponid) ? 'Edit' : 'Add'} Coupon</Module.Card.Title>
                    </Module.Grid.Col>
                </Module.Grid.Row>
                <Module.Grid.Row>
                    <Module.Grid.Col sm={6} md={6}>
                        <Module.Form.Group>
                            <Module.Form.Label>Coupon ID <span class="required">*</span></Module.Form.Label>
                            <Module.Form.Input
                                type="text"
                                placeholder="Coupon ID"
                                name="couponidval"
                                value={values.couponidval}
                                error={errors.couponidval}
                                onChange={handleChange}
                                className={"text-lowercase"}
                                disabled={true}
                            />
                        </Module.Form.Group>
                    </Module.Grid.Col>
                    <Module.Grid.Col sm={6} md={6}>
                        <Module.Form.Group>
                            <Module.Form.Label>Coupon Name <span class="required">*</span></Module.Form.Label>
                            <Module.Form.Input
                                type="text"
                                placeholder="Coupon Name"
                                name="couponname"
                                value={values.couponname}
                                error={errors.couponname}
                                onChange={handleChange}
                            />
                        </Module.Form.Group>
                    </Module.Grid.Col>
                </Module.Grid.Row>
                <Module.Grid.Row>
                    <Module.Grid.Col sm={12} md={12}>
                        <Module.Form.Group>
                            <Module.Form.Label>Coupon Type <span class="required">*</span></Module.Form.Label>
                            <Module.Form.Select
                                name="coupontype"
                                value={values.coupontype}
                                error={errors.coupontype}
                                onChange={handleChange}
                                disabled={(props.couponid)}
                            >
                                <option value="">Select Coupon Type</option>
                                <option value="Percentage">Percentage Off</option>
                                <option value="Fixed Amount">Fixed Amount</option>
                            </Module.Form.Select>
                        </Module.Form.Group>
                    </Module.Grid.Col>
                </Module.Grid.Row>
                <Module.Grid.Row className={(values.coupontype == "Percentage") ? '' : 'd-none'}>
                    <Module.Grid.Col sm={6} md={6}>
                        <Module.Form.Group className="position-relative">
                            <Module.Form.Label>Percentage Off <span class="required">*</span></Module.Form.Label>
                            <Module.Form.Input
                                type="text"
                                placeholder="Percentage Off"
                                name="percentageoff"
                                value={values.percentageoff}
                                error={errors.percentageoff}
                                onChange={handleChange}
                                disabled={(props.couponid)}
                            /><span className="percentageofficon">%</span>
                        </Module.Form.Group>
                    </Module.Grid.Col>
                </Module.Grid.Row>
                <Module.Grid.Row className={(values.coupontype == "Fixed Amount") ? '' : 'd-none'}>
                    <Module.Grid.Col sm={6} md={6}>
                        <Module.Form.Group>
                            <Module.Form.Label>Amount Off Currency <span class="required">*</span></Module.Form.Label>
                            <Module.Form.Select
                                name="amountoffcurrency"
                                value={values.amountoffcurrency}
                                error={errors.amountoffcurrency}
                                onChange={handleChange}
                                disabled={(props.couponid)}
                            >
                                <option value="">Select Currency</option>
                                {currencydata.map((option) => (
                                    <option value={option.currencyCode}>{option.currencyName}</option>
                                ))}
                            </Module.Form.Select>
                        </Module.Form.Group>
                    </Module.Grid.Col>
                    <Module.Grid.Col sm={6} md={6}>
                        <Module.Form.Group>
                            <Module.Form.Label>Amount Off <span class="required">*</span></Module.Form.Label>
                            <Module.Form.Input
                                type="text"
                                placeholder="Amount Off"
                                name="amountoff"
                                value={values.amountoff}
                                error={errors.amountoff}
                                onChange={handleChange}
                                disabled={(props.couponid)}
                            />
                        </Module.Form.Group>
                    </Module.Grid.Col>
                </Module.Grid.Row>
                <Module.Grid.Row>
                    <Module.Grid.Col sm={6} md={6}>
                        <Module.Form.Group>
                            <Module.Form.Label>Duration</Module.Form.Label>
                            <Module.Form.Select
                                name="duration"
                                value={values.duration}
                                error={errors.duration}
                                onChange={handleChange}
                                disabled={(props.couponid)}
                            >
                                <option value="">Select Duration</option>
                                <option value="once">Once</option>
                                <option value="repeating">Repeating</option>
                                <option value="forever">Forever</option>
                            </Module.Form.Select>
                        </Module.Form.Group>
                    </Module.Grid.Col>
                    <Module.Grid.Col sm={6} md={6}>
                        <Module.Form.Group>
                            <Module.Form.Checkbox
                                name="isactive"
                                label="Is Active"
                                checked={values.isactive}
                                value={values.isactive || false}
                                className="font-20 mt-6"
                                onChange={handleChange}
                                disabled={(props.couponid)}
                            />
                        </Module.Form.Group>
                    </Module.Grid.Col>
                </Module.Grid.Row>
                <Module.Grid.Row>
                    <Module.Grid.Col sm={6} md={6} className={(values.duration == "repeating") ? '' : 'd-none'}>
                        <Module.Form.Group>
                            <Module.Form.Label>No of Times Redeem per user</Module.Form.Label>
                            <Module.Form.Input
                                type="number"
                                placeholder="No of Times Redeem per user"
                                name="durationperuser"
                                value={values.durationperuser}
                                error={errors.durationperuser}
                                onChange={handleChange}
                                disabled={(props.couponid)}
                            />
                        </Module.Form.Group>
                    </Module.Grid.Col>
                    <Module.Grid.Col sm={6} md={6}>
                        <Module.Form.Group>
                            <Module.Form.Label>Max Redemptions</Module.Form.Label>
                            <Module.Form.Input
                                type="number"
                                placeholder="Max Redemptions"
                                name="maxredemptions"
                                value={values.maxredemptions}
                                error={errors.maxredemptions}
                                onChange={handleChange}
                                disabled={(props.couponid)}
                            />
                        </Module.Form.Group>
                    </Module.Grid.Col>
                </Module.Grid.Row>
                </Module.Modal.Body>
            <Module.Modal.Footer>
                <Module.Button
                    type="button"
                    color="primary"
                    className="mr-4 btn-secondary"
                    onClick={e => props.onClickClose() }>
                        Back
                    </Module.Button>
                    <Module.Button
                    type="submit"
                    color="primary"
                    disabled={buttonstate}
                    >
                    {(actionstate === 'Add') ? 'Create Coupon' : 'Update Coupon'}
                </Module.Button>
            </Module.Modal.Footer>
        </Module.Form>
        )}
      />
    </Module.Modal>
    </Module.Container>
    );
}
export default Coupon;