import * as React from "react";
import {useState,useEffect} from "react";
import {Link} from "react-router-dom";
import * as Module from './Modules.react';
import InvestmentInfo from './Investmentinfo.react';

const { forwardRef, useRef, useImperativeHandle } = React;

const MyInvestment = (props) => {
  const [GetAllInvestments, setGetAllInvestments] = React.useState([]);
  const [Loading, setLoading] = React.useState(true);
  const [modalinvestment, setmodalinvestment] = React.useState([{displayInvinfo : false, modelpassid:0}]);
  const [deleteinvestment, setdeleteinvestment] = React.useState();

  const breadcumroute = {
    root: {title: 'Home', path: '/'},
    child: [
      {title: 'My Investments', path: ''},
    ]
  }
  const _columns = [
    {
      text: '',
      headerStyle: { width:'2%' },
      style: {'padding-left':'0px'},
      attrs: {
        'data-label': ''
      },
      formatter : (cell, row) => ((!row.nominees.length>0) ? <><Module.Tooltip classes={{ tooltip: 'investmenttitletooltip' }} enterTouchDelay={0} placement="top" title='If no nominee for this investment, then ignore' arrow><div className='warning'></div></Module.Tooltip></> : null)
    },
    {
      text: 'Investment Title',
      headerStyle: { width:'10%' },
      attrs: {
        'data-label': 'Investment Title'
      },
      formatter : (cell, row) => <a href="#!" onClick={(e) => { displayInvinfovisible(row.investementId) }} >{row.investementname}</a>
    }, {
      dataField: 'investementCountry',
      text: 'Country',
      headerStyle: { width:'10%' },
      attrs: {
        'data-label': 'Country'
      }
    }, {
      dataField: 'financialinstitution',
      text: 'Financial Institution',
      headerStyle: { width:'13%' },
      attrs: {
        'data-label': 'Financial Institution'
      }
    }, {
      text: 'Attachment',
      headerStyle: { width:'8%' },
      attrs: {
        'data-label': 'Attachment'
      },
      formatter : (cell, row) => ((row.documents.length>0) ? <div className="view-button-image"></div> : "")
    }, {
      text: 'Estimated Value',
      headerStyle: { width:'10%' },
      attrs: {
        'data-label': 'Estimated Value'
      },
      formatter : (cell, row) => ((row.estimateValue) ? ((row.estimateCurrency) ? row.estimateCurrency + ' ': '') + Module.formatNumber(row.estimateValue) : 'N/A')
    }, {
      text: '',
      classes: 'button-row',
      headerStyle: { width:'10%' },
      attrs: {
        'data-label': ''
      },
      formatter : (cell, row) => <><a href="#!" className="btn-primary" onClick={(e) => { props.history.push("/Investment/"+ row.investementId) }} >Edit</a><a href="#!" className="btn-secondary" onClick={(e) => { actiondeleteinvestment(row.investementId) }} >Delete</a></>
    }];

  const LoadMyInvestments = () =>{
    setLoading(true);
    Module.ar.getAllInvestment().then(res=>{
      setLoading(false);
      setGetAllInvestments(res);
    });
  };
  const actiondeleteinvestment = (id) => {
    setdeleteinvestment(<Module.SweetAlert 
      warning 
      title="Are you sure you want to delete this investment?"
      showCancel={true}
      onConfirm={(event) => {
        Module.ar.removeInvestment(id).then(res=>{
          setdeleteinvestment();
          Module.SnackbarUtils.success("Remove Successfully from Investment.");
          LoadMyInvestments();
        },error => {
          Module.SnackbarUtils.error("Something Went Wrong! Try later");
        });
      }}
      onCancel={(event) => setdeleteinvestment()}
      confirmBtnText="Yes delete it"
      cancelBtnText="No"
      closeOnClickOutside={false}
    >
      You will be no longer able to see this investment once deleted.
    </Module.SweetAlert>);
  }
  const displayInvinfovisible = (id) => {
    setmodalinvestment({displayInvinfo : true,modelpassid:id})
  }
  const invinfocloseModal = ()=> {
    setmodalinvestment({displayInvinfo : false, modelpassid:0})
  }
  useEffect(()=>
  {
    LoadMyInvestments();
  },[])
  return (
    <Module.SiteWrapper>
          <Module.Container>
            <Module.Form className="card">
              <Module.Card.Body>
                <Module.Grid.Row className="mobile-full">
                <Module.Grid.Col width={6}>
                  <Module.Card.Title>My Investments</Module.Card.Title>
                </Module.Grid.Col>
                <Module.Grid.Col width={6}>
                  <Module.Button
                    type="button"
                    color="primary"
                    className="float-right"
                    onClick={() => props.history.push("/Investment")}
                  >Add New Investment</Module.Button>
                </Module.Grid.Col>
                </Module.Grid.Row>
                <Module.Grid.Row>
                <Module.Grid.Col width={12}>
                  <Module.BreadCum routes={breadcumroute} />
                </Module.Grid.Col>
                </Module.Grid.Row>
                <Module.Grid.Row cards={true}>
                <Module.Grid.Col width={12}>
                  {/* {(GetAllInvestments) ? (
                  <Module.BootstrapTable keyField='id' bordered={ false } classes="table card-table table-striped card-table table-vcenter text-nowrap investment-table  my-investment-table" data={ GetAllInvestments } columns={ columns } noDataIndication={ <div className="text-center">Please Wait</div> } pagination={ Module.paginationFactory() } />
                  ) : null} */}
                  <Module.BootstrapTable 
                bootstrap4
                keyField='id' 
                bordered={ false } 
                classes="card-table table-vcenter investment-table my-family-table" 
                data={ GetAllInvestments }
                columns={ _columns } 
                noDataIndication={
                  (Loading) ?
                  <Module.Loading/>
                  :
                  <div className="position-relative text-center font-weight-bold"><i class="icon fa fa-balance-scale display-1"></i><React.Fragment>{<span className="no-data-available-text">'No investments to display'</span>}</React.Fragment></div>
                  }
                pagination={ Module.paginationFactory() } />
                </Module.Grid.Col>
              </Module.Grid.Row>
            </Module.Card.Body>
          </Module.Form>
            {deleteinvestment}
            <Module.Modal show={modalinvestment.displayInvinfo} className="ModalCustomStyle" onHide={() => invinfocloseModal()}>
                <Module.Modal.Header closeButton>
                <Module.Modal.Title id='ModalHeader' className="d-inline">Investment Details</Module.Modal.Title>
                <Link to={"/Investment/"+ modalinvestment.modelpassid} className="btn btn-primary float-right">Edit Investment</Link>
                </Module.Modal.Header>
                <Module.Modal.Body>
                  {(modalinvestment.displayInvinfo == true ? <InvestmentInfo fnname={() => invinfocloseModal()} fnid={modalinvestment.modelpassid} /> : null)}
                </Module.Modal.Body>
              </Module.Modal>

          </Module.Container>
        </Module.SiteWrapper>
  );
}
  
  export default MyInvestment;
  
