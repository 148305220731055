import * as React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import * as Module from "./Module.react";

const { forwardRef, useRef, useImperativeHandle } = React;

const CurrencyManagement = (props) => {
  const DeleteCurrencyRef = useRef();

  const ModalDeleteCurrency = (id) => {
    setCurrencyId(id);
    DeleteCurrencyRef.current.handleShow(true);
  };


  const _columns = [
    {
      text: 'CURRENCY ID',
      dataField: 'id',
       filter: Module.textFilter(),
      attrs: {
          'data-label': 'CURRENCY ID'
        },
        sort:true,
    },
    {
      text: 'CURRENCY NAME',
      dataField: 'currencyName',
       filter: Module.textFilter(),
      attrs: {
          'data-label': 'CURRENCY NAME'
        },
        sort:true,
    },
    {
      text: 'CURRENCY CODE',
      dataField: 'currencyCode',
       filter: Module.textFilter(),
      attrs: {
          'data-label': 'CURRENCY CODE'
        },
        sort:true,
    },
    {
  
      text: 'Action',
      headerClasses: 'wd-17',
       
     attrs: {
        'data-label': 'Action'
      },


  
      formatter : (cell, row) => (
        <div className="button-row">
          <a
            href="#!"
            className="btn-primary"
            onClick={() => props.history.push("/EditCurrency/" + row.id)}
          >
            Edit
          </a>
          &nbsp;&nbsp;&nbsp;
          <a
            href="#!"
            className="btn-secondary"
            onClick={() => ModalDeleteCurrency(row.id)}
          >
            Delete
          </a>
        </div>
      ),
      name: " ",
      sortable: true,
      width: "200px",
    },
  ];

  const [Id, setCurrencyId] = useState(false);
  const [CurrencyList, setCurrencyList] = React.useState([]);
  const [Filter, setFilter] = React.useState({
    columns: [],
    data: [],
    export: false,
    print: false,
  });

  const LoadCurrency = () => {
    Module.ar.getAllCurrencies().then((res) => {
      setCurrencyList(res);

      setFilter({
        columns: _columns,
        data: res,
        export: false,
        print: false,
      });
    });
  };

  useEffect(() => {
    LoadCurrency();
  }, []);
  return (
    
    <Module.SiteWrapper>
<Module.Page.Content>
    <Module.Grid.Row className="mobile-full mb-4">
      <Module.Grid.Col width={6}>
        <Module.Card.Title>Currency Settings</Module.Card.Title>
      </Module.Grid.Col>
      <Module.Grid.Col width={6}>
        <Link to={"/CreateCurrency"}>
          <Module.Button
            type="button"
            color="primary"
            className="float-right"
          >Create
          </Module.Button>
        </Link>
      </Module.Grid.Col>
    </Module.Grid.Row>
   <Module.Grid.Row cards={true}>                
    <Module.Grid.Col width={12}>
      <Module.Card classtext="Userlist">  
      <Module.BootstrapTable
        bootstrap4
        keyField='id' 
        bordered={ false } 
        classes="card-table table-vcenter investment-table my-family-table" 
        data={CurrencyList }
        columns={ _columns }
        noDataIndication={ <div className="text-center">Please Wait</div> }
        filter={ Module.filterFactory() }
        filterPosition="top"
        pagination={ Module.paginationFactory() }
        />

      </Module.Card>      
    </Module.Grid.Col>
  </Module.Grid.Row>
</Module.Page.Content>

<Module.DeleteCurrency ref={DeleteCurrencyRef} ParentFun={LoadCurrency} id = {Id} ></Module.DeleteCurrency>
</Module.SiteWrapper>
  );
};

export default CurrencyManagement;
