"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
exports.__esModule = true;
exports.ApiException = exports.UsersDetails = exports.UserDtoPagedResultDto = exports.ResetPasswordDto = exports.ChangePasswordDto = exports.ChangeUserLanguageDto = exports.RoleDtoListResultDto = exports.Int64EntityDto = exports.UserDto = exports.CreateUserDto = exports.ExternalAuthenticateResultModel = exports.ExternalAuthenticateModel = exports.ExternalLoginProviderInfoModel = exports.AuthenticateResultModel = exports.AuthenticateModel = exports.TenantDtoPagedResultDto = exports.TenantDto = exports.CreateTenantDto = exports.Couponasyncoutput = exports.Couponasyncinput = exports.SubscriptionInput = exports.SubscriptionOutput = exports.PriceRecurring = exports.SubscriptionResponse = exports.InvoiceList = exports.CustomerPaymentMethod = exports.StripeProducts = exports.SubscriptionData = exports.AllSubscription = void 0;
var CClient = /** @class */ (function () {
    function CClient(baseUrl, http) {
        this.jsonParseReviver = undefined;
        this.http = http ? http : window;
        this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "/api";
    }
    /**
     * @param body (optional)
     * @return Success
     */
     CClient.prototype.postProfileImage = function (data) {
        var _this = this;
        var url_ = this.baseUrl + "/api/services/app/MyProfile/PostProfileImage";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            body: data,
            method: "POST",
            headers: {
                
            }
        };
        return this.http.fetch(url_, options_).then(function (_response) {
            return _this.processpostProfileImage(_response);
        });
    };
    CClient.prototype.processpostProfileImage = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) { return _headers[k] = v; });
        }
        ;
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                var resultData200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result200 = resultData200 !== undefined ? resultData200 : null;
                return result200;
            });
        }
        else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(null);
    };
    return CClient;
}());
exports.CClient = CClient;

function throwException(message, status, response, headers, result) {
    if (result !== null && result !== undefined) throw result;
    else if (response != null) {
        throw new ApiException(JSON.parse(response));
    } else {
        throw new ApiException(message, status, response, headers, null);
    }
}