import * as React from "react";
import {useState,useEffect} from "react";
import {Link} from "react-router-dom";
import * as Module from './Modules.react';

const { forwardRef, useRef, useImperativeHandle } = React;
let profileinput = Module.UpdateProfileInput;

const ProfilePage = (props) => {
  const WrapperRef = useRef();
  const inputFile = useRef(null) 

  const [ProfileData, setProfileData] = React.useState([]);
  const [ProfilePicture, setProfilePicture] = React.useState(null);
  const [PasswordInfo, setPasswordInfo] = React.useState();
  const [Toggle, setToggle] = React.useState("profile");
  const [Loading, setLoading] = React.useState(true);
  const [ButtonStatus, setButtonStatus] = React.useState(true);
  const [ShowSweetAlert, setShowSweetAlert] = React.useState();
  const [Cropper, setCropper] = React.useState({imageSrc: 'https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000',
                                  crop: { x: 0, y: 0 },
                                  zoom: 1,
                                  aspect: 3 / 3,
                                  croppedAreaPixels: null,
                                  visibleblockmodal: false});
  const [ImageOperation, setImageOperation] = React.useState(false);
  const [Countries, setCountries] = React.useState();
  const [States, setStates] = React.useState([]);
  const [Reload, setReload] = React.useState(false);
              

  const OnLoad = () => {
    setLoading(true);
    Module.ar.getCountryData().then(res=>{
      setCountries(res);
    });
    Module.ar.getAllStatesForUsers().then(res=>{
      setStates(res);
    });
    Module.ar.getCurrentUserProfile().then(res=>{
      setProfileData({
        accountnumber: res.id,
        emailaddress: res.emailaddress,
        firstname: res.firstname,
        lastname: res.lastname,
        countrycode: res.country == "38" ? "~1" :"+" + res.phonenumberformate,
        phonenumber: res.phonenumber,
        country: res.country,
        city: res.city,
        state: res.state,
      });
      setLoading(false);
    });
    Module.ar.getCurrentLoginInformations(Module.AuthorizationToken).then(res=>{
      if(res) {
        if(res != null && res.user != null) {
          if(res.user.profilePicture != null) {
            Module.getprofilepicture().then(res=> {
              setProfilePicture(res);
            });
          } else {
            setProfilePicture();
          }
        } else
          setProfilePicture();
      }
      else
        setProfilePicture();
    });
  }
  const updateprofile = (values, actions) => {
    setButtonStatus(false);

    let phoneNumberCode = '';
    profileinput = {
      emailaddress: values.emailaddress,
      firstname: values.firstname,
      lastname: values.lastname,
      phonenumber:  ( phoneNumberCode?  phoneNumberCode : (values.countrycode)) + "-" + values.phonenumber,
      country: values.country,
      city: values.city,
      state: values.state,
    };
    Module.ar.updateCurrentUserProfile(profileinput).then(res=>{
      if(res == "success") {
        Module.SnackbarUtils.success("Profile Updated.");
        setButtonStatus(true);
        OnLoad();
        //setReload(true);
        WrapperRef.current.Reload();
      } else {
        Module.SnackbarUtils.error(result);
        setButtonStatus(true);
        OnLoad();
        WrapperRef.current.Reload();
      }
    });
  };
  const changepasswordbutton = (values, actions) => {
    setButtonStatus(false);
    var body = {
      currentPassword: values.oldpassword,
      newPassword: (values.password == values.retypepassword) ? values.password : '',
    };
    Module.ar.changePassword(body).then(res=>{
      Module.SnackbarUtils.success("Password Changed.");
        setButtonStatus(true);
        actions.resetForm();
    },
    error => {
      setButtonStatus(true);
      actions.resetForm();
      Module.errorresult(error);
    });
  }
  const toggle = (typesettings) => {
    if(typesettings == 'changepassword')
      setToggle("changepassword");
    else
      setToggle("profile");
  }
  const onCropChange = (crop) => {
    setCropper({...Cropper, crop })
  }
  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCropper({...Cropper, croppedAreaPixels })
  }
  const onZoomChange = (zoom) => {
    setCropper({...Cropper, zoom })
  }
  const imageupload = (event) => {
    console.log(Cropper);
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      setImageOperation(false);
      setCropper({...Cropper,imageSrc: URL.createObjectURL(img), visibleblockmodal: true});
    }
  }
  const onimageoperation = (event) => {
    setImageOperation(true);
  }
  const dataURLtoFile = (dataurl, filename) => {
      var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {type:mime});
  }
  const changeprofilepicture = () => {
    try {
      Module.getCroppedImg(
        Cropper.imageSrc,
        Cropper.croppedAreaPixels,
        0
      ).then(croppedImage => {
        var randfilename = Math.random().toString(36).substring(7);
        var file = dataURLtoFile(croppedImage, "profileimage_"+randfilename+".jpeg");
        
        var data = new FormData();
        data.append("file", file, file.name);
  
        Module.ar2.postProfileImage(data).then(res=>{
          console.log(res);
          Module.SnackbarUtils.success("Profile Picture Updated.");
          setCropper({ ...Cropper,visibleblockmodal: false});
          OnLoad();
          WrapperRef.current.Reload();
        }).catch((error) => {
          Module.SnackbarUtils.error("Failed Update Profile Picture");
          setCropper({ ...Cropper,visibleblockmodal: false});
          OnLoad();
          WrapperRef.current.Reload();
        });
      });      
    } catch (e) {
      console.error(e)
    }
  }
  const removeprofilepicture = () => {
    closeModalimageoperation();
    setShowSweetAlert(<Module.SweetAlert 
      warning 
      title="Are you sure Want to remove profile picture"
      showCancel={true}
      onConfirm={(event) => {
        setShowSweetAlert();
        Module.ar.removeProfilePicture().then(res=>{
            OnLoad();
            WrapperRef.current.Reload();
            if(res == "success") {
              Module.SnackbarUtils.success("Profile Picutre Removed.");
            } else {
              Module.SnackbarUtils.error("Failed to remove profile picture");
            }
        });
      }}
      onCancel={(event) => {setShowSweetAlert();}}
      confirmBtnText="Yes Remove it"
      cancelBtnText="No"
      closeOnClickOutside={false}
    >
    </Module.SweetAlert>);
  }
  const closeModal = () => {
    setCropper({ ...Cropper,visibleblockmodal: false});
  }
  const closeModalimageoperation = () => {
    setImageOperation(false);
  }
  useEffect(()=>
  {
    OnLoad();
  },[]);
  return (
    <Module.SiteWrapper ref={WrapperRef}>
      {ShowSweetAlert}
      <div className="my-3 my-md-5">
        {Loading ? (
          <Module.Loading />
        ) : (
          <Module.Container>
            <Module.Grid.Row>
              <Module.Grid.Col lg={4} className="left-profile-box">
                <Module.Card className={!ProfilePicture ? "card-profile profile-add" : "card-profile"}>
                  <Module.Card.Header/>
                  <Module.Card.Body className="text-center">
                    {!ProfilePicture ? 
                    <span className="card-profile-img-text" style={{backgroundColor: Module.ColorPicker(ProfileData.firstname)}}>{Module.getfirstlatters(ProfileData.firstname,ProfileData.lastname)}</span>
                    : null }
                    <label>
                      <div className={"profile-box"} onClick={(event) => (ProfilePicture) ? onimageoperation(event) : null }></div>
                      <input type="file" name="image" id="image" className="d-none" disabled={(ProfilePicture) ? true : false} onChange={(event) => { imageupload(event) }} />
                      {!ProfilePicture ? 
                      null
                      :
                      <img src={ProfilePicture} className="card-profile-img" onClick={(event) => {onimageoperation(event)}} />
                      }
                  </label>
                  {(!ProfilePicture ? null : 
                  <Module.Tooltip enterTouchDelay={0} placement="right" title='Remove Profile Picture' arrow>
                  <div className={"remove-profile"} onClick={(e) => { removeprofilepicture() }}></div>
                  </Module.Tooltip>
                  )}
                  <h3>{ProfileData.firstname + ' ' + ProfileData.lastname}</h3>
                  </Module.Card.Body>
                  </Module.Card>
                  <Module.Card className="link-row">
                        <ul>
                    <li className={(Toggle === "profile") ? 'active' : ''}><a href="#!" onClick={(event) => { toggle('profile') }}>Edit Profile</a></li>
                      <li className={(Toggle === "changepassword") ? 'active' : ''}><a href="#!" onClick={(event) => { toggle('changepassword') }}>Reset Password</a></li>
                    </ul>
                  </Module.Card>
        
              </Module.Grid.Col>
              <Module.Grid.Col lg={8} className={(Toggle === "profile") ? '' : 'd-none'}>
                <Module.Formik
                  initialValues={{
                    emailaddress: ProfileData.emailaddress,
                    firstname: ProfileData.firstname,
                    lastname: ProfileData.lastname,
                    countrycode: ProfileData.countrycode,
                    phonenumber: ProfileData.phonenumber,
                    country: ProfileData.country,
                    city: ProfileData.city,
                    state: ProfileData.state,
                  }}
                  validate={values => {
                    let errors = {};
                    if (!values.firstname) {
                      errors.firstname = "First name Required";
                    }
                    if (!values.lastname) {
                      errors.lastname = "Last name Required";
                    }
                    if (!values.countrycode) {
                      errors.countrycode = "Country Code Required";
                    }
                    if (!values.phonenumber) {
                      errors.phonenumber = "PhoneNumber Required";
                    }
                    else if (!/^([0-9])*$/i.test(values.phonenumber)) {
                      errors.phonenumber = "Invalid Phone Number";
                    }
                    else if (!/^.{6,10}$/i.test(values.phonenumber)) {
                      errors.phonenumber = "Invalid Phone Number";
                    }
                    if (!values.city) {
                      errors.city = "City Required";
                    }
                    if (!values.state) {
                      errors.state = "State Required";
                    }
                    if (!values.country) {
                      errors.country = "Country Required";
                    }
                    return errors;
                  }}
                  validateOnChange={false}
                  onSubmit={updateprofile}
                  render={({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <Module.Form className="card profile-form" onSubmit={handleSubmit}>
                    <Module.Card.Body>
                    <Module.Grid.Row className="profile-top-row">
                      <Module.Card.Title>Edit Profile</Module.Card.Title>
                    </Module.Grid.Row>
                    <Module.Grid.Row>                          
                        <Module.Grid.Col sm={4} md={4}>
                          <Module.Form.Group>
                            <Module.Form.Label>First Name</Module.Form.Label>
                            <Module.Form.Input
                              type="text"
                              placeholder="First Name"
                              name="firstname"
                              value={values.firstname}
                              error={errors.firstname}
                              onChange={handleChange}
                            />
                          </Module.Form.Group>
                        </Module.Grid.Col>
                        <Module.Grid.Col sm={4} md={4}>
                          <Module.Form.Group>
                            <Module.Form.Label>Last Name</Module.Form.Label>
                            <Module.Form.Input
                              type="text"
                              placeholder="Last Name"
                              name="lastname"
                              value={values.lastname}
                              error={errors.lastname}
                              onChange={handleChange}
                            />
                          </Module.Form.Group>
                        </Module.Grid.Col>
                      </Module.Grid.Row>
                      <Module.Grid.Row className="full-width-input">
                      <Module.Grid.Col sm={4} md={4}>
                          <Module.Form.Group>
                            <Module.Form.Label>Email address</Module.Form.Label>
                            <Module.Form.Input
                              type="email"
                              placeholder="Email"
                              name="emailaddress"
                              value={values.emailaddress || ""}
                              onChange={handleChange}
                            />
                          </Module.Form.Group>
                        </Module.Grid.Col>
                        <Module.Grid.Col sm={6} md={6}>
                          <Module.Form.Label>Phone Number</Module.Form.Label>
                          <Module.Grid.Row className="full-width-input-50">
                            <Module.Grid.Col sm={4} md={4} className="pr-0">
                              <Module.Form.Group>
                                <Module.Form.Select
                                  name="countrycode"
                                  value={values.countrycode}
                                  error={errors.countrycode}
                                  onChange={handleChange}
                                >
                                  <option value="">Select Code</option>
                                  {Countries && Countries.map((option) => (
                                    <option value={option.code}>{option.countryName + " (" + option.code.replace("~","+") + ")"}</option>
                                  ))}
                                </Module.Form.Select>
                              </Module.Form.Group>
                            </Module.Grid.Col>
                            <Module.Grid.Col sm={6} md={6}>
                              <Module.Form.Group>
                                <Module.Form.Input
                                  type="text"
                                  placeholder="Phone Number"
                                  name="phonenumber"
                                  value={values.phonenumber}
                                  error={errors.phonenumber}
                                  onChange={handleChange}
                                />
                              </Module.Form.Group>
                            </Module.Grid.Col>
                          </Module.Grid.Row>
                        </Module.Grid.Col>
                        
                  </Module.Grid.Row>
                  <Module.Grid.Row className="full-width-input">

                  <Module.Grid.Col sm={4} md={4}>
                          <Module.Form.Label>Country</Module.Form.Label>
                          <Module.Form.Select
                            name="country"
                            value={values.country}
                            error={errors.country}
                            onChange={handleChange}
                          >
                            <option value="">Select Country</option>
                            {Countries && Countries.map((option) => (
                              <option value={option.id}>{option.countryName}</option>
                            ))}
                          </Module.Form.Select>
                        </Module.Grid.Col>
                        
                  <Module.Grid.Col sm={4} md={4}>
                          <Module.Form.Label>City</Module.Form.Label>
                          <Module.Form.Input
                            name="city"
                            placeholder="City"
                            value={values.city}
                            error={errors.city}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Module.Grid.Col>
                        <Module.Grid.Col sm={4} md={4}>
                          <Module.Form.Label>State</Module.Form.Label>
                          {values.country == "" ? (
                          <Module.Form.Input
                            name="state"
                            placeholder="State"
                            value={values.state}
                            error={errors.state}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        ) : 
                        (
                          (States.filter(data => data.country == values.country.toString()).length > 0) ? (
                              <Module.Form.Select
                              name="state"
                              value={values.state}
                              error={errors.state}
                              onChange={handleChange}
                            >
                              <option value="">Select State</option>
                              {States.filter(data => data.country == values.country.toString()).map((items) => (
                                items.states.map(function (mark, i) {
                                  return <option value={mark}>{mark}</option>
                                })
                              ))}
                            </Module.Form.Select>
                            ) : (
                              <Module.Form.Input
                                name="state"
                                placeholder="State"
                                value={values.state}
                                error={errors.state}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            )
                        )}
                        </Module.Grid.Col>
                        
                      </Module.Grid.Row>
                    </Module.Card.Body>
                    <Module.Card.Footer className="text-right mt-25">
                      <Module.Button
                        type="submit"
                        color="primary"
                        disabled={!ButtonStatus}
                      >
                        Update Profile
                      </Module.Button>
                    </Module.Card.Footer>
                  </Module.Form>
                  )}
                />
                </Module.Grid.Col>
                <Module.Grid.Col lg={8} className={(Toggle === 'changepassword') ? '' : 'd-none'}>
                <Module.Formik
                  initialValues={{
                    oldpassword: "",
                    password: "",
                    retypepassword: "",
                  }}
                  validate={values => {
                    let errors = {};
                    if (!values.oldpassword) {
                      errors.oldpassword = "Old Password";
                    }
                    //if (!/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/i.test(values.password)) {
                    if (!/^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/gm.test(values.password)) {
                      errors.password = "Password should consist of at-least Minimum eight characters, One Uppercase, one Special character and one numeric character";
                    }
                    if (!values.retypepassword) {
                      errors.retypepassword = "Re-Enter Password";
                    }
                    if (values.password != values.retypepassword) {
                      errors.password = "Password Does not match";
                      errors.retypepassword = "Password Does not match";
                    }
                    return errors;
                  }}
                  onSubmit={changepasswordbutton}
                  render={({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <Module.Form className="card" onSubmit={handleSubmit}>
                      <Module.Card.Body>
                      <Module.Grid.Row className="profile-top-row">
                        <Module.Card.Title>Change Password</Module.Card.Title>
                      </Module.Grid.Row>
                      <Module.Grid.Row>
                        <Module.Grid.Col sm={6} md={6}>
                            <Module.Form.Group>
                              <Module.Form.Label>Existing Password</Module.Form.Label>
                              <Module.Form.Input
                                type="password"
                                placeholder="Existing Password"
                                name="oldpassword"
                                value={values.oldpassword}
                                error={errors.oldpassword}
                                onChange={handleChange}
                              />
                            </Module.Form.Group>
                          </Module.Grid.Col>
                        </Module.Grid.Row>
                      <Module.Grid.Row>
                        <Module.Grid.Col sm={6} md={6}>
                            <Module.Form.Group>
                              <Module.Form.Label>Password</Module.Form.Label>
                              <Module.Form.Input
                                type="password"
                                placeholder="Password"
                                name="password"
                                value={values.password}
                                error={errors.password}
                                onChange={handleChange}
                              />
                            </Module.Form.Group>
                          </Module.Grid.Col>
                          <Module.Grid.Col sm={6} md={6}>
                            <Module.Form.Group>
                              <Module.Form.Label>Re-Type Password</Module.Form.Label>
                              <Module.Form.Input
                                type="password"
                                placeholder="Re-Type Password"
                                name="retypepassword"
                                value={values.retypepassword}
                                error={errors.retypepassword}
                                onChange={handleChange}
                              />
                            </Module.Form.Group>
                          </Module.Grid.Col>
                        </Module.Grid.Row>
                        <Module.Grid.Row>
                          <Module.Grid.Col sm={12} md={12}>
                            Password should consist of at-least Minimum eight characters, One Uppercase, one Special character and one numeric character
                          </Module.Grid.Col>
                        </Module.Grid.Row>
                      </Module.Card.Body>
                      <Module.Card.Footer className="text-right mt-25">
                        <Module.Button
                          type="submit"
                          color="primary"
                          disabled={!ButtonStatus}
                        >
                          Change Password
                        </Module.Button>
                      </Module.Card.Footer>
                    </Module.Form>
                  )}
                />
              </Module.Grid.Col>
            </Module.Grid.Row>
          </Module.Container>
        )}
        ;
      </div>
      <section className="modal-class">
          <Module.Modal show={Cropper.visibleblockmodal} aria-labelledby={"ModalHeader"} className={"ModalCustomStyle ProfileUploadModel"} onHide={closeModal}>
            <Module.Modal.Header closeButton>
            </Module.Modal.Header>
            <Module.Modal.Body>
                {(Cropper.visibleblockmodal == true ? 
                  <div>
                    <div className="crop-container">
                      <Module.Cropper
                        image={Cropper.imageSrc}
                        crop={Cropper.crop}
                        zoom={Cropper.zoom}
                        aspect={Cropper.aspect}
                        onCropChange={onCropChange}
                        onCropComplete={onCropComplete}
                        onZoomChange={onZoomChange}
                      />
                    </div>
                    <div className="controls">
                      <Module.Slider
                        value={Cropper.zoom}
                        min={1}
                        max={3}
                        step={0.1}
                        aria-labelledby="Zoom"
                        onChange={(e, zoom) => onZoomChange(zoom)}
                        classes={{ container: 'slider' }}
                      />
                    </div>
                  </div>
                : null)}
            </Module.Modal.Body>
            <Module.Modal.Footer className="upload-btn">
              <Module.Button
                type="button"
                color="primary"
                className="mr-4"
                onClick={(event) => { changeprofilepicture(event) }}>
                  Upload
              </Module.Button>
            </Module.Modal.Footer>
          </Module.Modal>
      </section>
      <section>
      {(ImageOperation) ? 
          <Module.Modal show={ImageOperation} aria-labelledby={"ModalHeader"} className={"ModalCustomStyle ProfileUploadModel"} onHide={closeModalimageoperation}>
            <Module.Modal.Header closeButton>
            </Module.Modal.Header>
            <Module.Modal.Body className="popupprofilebody" onClick={(event) => { inputFile.current.click() }}>
              <input type="file" name="image" id="image" ref={inputFile} className="d-none" onChange={(event) => { imageupload(event) }} />
              <img src={ProfilePicture} className={"popupprofile"} />
            </Module.Modal.Body>
            <Module.Modal.Footer className="upload-btn">
              <Module.Button
                type="button"
                color="primary"
                className="mr-4"
                onClick={(event) => { inputFile.current.click() }}>
                  Upload
              </Module.Button>
              <Module.Button
                type="button"
                color="primary"
                className="mr-4 btn-Remove"
                onClick={(event) => { removeprofilepicture() }}>
                  Remove 
              </Module.Button>
            </Module.Modal.Footer>
          </Module.Modal>
      : ''}
      </section>
    </Module.SiteWrapper>
  )
}
  
export default ProfilePage;