// @flow

import * as React from "react";

import { Page, Grid, Card, Icon } from "../tabler-react/index";

import SiteWrapper from "../SiteWrapper.react";


function IconPage(): React.Node {
  return (
    <SiteWrapper>
   
    </SiteWrapper>
  );
}

export default IconPage;
