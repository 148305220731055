//@flow
const strings = {
  title: "Reset Password",
  buttonText: "Reset",
  NewPasswordLabel: "New Password",
  NewPasswordPlaceholder: "Enter Password",
  ConfirmPasswordLabel: "Confirm Password",
  ConfirmpasswordPlaceholder: "Enter Password",
};

export default strings;

export type stringTypes = { [$Keys<typeof strings>]: string };
