// @flow

import * as React from "react";

import { Error500Page } from "../tabler-react/index";

type Props = {||};

function Error500(props: Props): React.Node {
  return <Error500Page />;
}

export default Error500;
